import { Bus } from '@/lib/classes/Bus';
import { SeasonModel } from '@/models/season/SeasonModel';

enum Events {
  SELECT_ACTIVE_SEASON,
}

class CropRotationEvents extends Bus {
  onSelectActiveSeason(callback: (season: SeasonModel | undefined) => void, once = false) {
    this.registerListener(Events.SELECT_ACTIVE_SEASON, callback, once);
  }

  offSelectActiveSeason(callback: (season: SeasonModel | undefined) => void) {
    this.unregisterListener(Events.SELECT_ACTIVE_SEASON, callback);
  }

  emitSelectActiveSeason(season: SeasonModel | undefined) {
    this.emitListeners(Events.SELECT_ACTIVE_SEASON, season);
  }
}

export default new CropRotationEvents();
