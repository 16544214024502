import ApiService from '@/services/api/ApiService';
import { SeasonModel } from '@/models/season/SeasonModel';
import {
  reactive, Ref, ref, UnwrapRef,
} from 'vue';
import { SeasonDto } from '@/services/api/dto/struct/SeasonDto';
import FieldsList from '@/modules/fields/FieldsList';

class SeasonList {
  get seasons(): Ref<UnwrapRef<SeasonModel[]>> {
    return this._seasons;
  }

  // Выбранный пользователем активный сезон
  private _activeSeason = ref<SeasonModel | undefined>();

  get activeSeason(): Ref<SeasonModel | undefined> {
    return this._activeSeason;
  }

  private _seasons = ref<SeasonModel[]>([]);

  async setActiveSeason(seasonId: number) {
    const matchId = this._activeSeason.value?.id === seasonId;
    this._activeSeason.value = this._seasons.value.find((s) => s.id === seasonId) as SeasonModel;

    if (matchId) {
      if (this._activeSeason.value) {
        window.localStorage.setItem('seasonId', this._activeSeason.value.id.toString());
        await FieldsList.updateAllData(true);
      } else {
        window.localStorage.removeItem('seasonId');
      }
    }
  }

  async fetch() {
    this._seasons.value = [];
    const { data } = await ApiService.season.getSeasons();
    const buffArray = [];
    data.forEach((seasonData: SeasonDto) => {
      const season = this._seasons.value.find((v) => v.id === seasonData.id);
      if (season) {
        season.name = seasonData.name;
        season.startDate = new Date(seasonData.period_start);
        season.endDate = new Date(seasonData.period_stop);
        season.note = seasonData.note;
      } else {
        buffArray.push(new SeasonModel(seasonData));
      }
    });

    this._seasons.value = buffArray;

    this._seasons.value.sort((a, b) => (a.endDate > b.endDate ? 1 : -1));

    if (this._seasons.value.length === 0) {
      await this.createDefaultSeason();
      return;
    }

    const storageSeasonId = parseInt(window.localStorage.getItem('seasonId') || '0', 10);

    if (storageSeasonId && this._seasons.value.find((season) => season.id === storageSeasonId)) {
      await this.setActiveSeason(this._seasons.value.find((season) => season.id === storageSeasonId).id);
    } else {
      const currentDate = new Date();
      let closestSeason: SeasonModel;
      let minDiff = Infinity;

      this._seasons.value.forEach((season) => {
        const diff = Math.abs(season.endDate.getTime() - currentDate.getTime());
        if (diff < minDiff) {
          minDiff = diff;
          closestSeason = season as SeasonModel;
        }
      });
      if (closestSeason) {
        await this.setActiveSeason(closestSeason.id);
      } else {
        await this.setActiveSeason(this._seasons.value[this._seasons.value.length - 1].id);
      }
    }
  }

  private async createDefaultSeason() {
    const currentDate = new Date();
    const { data } = await ApiService.season.createSeason({
      name: `Сезон ${currentDate.getFullYear()}`,
      period_start: new Date(`${currentDate.getFullYear()}-01-01`).toISOString(),
      period_stop: new Date(`${currentDate.getFullYear()}-31-12`).toISOString(),
      note: '',
    });
    const season = new SeasonModel(data);
    this._seasons.value.push(season);
    await this.setActiveSeason(season.id);
  }
}

export default new SeasonList();
