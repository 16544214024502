import { lineString } from '@turf/turf';
import {
  Feature, LineString, Position,
} from 'geojson';
import { PositionModel } from '@/models/geojson/PositionModel';
import { Model } from '@/models/Model';

export class LineStringModel extends Model {
  get id(): number {
    return this._id;
  }

  get data(): PositionModel[] {
    return this._data;
  }

  static counter = 1000000;

  private readonly _id: number;

  private _data: PositionModel[] = [];

  constructor(coordinates: Position[]) {
    super();
    this._data = coordinates.map((v) => new PositionModel(v));
    this._id = LineStringModel.counter++;
  }

  addPosition(newPosition: Position) {
    this.data.push(new PositionModel(newPosition));
  }

  deletePosition(index: number) {
    if (index > 1000000) {
      this._data = this._data.filter((v) => v.id !== index);
      return;
    }
    this._data.splice(index, 1);
  }

  update(coordinates: Position[]) {
    this._data = coordinates.map((v) => new PositionModel(v));
  }

  toFeature(): Feature<LineString> {
    return {
      type: 'Feature',
      id: this._id,
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: this._data.map((v) => v.data),
      },
    };
  }
}
