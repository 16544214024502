import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/no-data.svg'


const _hoisted_1 = { class: "NoData" }
const _hoisted_2 = { class: "NoData-image" }
const _hoisted_3 = { src: _imports_0 }
const _hoisted_4 = {
  key: 0,
  class: "NoData-message"
}
const _hoisted_5 = { class: "fs-18 color-extra-light fw-500" }

export function render(_ctx, _cache) {
  const _directive_svg_inline = _resolveDirective("svg-inline")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
        [_directive_svg_inline]
      ])
    ]),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}