<template lang="pug"  src="./PoisPanel.pug"/>
<style lang="scss" src="./PoisPanel.scss"/>

<script lang="ts">
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';
import { PoiCollection } from '@/collection/PoiCollection';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useApp } from '@/composables/useApp';
import { useAuth } from '@/composables/useAuth';
import { useMapContainers } from '@/composables/useMapContainers';
import { usePoi } from '@/composables/usePoi';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { POIGroupTypeEnum } from '@/constants/enums/POIGroupTypeEnum';
import { RulePoiGroupName } from '@/constants/rules/RulePoiGroupName';
import { flyToLngLat } from '@/lib/map/flyToLngLat';
import { MapLayerPoiModel } from '@/models/map/Layers/MapLayerPoiModel';
import { PoiGroupModel } from '@/models/poi/PoiGroupModel';
import { PoiModel } from '@/models/poi/PoiModel';
import PoiEvents from '@/modules/poi/PoiEvents';
import PoisList from '@/modules/poi/PoisList';
import POIAddBlock from '@/modules/poi/ui/POIAddBlock/POIAddBlock.vue';
import POIAddCompactBlock from '@/modules/poi/ui/POIAddCompactBlock/POIAddCompactBlock.vue';
import POIEditBlock from '@/modules/poi/ui/POIEditBlock/POIEditBlock.vue';
import POITableModal from '@/modules/poi/ui/POITableModal/POITableModal.vue';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import LoadingStatus from '@/services/loading/LoadingStatus';
import {
  Delete, Download, Edit, Filter, InfoFilled, Search,
} from '@element-plus/icons-vue';
import { useTranslate } from '@tolgee/vue';
import { ElMessageBox, FormInstance, FormRules } from 'element-plus';
import { Feature } from 'geojson';
import { Map } from 'mapbox-gl';
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  shallowRef,
  UnwrapRef,
  watch,
} from 'vue';

export default defineComponent({
  name: 'PoisPanel',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SidePanelContent,
    UiIcon,
    POIEditBlock,
    Download,
    UiDrawer,
    Edit,
    POITableModal,
    POIAddCompactBlock,
    POIAddBlock,
  },
  emit: ['close'],
  setup(props) {
    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;
    const {
      activePoi,
      setActivePoi,
      filterPoi,
      informationMode,
      compareMap,
      compareFilterPoi,
    } = useMapContainers(props.mapContainer);
    const localFilterPois = computed(() => (props.isCompare ? compareFilterPoi.value : filterPoi.value));
    const activeFiltersForm = ref(false);
    const activeSettingsLabels = ref(false);
    const activeFilter = ref(true);
    const { t } = useTranslate('poi');
    const { accessToken } = useAuth();
    const { showText, groupEditForm, groupAddForm } = usePoi();
    const _labels = ref<Record<string, string>>({});
    const enterElementBtn = ref<string>('');
    const editGroupActive = ref(false);
    const exportDialog = ref(false);
    const _group = ref<PoiGroupModel>();
    const showEditBlock = ref(true);
    const activeTableId = ref<number>(0);
    const showAddPoi = ref<boolean>(false);
    const mapLayer = ref<MapLayerPoiModel>();
    const poisLabels = computed({
      get: () => _labels.value,
      set: (value) => {
        _labels.value = value;
      },
    });

    const uniqueNameGroupAdd = (rule: any, value: any, callback: any) => {
      if (PoisList.groups.value?.some((f) => f.name === groupAddForm.value.name)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const groupAddRules = reactive<FormRules>({
      name: [
        ...RulePoiGroupName,

        {
          validator: uniqueNameGroupAdd,
          trigger: ['blur', 'change'],
        },
      ],
    });

    const computedPois = computed(() => PoisList.pois.value?.filter((v) => localFilterPois.value?.selectPoiGroup.includes(Number(v.group))));
    const filteredPois = computed(() => computedPois.value.filter((a) => {
      if (localFilterPois.value !== undefined) {
        if (localFilterPois.value?.keySearch === undefined) {
          return a.name.toLowerCase().includes(localFilterPois.value.searchValue.toLowerCase());
        }

        if (localFilterPois.value?.keySearch === 'description') {
          return a.description.toLowerCase().includes(localFilterPois.value.searchValue.toLowerCase());
        }

        if (localFilterPois.value?.keySearch) {
          const paramValue = a.params[localFilterPois.value.keySearch] || '';
          return paramValue?.toString().toLowerCase().includes(localFilterPois.value.searchValue.toLowerCase());
        }
      }
      return true;
    }).filter((a) => {
      if (localFilterPois.value.filterKeys.length > 0) {
        return Object.keys(a.params).some((b) => filterPoi.value.filterKeys.some((c) => b === c));
      }
      return true;
    }));

    const isGroupChecked = computed(() => (groupId: number) => (props.isCompare ? compareFilterPoi.value.selectPoiGroup.includes(groupId) : localFilterPois.value.selectPoiGroup.includes(groupId)));
    const poiLabelActive = ref<string[]>([]);

    let timeOut: ReturnType<typeof setTimeout>;

    const updatePoisLabels = () => {
      setTimeout(() => {
        const labels: Record<string, string> = {};
        const dp = new Set<string>();
        computedPois.value.forEach((p) => {
          Object.keys(p.params).forEach((k: string) => {
            if (![undefined, null, true, false, -1].some((e) => e === p.params[k]) && !['y', 'x', 'n_prob'].includes(k)) {
              dp.add(k);
            }
          });
        });

        Array.from(dp).sort((a, b) => {
          const indexA = OrderedPoiKeys.indexOf(a);
          const indexB = OrderedPoiKeys.indexOf(b);

          if (indexA === -1 && indexB === -1) {
            return 0; // Если оба элемента отсутствуют в OrderedPoiKeys, они равнозначны
          } if (indexA === -1) {
            return 1; // Если элемент a отсутствует, он идет после элемента b
          } if (indexB === -1) {
            return -1; // Если элемент b отсутствует, он идет после элемента a
          }
          return indexA - indexB;
        }).forEach((a) => {
          labels[a] = '';
        });

        poisLabels.value = labels;
      }, 150);
    };

    const toggleGroupCheck = (groupId: number) => {
      const idx = localFilterPois.value?.selectPoiGroup.indexOf(groupId);

      if (idx === -1) {
        localFilterPois.value?.selectPoiGroup.push(groupId);
      } else {
        localFilterPois.value?.selectPoiGroup.splice(idx, 1);
      }
      updatePoisLabels();
    };

    const openFilterInfo = () => {
      ElMessageBox.alert(
        'Вы можете использовать чекбокс «Включить фильтр» для включения или отключения фильтрации по параметрам. При этом ваш выбор параметров не очищается.', 'Информация о работе с фильтрами по параметрам.',
        {
          confirmButtonText: 'Закрыть',
          type: 'info',
          dangerouslyUseHTMLString: true,
        },
      );
    };

    const selectPoi = (poi: PoiModel | UnwrapRef<PoiModel> | undefined) => {
      setActivePoi(poi as PoiModel);
      mapLayer.value?.setActivePoi(poi?.id);
      if (poi !== undefined) {
        flyToLngLat(mapModel.value?.map as Map, { lng: poi.x, lat: poi.y }, 15);
      }
    };

    const isActivePoi = (poi: PoiModel | UnwrapRef<PoiModel>) => activePoi.value?.id === poi.id;

    const setLabelLayer = () => {
      Object.keys(mapLayer.value.poisLabels).forEach((key) => {
        if (poiLabelActive.value.some((b) => b === key)) {
          mapLayer.value.poisLabels[key] = (t.value(key) || key);
        } else {
          mapLayer.value.poisLabels[key] = '';
        }
      });
    };

    const toggleLabel = (key: string) => {
      if (mapLayer.value !== undefined) {
        const idx = poiLabelActive.value.indexOf(key);
        if (idx === -1 && poiLabelActive.value.length < 3) {
          poiLabelActive.value.push(key);
        } else if (idx !== -1) {
          poiLabelActive.value.splice(idx, 1);
        }
        setLabelLayer();
        mapLayer.value.redrawLabels();
      }
    };

    const updateLabel = () => {
      if (mapLayer.value) {
        if (mapLayer.value.showTextLabel !== undefined && mapLayer.value.showTextName !== undefined) {
          mapLayer.value.showTextLabel = showText.value.label;
          mapLayer.value.showTextName = showText.value.name;
        }
        EventBus.$emit(EventsEnum.MapRedrawPois);
      }
    };

    const toggleLabelText = () => {
      updateLabel();
      mapLayer.value.redrawLabels();
      window.localStorage.setItem('poi-show-text', JSON.stringify(showText.value));
    };

    onMounted(async () => {
      if (!props.isCompare) {
        await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
        mapLayer.value = mapModel.value?.render(new PoiCollection([])) as MapLayerPoiModel;
      }
    });

    onMounted(async () => {
      if (props.isCompare) {
        await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, `${MapContainerEnum.MAIN_MAP}-compare`);
        mapLayer.value = compareMap.value?.render(new PoiCollection([])) as MapLayerPoiModel;
      }
    });
    const poiImg = (poi: PoiModel) => poi.photos.map((item) => ({
      src: `${process.env.VUE_APP_API_URL}/scouting/photo/${item.id}/file/?size=70&access_token=${accessToken.value}`,
      alt: item.name,
    })).shift();

    const showPOITable = (groupId: number) => {
      useApp().setLoadingScreen('open-poi-table', 'Загрузка данных таблицы..');
      setTimeout(() => {
        activeTableId.value = groupId;
      }, 500);
    };
    const setHoverBtn = (enter: string) => {
      enterElementBtn.value = enter;
    };

    const editGroupActivate = (group: PoiGroupModel) => {
      groupEditForm.value.name = group.name || '';
      groupEditForm.value.type = group.type || 0;
      _group.value = group;
      editGroupActive.value = true;
    };
    const deletePoiGroup = (group: PoiGroupModel) => {
      ElMessageBox.confirm(
        `Вы хотите удалить группу точек ${group.name}? Все точки интереса, входящие в текущую группу также будут удалены!`,
        'Удаление группы точек',
        {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      )
        .then(() => {
          PoisList.deletePoiGroup(group);
          localFilterPois.value.selectPoiGroup = localFilterPois.value.selectPoiGroup.filter((a) => a !== group.id);
          // mapLayer.value?.redraw(filteredPois.value as PoiModel[]);
        });
    };

    const openExport = (group: PoiGroupModel) => {
      exportDialog.value = true;
      _group.value = group;
    };

    const exportFileUri = (format: 'esri' | 'kml') => `${process.env.VUE_APP_API_URL}/gis/export_poi_params/?poigroup=${_group.value?.id}&format=${format}&access_token=${accessToken.value}`;

    const uniqueNameGroupEdit = (rule: any, value: any, callback: any) => {
      if (PoisList.groups.value?.some((f) => f.name === groupEditForm.value.name && f.id !== _group.value?.id)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const formGroupAddRef = shallowRef<FormInstance>();
    const formGroupEditRef = shallowRef<FormInstance>();

    const groupEditRules = reactive<FormRules>({
      name: [...RulePoiGroupName,
        {
          validator: uniqueNameGroupEdit,
          trigger: ['blur', 'change'],
        },
      ],
    });

    const addGroupActive = ref(false);

    const addGroupActivate = () => {
      addGroupActive.value = true;
    };
    const groupAddSave = async () => {
      const formData = new FormData();
      formData.append('name', groupAddForm.value.name);
      formData.append('grouptype', groupAddForm.value.type.toString());
      formData.append('farmunit', (StructList.activeStruct.value?.id || 0).toString());
      const { data } = await ApiService.scouting.addPoigroup(formData);
      PoisList.addGroups(new PoiGroupModel(data));
      addGroupActive.value = false;
    };

    const onGroupAddSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          groupAddSave();
        }
      });
    };

    const groupEditSave = async () => {
      if (_group.value) {
        const formData = new FormData();
        formData.append('name', groupEditForm.value.name);
        formData.append('grouptype', groupEditForm.value.type.toString());
        formData.append('farmunit', (StructList.activeStruct.value?.id || 0).toString());
        const id = _group.value.id;
        await ApiService.scouting.putPoigroupEdit(id, formData);
        PoiEvents.emitUpdatePoiGroup();
        _group.value.name = groupEditForm.value.name;
        _group.value.type = groupEditForm.value.type;
        editGroupActive.value = false;
      }
    };

    const onGroupEditSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          groupEditSave();
        }
      });
    };

    PoiEvents.onClick((a: Feature, container: string) => {
      if (container === mapModel.value?.container) {
        setActivePoi(PoisList.getPoiModel(Number(a?.id)));
        mapLayer.value?.setActivePoi(Number(a?.id));
      }
    });

    const toggleFilterLabel = (key: string) => {
      const index = localFilterPois.value?.filterKeys.indexOf(key);
      if (index === -1) {
        localFilterPois.value?.filterKeys.push(key);
      } else if (index !== undefined) {
        localFilterPois.value?.filterKeys.splice(index, 1);
      }
    };

    const openAddPoi = () => {
      showAddPoi.value = true;
    };

    const accNewPoi = ref<PoiModel[]>([]);
    PoiEvents.onAddPoi((poi: PoiModel) => {
      accNewPoi.value.push(poi);
      mapLayer.value?.redraw([...filteredPois.value, ...accNewPoi.value] as PoiModel[]);
    });

    const closeAddPoi = () => {
      mapLayer.value?.redraw(filteredPois.value as PoiModel[]);
      accNewPoi.value = [];
    };

    watch(filteredPois, () => {
      clearTimeout(timeOut);
      if (mapLayer.value !== undefined) {
        timeOut = setTimeout(() => {
            mapLayer.value?.redraw(filteredPois.value as PoiModel[]);
            setLabelLayer();
            mapLayer.value?.redrawLabels();
        },
        300);
      }
    });

    return {
      PoisList,
      computedPois,
      toggleGroupCheck,
      isGroupChecked,
      POIGroupTypeEnum,
      Search,
      Filter,
      InfoFilled,
      activeFiltersForm,
      activeSettingsLabels,
      openFilterInfo,
      activeFilter,
      localFilterPois,
      selectPoi,
      isActivePoi,
      mapLayer,
      toggleLabel,
      toggleLabelText,
      showText,
      poiLabelActive,
      filteredPois,
      activePoi,
      poisLabels,
      poiImg,
      Download,
      Edit,
      Delete,
      showPOITable,
      setHoverBtn,
      enterElementBtn,
      editGroupActivate,
      deletePoiGroup,
      openExport,
      exportDialog,
      exportFileUri,
      groupEditForm,
      editGroupActive,
      groupEditRules,
      formGroupAddRef,
      onGroupAddSubmit,
      onGroupEditSubmit,
      addGroupActivate,
      formGroupEditRef,
      addGroupActive,
      groupAddForm,
      showEditBlock,
      activeTableId,
      groupAddRules,
      toggleFilterLabel,
      informationMode,
      openAddPoi,
      showAddPoi,
      closeAddPoi,
    };
  },
});
</script>
