<template lang="pug" src="./POITable.pug"/>
<style lang="scss" src="./POITable.scss"/>
<script  lang="ts">
import { POIParams } from '@/assets/data/POIParams';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import SortCaret from '@/components/ui/SortCaret/SortCaret.vue';
import { useApp } from '@/composables/useApp';
import { useAuth } from '@/composables/useAuth';
import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import { TableKeyEnum } from '@/constants/enums/TableKeyEnum';
import { SimpleType } from '@/constants/types/BasedTypes';
import { UiTableKeyType } from '@/constants/types/ui/UiTableKeyType';
import { formatNumber } from '@/lib/convertors/formatNumber';
import ApiService from '@/services/api/ApiService';
import LoggerService from '@/services/logger/LoggerService';
import { isObject } from '@/utils/isObject';
import { pythonRound } from '@/utils/pythonRound';
import { Document, Plus, Search } from '@element-plus/icons-vue';
import { useTranslate } from '@tolgee/vue';
import { ElMessageBox } from 'element-plus';
import {
  computed, defineComponent, onMounted, PropType, ref, toRefs, watch,
} from 'vue';
import PoisList from '@/modules/poi/PoisList';

export default defineComponent({
  name: 'POITable',
  components: {
    UiIcon,
    Search,
    Document,
    Plus,
    SortCaret,
  },
  props: {
    keys: {
      type: Array as PropType<UiTableKeyType[]>,
      required: true,
    },
    data: {
      type: Array as PropType<Record<string, SimpleType | {
        name: string,
        photoList?: string[]
      }>[]>,
      required: true,
    },
    searchAble: {
      type: Boolean,
      default: true,
    },
    hideAble: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:keys', 'click', 'change', 'clear', 'addColumn', 'deleteColumn', 'renameColumn'],
  setup(props, { emit }) {
    const count = ref(0);
    const { data, keys } = toRefs(props);
    const keyToSort = ref('');
    const sortOrder = ref(0);
    const table = ref();
    const tableHeader = ref();
    const widthList = ref<Record<string, number>>({});
    const searchValue = ref('');
    const searchParam = ref();
    const activeKey = ref<UiTableKeyType>();
    const activeRow = ref<Record<string, SimpleType>>();
    const activeValue = ref();
    const activeRowIndex = ref<number>();
    const localKeys = ref([...keys.value]);
    const localData = ref([...data.value]);
    const showListParams = ref([...keys.value?.filter((a) => !a.hideAble)?.map((a) => a.name)]);
    const { t } = useTranslate('ui-table');
    const width = ref(0);
    const editingKey = ref<UiTableKeyType>();
    const activeKeyValue = ref('');
    const poiCount = computed(() => localData.value.length);

    const isObjectInName = (obj: any, key: string): obj is {name: string, photoList?: string[]} => isObject(obj) && key in obj;

    const computedOptions = computed(() => [{ name: undefined, alias: '---' }, ...localKeys.value.filter((option) => showListParams.value.includes(option.name))]);

    const computedData = computed(() => [...localData.value].sort((a, b) => {
      if (sortOrder.value === 0 && keyToSort.value === '') {
        return 0;
      }

      const aValue = a[keyToSort.value];
      const bValue = b[keyToSort.value];

      if (![null, undefined].some((v) => v === aValue) && ![null, undefined].some((v) => v === bValue)) {
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return sortOrder.value === 1 ? aValue - bValue : bValue - aValue;
        }
        if (
          isObjectInName(aValue, 'name')
          && isObjectInName(bValue, 'name')
        ) {
          return sortOrder.value === 1
            ? aValue!.name.localeCompare(bValue!.name, undefined, { numeric: true, sensitivity: 'base' })
            : bValue!.name.localeCompare(aValue!.name, undefined, { numeric: true, sensitivity: 'base' });
        }

        return sortOrder.value === 1
          ? aValue!.toString().localeCompare(bValue!.toString(), undefined, { numeric: true, sensitivity: 'base' })

          : bValue!.toString().localeCompare(aValue!.toString(), undefined, { numeric: true, sensitivity: 'base' });
      }
      if ([null, undefined].some((v) => v === aValue)) {
        return sortOrder.value === 1 ? 1 : -1;
      }
      return sortOrder.value === 1 ? -1 : 1;
    }));

    const onResize = (w: number) => {
      width.value = w;
    };

    const computedStickKey = computed(() => localKeys.value.map((a) => {
      const element = document.getElementById(`${a.name}Head`);
      return {
        name: a.name,
        lock: a.lock,
        width: element?.getBoundingClientRect().width || 0,
      };
    }));

    const searchData = computed(() => computedData.value.filter((a) => {
      if (searchParam.value) {
        const paramValue = a[searchParam.value] || '';

        if (isObjectInName(paramValue, 'name')) {
          return paramValue?.name.toString().toLowerCase().includes(searchValue.value.toLowerCase());
        }

        return paramValue?.toString().toLowerCase().includes(searchValue.value.toLowerCase());
      }

      if (searchParam.value === undefined) {
        return Object.values(a).some((value) => {
          if (isObjectInName(value, 'name')) {
            return value.name.toString().toLowerCase().includes(searchValue.value.toLowerCase());
          }
          return value?.toString().toLowerCase().includes(searchValue.value.toLowerCase());
        });
      }

      return true;
    }) || []);
    const filteredPoiCount = computed(() => searchData.value.length);
    const computedColumnValue = computed(() => (key: UiTableKeyType) => {
      if ([TableKeyEnum.int, TableKeyEnum.number].some((a) => a === key.type)) {
        const _data: number[] = localData.value.map((row) => Number(row[key.name])).filter((v) => !Number.isNaN(v) && v !== -1);

        const length = _data.length;
        let sum = 0;
        _data.forEach((v) => {
          sum += v;
        });

        switch (key.columnMetrics) {
        case 0: // Сумма значений
          return formatNumber(sum, POIParams[key.name].precision || 2);
        case 1: // Среднее значение
          return length > 0 && sum > 0 ? formatNumber(sum / length, key.precision || 0) : 0;
        case 2: // Максимальное значение
          return Math.max(..._data) || 0;
        case 3: // Минимальное значение
          return Math.min(..._data) || 0;
        default: // Неизвестная метрика
          LoggerService.error(`Unknown column metric: ${key.columnMetrics}`);
          return '';
        }
      } else {
        return '';
      }
    });

    const prepareSortingKey = (key: string) => {
      if (keyToSort.value !== key) {
        keyToSort.value = key;
        sortOrder.value = 1;
      } else if (sortOrder.value !== 2) {
        sortOrder.value++;
      } else {
        sortOrder.value = 0;
        keyToSort.value = '';
      }
    };

    const shadowHeaderWidth = computed(() => (key: string) => {
      const a = count.value + 1;
      let totalWidth = 0;
      computedStickKey.value.find((obj) => {
        if (obj.name === key) {
          return true;
        }
        if (obj.lock) {
          totalWidth += obj.width;
        }
        return false;
      });

      return `${totalWidth}px` || 'auto';
    });
    const shadowHeaderHeight = computed(() => (i: number) => {
      const a = count.value + 1;
      return a ? table.value?.getElementsByClassName('UiTable-mapContainer-header-item')[i]?.getBoundingClientRect().height || 0 : 0;
    });

    const isActiveCell = computed(() => (key: string, index: number) => activeKey.value?.name === key && activeRowIndex.value === index);

    const formatActiveValue = (key: UiTableKeyType, value: any) => {
      if ([TableKeyEnum.int, TableKeyEnum.number].includes(key.type)) {
        if (Number.isNaN(Number(value)) || Number(value) === -1) {
          return null;
        }
        return Number(value);
      }
      return value;
    };

    const setActiveCell = (evt: MouseEvent, key: UiTableKeyType, row: Record<string, SimpleType>, index: number) => {
      if ((evt.target as HTMLElement).tagName === 'IMG') {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        return;
      }
      emit('click', key, row);
      if (key.type === TableKeyEnum.poiName && isObjectInName(searchData.value[index][key.name], 'name')) {
        activeKey.value = key;
        activeRow.value = row;
        activeRowIndex.value = index;
        // @ts-ignore
        activeValue.value = searchData.value[index][key.name]?.name;
      } else if (key.type !== TableKeyEnum.view) {
        activeKey.value = key;
        activeRow.value = row;
        activeRowIndex.value = index;
        activeValue.value = formatActiveValue(key, searchData.value[index][key.name]);
      }
    };

    const change = () => {
      if (activeRowIndex.value !== undefined && activeKey.value && activeKey.value?.type === TableKeyEnum.poiName) {
        // @ts-ignore
        searchData.value[activeRowIndex.value][activeKey.value.name].name = activeValue.value;
        emit('change', activeKey.value, activeValue.value);
      } else if (activeRowIndex.value !== undefined && activeKey.value) {
        searchData.value[activeRowIndex.value][activeKey.value.name] = activeValue.value;
        emit('change', activeKey.value, activeValue.value);
      }
    };

    watch(showListParams, (a, b) => {
      const set1 = new Set(a);
      const set2 = new Set(b);

      const missingInArray1 = [...set2].find((element) => !set1.has(element));
      const missingInArray2 = [...set1].find((element) => !set2.has(element));

      if (missingInArray1) {
        const element = localKeys.value.find((k) => k.name === missingInArray1);
        if (element) {
          element.hideAble = !element.hideAble;
          emit('update:keys', localKeys.value);
        }
      } else {
        const element = localKeys.value.find((k) => k.name === missingInArray2);
        if (element) {
          element.hideAble = !element.hideAble;
          emit('update:keys', localKeys.value);
        }
      }
    });

    const calculateRows = () => {
      const rows: any[][] = localData.value.map((obj) => {
        const row = localKeys.value.filter((a) => a.tableDownload).map((key: UiTableKeyType) => {
          const a = obj[key.name];
          if (isObjectInName(a, 'name') && key.type === 'name') {
            return a!.name || '';
          }

          if (a === undefined) return -1;

          return a;
        });
        return row;
      });
      return rows;
    };

    const calculateHeaders = () => {
      const keyList: string[] = [];
      localKeys.value.forEach((a) => {
        if (a.tableDownload) {
          keyList.push(a.name);
        }
      });
      return keyList;
    };

    const createTableCsv = () => {
      ApiService.etc.postTable({
        file_format: 'csv', headers: calculateHeaders(), rows: calculateRows(), style: {},
      }).then((resp) => {
        const url = new Blob([resp.data], { type: 'text/csv' });
        const link = document.createElement('a');
        const urls = URL.createObjectURL(url);
        link.href = urls;
        link.setAttribute('download', resp.headers['content-disposition'].match(/filename="([^"]+)"/)[1]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };
    const createTableXlsx = () => {
      const header = '';
      const headers = {
        Authorization: useAuth().getBearer(),
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        Expires: '0',
      };

      const dataPost = {
        file_format: 'xlsx',
        headers: calculateHeaders(),
        rows: calculateRows(),
        style: {},
      };

      fetch(`${process.env.VUE_APP_BASE_URL}/api/v1/etc/tools/table/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(dataPost),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', header);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    };
    const createTableHtml = () => {
      ApiService.etc.postTable({
        file_format: 'html', headers: calculateHeaders(), rows: calculateRows(), style: {},
      }).then((resp) => {
        const url = new Blob([resp.data], { type: 'text/csv' });
        const link = document.createElement('a');
        const urls = URL.createObjectURL(url);
        link.href = urls;
        link.setAttribute('download', resp.headers['content-disposition'].match(/filename="([^"]+)"/)[1]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };

    const lockColumn = (key: string) => {
      const element = localKeys.value.find((k) => k.name === key);
      count.value++;
      if (element) {
        element.lock = !element.lock;
        emit('update:keys', localKeys.value);
      }
    };

    onMounted(() => {
      setTimeout(() => {
        useApp().setLoadingScreen('open-poi-table');
      }, 500);
    });

    // eslint-disable-next-line no-nested-ternary,vue/no-side-effects-in-computed-properties
    const categoryOrder = [
      POICategoryEnum.NAME,
      POICategoryEnum.COORDS,
      POICategoryEnum.SOIL,
      POICategoryEnum.LIMIT_FACTOR,
      POICategoryEnum.DESCRIPTION,
      POICategoryEnum.COMMENTS,
      POICategoryEnum.CUT,
      POICategoryEnum.AGRO_CHEMISTRY,
      POICategoryEnum.NOT_STANDARDIZED,
    ];
    const sortedKeys = computed(() => [...localKeys.value].sort((a, b) => {
      if (a.lock && b.lock) {
        return 0;
      }
      if (a.lock) {
        return -1;
      }
      if (b.lock) {
        return 1;
      }
      if (a.params.category !== b.params.category) {
        return categoryOrder.indexOf(a.params.category) - categoryOrder.indexOf(b.params.category);
      }
      return 0;
    }));

    const addColumn = (name: string) => {
      const element: UiTableKeyType = {
        name,
        lock: false,
        hideAble: false,
        type: TableKeyEnum.string,
        sortAble: true,
        searchAble: true,
        editAble: true,
        params: {
          selectValues: [],
          clearAble: false,
          deleteAble: true,
          category: POICategoryEnum.NOT_STANDARDIZED,
        },
      };
      localKeys.value.push(element);
      showListParams.value.push(name);
      emit('addColumn', element);
    };

    const deleteColumn = (key: UiTableKeyType) => {
      localKeys.value = localKeys.value.filter((a) => a.name !== key.name);
      showListParams.value = [...localKeys.value?.filter((a) => !a.hideAble)?.map((a) => a.name)];
      emit('deleteColumn', key);
    };

    const confirmAddColumnConfirm = () => {
      ElMessageBox.prompt(t.value('input-column'), t.value('add-column-title'), {
        confirmButtonText: t.value('add'),
        cancelButtonText: t.value('revoke'),
        inputValidator: (text) => !localKeys.value.some((a) => a.name === text),
        inputErrorMessage: 'Данная колонка уже есть',
      })
        .then(({ value }) => {
          addColumn(value);
        });
    };

    const confirmDeleteColumn = (key: UiTableKeyType) => {
      ElMessageBox.confirm(t.value('body-delete-column'), t.value('delete-column'), {
        confirmButtonText: t.value('remove'),
        cancelButtonText: t.value('revoke'),
      })
        .then(() => {
          deleteColumn(key);
        });
    };

    const editKey = (key: UiTableKeyType) => {
      editingKey.value = key;
      activeKeyValue.value = key.name;
    };

    const changeKey = () => {
      if (editingKey.value) {
        emit('renameColumn', editingKey.value.name, activeKeyValue.value);
        localData.value = localData.value.map((a) => {
          const obj = { ...a };
          if (editingKey.value?.name) {
            obj[activeKeyValue.value] = obj[editingKey.value.name];
            delete obj[editingKey.value.name];
          }
          return obj;
        });
        if (editingKey.value?.alias) editingKey.value.alias = activeKeyValue.value;
        editingKey.value.name = activeKeyValue.value;
        editingKey.value = undefined;
        activeKeyValue.value = '';
      }
    };

    const addActiveValue = (value: string) => {
      const currentValue = activeValue.value ?? '';
      const rowIndex = activeRowIndex.value ?? -1;
      const keyName = activeKey.value?.name ?? '';

      searchData.value[rowIndex][keyName] = currentValue + value;
      setTimeout(() => {
        change();
      });
    };

    watch(editingKey, () => {
      activeKey.value = undefined;
    });
    watch(activeKey, () => {
      editingKey.value = undefined;
    });

    const changeInputNumber = (value: number) => {
      if (activeKey.value !== undefined && POIParams[activeKey.value?.name] && value !== null) {
        activeValue.value = pythonRound(value, POIParams[activeKey.value?.name].precision);
      } else {
        activeValue.value = value;
      }
    };
    return {
      searchData,
      prepareSortingKey,
      table,
      widthList,
      shadowHeaderWidth,
      tableHeader,
      shadowHeaderHeight,
      searchValue,
      Search,
      searchParam,
      isActiveCell,
      setActiveCell,
      activeValue,
      showListParams,
      Document,
      createTableCsv,
      change,
      lockColumn,
      localKeys,
      sortedKeys,
      onResize,
      confirmAddColumnConfirm,
      confirmDeleteColumn,
      editKey,
      editingKey,
      changeKey,
      activeKeyValue,
      Plus,
      computedColumnValue,
      createTableXlsx,
      createTableHtml,
      sortOrder,
      keyToSort,
      TableKeyEnum,
      addActiveValue,
      computedOptions,
      POIParams,
      changeInputNumber,
      poiCount,
      filteredPoiCount,
    };
  },
});
</script>
