import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { useStruct } from '@/composables/useStruct';
import { SeasonDto } from '@/services/api/dto/struct/SeasonDto';
import type { SeasonModel } from '@/models/season/SeasonModel';

export const endpoints = {
  getSeasons: (farmunit: number): string => `/api/v1/struct/seasons/${farmunit}/`,
  createSeason: (farmunit: number): string => `/api/v1/struct/seasons/${farmunit}`,
  patchSeason: (farmunit: number, seasonId: number): string => `/api/v1/struct/seasons/${farmunit}/${seasonId}`,
  deleteSeason: (farmunit: number, seasonId: number): string => `/api/v1/struct/seasons/${farmunit}/${seasonId}`,
};

export class ApiSeason extends ApiHandler {
  public async getSeasons(): Promise<ApiResponse<SeasonDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SeasonDto[]>(endpoints.getSeasons(unit));
  }

  // eslint-disable-next-line camelcase
  public async createSeason(data: { name: string; period_start: string; period_stop: string; note: string; }): Promise<ApiResponse<SeasonDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<SeasonDto>(endpoints.createSeason(unit), data);
  }

  public async updateSeason(season: SeasonModel): Promise<ApiResponse<SeasonDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<SeasonDto>(endpoints.patchSeason(unit, season.id), season.toJSON());
  }

  public async deleteSeason(season: SeasonModel): Promise<ApiResponse<SeasonDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<SeasonDto>(endpoints.deleteSeason(unit, season.id));
  }
}
