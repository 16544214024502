import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MapAreaInfo p-10 m-10" }
const _hoisted_2 = { class: "MapAreaInfo-chart" }

export function render(_ctx, _cache) {
  const _component_MapAreaChart = _resolveComponent("MapAreaChart")
  const _component_ui_dialog = _resolveComponent("ui-dialog")

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_ui_dialog, {
      title: _ctx.$t('area-info',{ns:'poi'}),
      modelValue: _ctx.computedOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.computedOpen) = $event)),
      width: 520,
      onClose: _cache[1] || (_cache[1] = $event => (_ctx.closeDrawer()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_MapAreaChart, { data: _ctx.computedChartData }, null, 8, ["data"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}