import {Feature, MultiPolygon} from 'geojson';
import {Model} from '@/models/Model';

export class MapDrawerModel extends Model {
  public polygon: Feature<MultiPolygon>

  constructor() {
    super();
    this.polygon = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'MultiPolygon',
        coordinates: [[[]]],
      },
    };
  }

  /** Индекусы выбранных пользовтелем полигонов */
  public selectedPolygons: number[] = []

  clearCoordinates(): void {
    if ('coordinates' in this.polygon.geometry) {
      this.polygon.geometry.coordinates = [[[]]];
    }
  }

  deleteHole(indexHole: number, indexPolygon = 0): void {
    if ('coordinates' in this.polygon.geometry) {
      (this.polygon.geometry.coordinates as [number, number][][][])[indexPolygon].splice(indexHole, 1);
    }
  }

  deletePolygon(indexPolygon = 0): void {
    if ('coordinates' in this.polygon.geometry) {
      (this.polygon.geometry.coordinates as [number, number][][][]).splice(indexPolygon, 1);
    }
  }
}
