import { ElNotification } from 'element-plus';
import { ApiErrorBodyType } from '@/constants/types/ApiErrorBodyType';
import LoggerService from '@/services/logger/LoggerService';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';

export class ApiError extends Error {
  public code = '';

  constructor(x: ApiErrorBodyType) {
    super(x.message || x.title);
    this.code = String(x.statusCode) || String(x.code) || '0';
    if (this.code !== '401') {
      this.errorNotification(x);
    }
    if (x.code === 'payment_required') {
      // window.location.href = '/struct-select';
    }
    if (this.code === '503' || this.code === '502') {
      window.location.href = '/maintenance';
    }
  }

  errorNotification(err: any) {
    let title = '';

    const supportMessage = '<br/><strong>Пожалуйста, обратитесь в <a href="mailto: helpdesk@truefields.ru">службу поддержки</a>.</strong>';

    switch ((err.code || '').trim()) {
    case 'error': title = 'Ошибка сервера'; break;
    case 'invalid': title = 'Ошибка при валидации'; break;
    case 'parse_error': title = 'Ошибка формата запроса'; break;
    case 'authentication_failed': title = 'Ошибка авторизации'; break;
    case 'not_authenticated': title = 'Ошибка доступа, требуется авторизация'; break;
    case 'permission_denied': title = 'Ошибка сервера, у пользователя недостаточно прав'; break;
    // case 'not_found': title = 'Страница или объект не найден'; break;
    case 'method_not_allowed': title = 'Метод не разрешен'; break;
    case 'not_acceptable': title = 'Указанный Accept Header не разрешен'; break;
    case 'unsupported_media_type': title = 'Указанный Media Type не разрешен'; break;
    case 'throttled': title = 'Лимитирующее ограничение'; break;
    case 'payment_required': title = 'Ограничения по тарификации'; break;
    default: title = ''; break;
    }

    LoggerService.error(err);

    if (title) {
      ElNotification({
        title,
        message: err.detail + supportMessage,
        type: 'error',
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
      });
    } else {
      LoggerService.from(LoggerGroupsEnum.API).group(err);
    }
  }
}
