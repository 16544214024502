type GenerateCardNameOptions = {
  pattern?: string;
  usedNames?: Set<string>;
  useNumber?: boolean;
  useRandom?: boolean;
  useTimestamp?: boolean;
  randomLength?: number;
  numberRange?: number;
};

export const generateCardName = ({
  pattern = 'Task-{number}-{random}-{timestamp}',
  usedNames = new Set<string>(),
  useNumber = true,
  useRandom = true,
  useTimestamp = true,
  randomLength = 6,
  numberRange = 1000,
}: GenerateCardNameOptions = {}): string => {
  const getRandomChar = (): string => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return chars[Math.floor(Math.random() * chars.length)];
  };

  const getRandomString = (length = 5): string => Array.from({ length }, getRandomChar).join('');

  const getTimestamp = (): string => new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];

  let uniqueName: string;
  do {
    uniqueName = pattern
      .replace('{number}', useNumber ? String(Math.floor(Math.random() * numberRange)) : '')
      .replace('{random}', useRandom ? getRandomString(randomLength) : '')
      .replace('{timestamp}', useTimestamp ? getTimestamp() : '')
      .replace(/-{2,}/g, '-') // Убираем лишние дефисы
      .replace(/^-|-$/g, ''); // Убираем дефисы в начале и конце
  } while (usedNames.has(uniqueName));

  usedNames.add(uniqueName);
  return uniqueName;
};

// 📦 **Примеры использования:**
const usedNames = new Set<string>();
