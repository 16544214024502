import {
  Feature, LineString, MultiPolygon, Polygon,
} from 'geojson';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import { sliceLineStringByCoords } from '@/lib/map/sliceLineStringByCoords';
import { sortCoordinatesByLine } from '@/lib/map/sortCoordinatesByLine';
import { removeDuplicatesCordsByPolygon } from '@/lib/map/removeDuplicatesCordsByPolygon';
import {
  booleanClockwise,
  difference,
  featureCollection,
  lineString,
  multiPolygon,
  polygon,
} from '@turf/turf';

export const splitPolygonByLine = (_polygon: Feature<Polygon>, line: Feature<LineString>): Feature<MultiPolygon> => {
  const polygonCoords = booleanClockwise(_polygon.geometry.coordinates[0]) ? _polygon.geometry.coordinates[0] : [..._polygon.geometry.coordinates[0]].reverse();
  const spliterCoords = booleanClockwise(line) ? line.geometry.coordinates : [...line.geometry.coordinates].reverse();

  const intersectLines = findCurveIntersections(lineString(polygonCoords), lineString(spliterCoords));

  const firstPoly = [[]];
  const secondPoly = [[]];

  const sortCordsByPolygon = sortCoordinatesByLine(intersectLines, lineString(polygonCoords));
  const _f1 = sliceLineStringByCoords(polygonCoords[0], sortCordsByPolygon[0], lineString(polygonCoords)).geometry.coordinates;

  const _f2 = sliceLineStringByCoords(intersectLines[0], intersectLines[1], lineString(spliterCoords)).geometry.coordinates;
  const _f3 = sliceLineStringByCoords(polygonCoords[0], sortCordsByPolygon[1], lineString([...polygonCoords].reverse())).geometry.coordinates.reverse();

  if (_f1.at(-1)?.toString() !== _f2[0]?.toString()) {
    _f2.reverse();
  }

  if (_f2.at(-1)?.toString() !== _f3[0]?.toString()) {
    _f3.reverse();
  }

  firstPoly[0].push(..._f1);
  firstPoly[0].push(..._f2);
  firstPoly[0].push(..._f3);

  const _s1 = sliceLineStringByCoords(intersectLines[0], intersectLines[1], lineString(polygonCoords)).geometry.coordinates;
  const _s2 = sliceLineStringByCoords(intersectLines[1], intersectLines[0], lineString(spliterCoords)).geometry.coordinates.reverse();

  if (_s1.at(-1)?.toString() !== _s2[0]?.toString()) {
    _s2.reverse();
  }

  secondPoly[0].push(..._s1);
  secondPoly[0].push(..._s2);

  const holes = _polygon.geometry.coordinates.filter((a, index) => index !== 0);
  holes.forEach((hole) => {
    const holePoly = polygon([hole]);
    const _firstPoly = polygon([firstPoly[0]]);
    const feature1 = featureCollection([_firstPoly, holePoly]);
    const firstDiff = difference(feature1);
    const secondDiff = difference(featureCollection([polygon([secondPoly[0]]), polygon([hole])]));
    firstPoly.splice(0, firstPoly.length, ...firstDiff.geometry.coordinates);
    secondPoly.splice(0, secondPoly.length, ...secondDiff.geometry.coordinates);
  });

  firstPoly[0] = removeDuplicatesCordsByPolygon(firstPoly[0]);
  secondPoly[0] = removeDuplicatesCordsByPolygon(secondPoly[0]);
  return multiPolygon([firstPoly, secondPoly]);
};
