import {
  Feature, LineString, Polygon, Position,
} from 'geojson';
import {
  area,
  booleanPointInPolygon,
  featureCollection,
  lineString,
  point,
  polygon,
  union,
  booleanClockwise,
  lineIntersect,
} from '@turf/turf';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import { sliceLineStringByCoords } from '@/lib/map/sliceLineStringByCoords';
import { sortCoordinatesByLine } from '@/lib/map/sortCoordinatesByLine';
import { splitPolygonByCurve } from '@/lib/map/splitPolygonByCurve';
import { removeDuplicatesCordsByPolygon } from '@/lib/map/removeDuplicatesCordsByPolygon';
import getLinesIntersection from '@/lib/map/getLinesIntersection';
import { concatPolygonAndLine } from '@/lib/map/concatPolygonAndLine';
import { getOutsideSegmentIndexes } from '@/lib/map/getOutsideSegmentIndexes';
import { getInsideSegmentIndexes } from '@/lib/map/getInsideSegmentIndexes';
import { log } from 'geotiff/dist-node/logging';
import { isPointInline } from './isPointInline';

const findMaxIndex = (arr: number[]): number => {
  let maxIndex = 0; // Индекс максимального элемента
  let maxValue = arr[0]; // Значение максимального элемента

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > maxValue) {
      maxValue = arr[i];
      maxIndex = i;
    }
  }

  return maxIndex;
};

export const reshapePolygon = (_polygon: Feature<Polygon>, curve: Feature<LineString>): Feature<Polygon> => {
  const polygonCoords = [..._polygon.geometry.coordinates[0]];
  const lineCoords = [...curve.geometry.coordinates];
  const unionCandidates = [];
  const favoriteUnionPolygonIndex = -1;

  const intersectPosition = sortCoordinatesByLine(
    findCurveIntersections(lineString(lineCoords), lineString(_polygon.geometry.coordinates[0])),
    lineString(lineCoords),
  );
  const outsideSegmentIndexes = lineCoords
    .map((pointCoords, index) => (booleanPointInPolygon(point(pointCoords), _polygon) ? false : index))
    .filter((value) => typeof value === 'number');
  const polygons = splitPolygonByCurve(_polygon, curve).geometry.coordinates;
  const areasPolygon = polygons.map((v) => area(polygon(v)));
  const favoriteAreaPolygonIndex = findMaxIndex(areasPolygon);

  const isAllOutside = outsideSegmentIndexes.every((v, i, arr) => i === arr.length - 1 || v + 1 === arr[i + 1]);

  if (isAllOutside && intersectPosition.length >= 4) {
    intersectPosition.splice(0, 1);
    intersectPosition.splice(-1, 1);
  } else if (lineCoords.length >= 3) {
    if (outsideSegmentIndexes[0] === 0) {
      intersectPosition.splice(0, 1);
    }

    if (lineCoords.at(-1) === lineCoords[outsideSegmentIndexes.at(-1)]) {
      intersectPosition.splice(-1, 1);
    }
  }

  const intersectPositionsCandidates = intersectPosition.reduce<[Position, Position][]>((acc, pos, index, arr) => {
    if (index % 2 === 1) {
      acc.push([arr[index - 1], pos]);
    }
    return acc;
  }, []);

  if (lineCoords.length >= 3) {
    const arrPair: [number, number] = [-1, -1];
    // Создаем кандидатов для объединения
    for (let i = 0; i < intersectPosition.length - 1; i += 2) {
      const startIndex = intersectPosition[i];
      const endIndex = intersectPosition[i + 1];
      lineCoords.forEach((lc, idx, arr) => {
        if (idx !== arr.length - 1) {
          if (isPointInline(lc, arr[idx + 1], startIndex)) {
            arrPair[0] = (idx);
          }
          if (isPointInline(lc, arr[idx + 1], endIndex)) {
            arrPair[1] = idx + 1;
            const _coords = arr.slice(arrPair[0], arrPair[1] + 1);
            unionCandidates.push(_coords);
            arrPair.splice(0, 2);
          }
        }
      });
    }

    // Проверяем пересечения с основным полигоном
    // const mainPolygon = polygons[favoriteAreaPolygonIndex];
    // unionCandidates.forEach((candidate, index) => {
    //   const intersections = findCurveIntersections(
    //     lineString(mainPolygon[0]),
    //     lineString(candidate),
    //   );
    //
    //   if (intersections.length > 0) {
    //     console.log(`Найдены пересечения для кандидата ${index}:`, intersections);
    //   }
    // });
  }

  let _polygon1: Feature<Polygon> = polygon(polygons[favoriteAreaPolygonIndex]);

  const existingPoints = _polygon1.geometry.coordinates[0];
  const newPoints = unionCandidates.flat();

  const pointsMatch = newPoints.every((newPoint) => existingPoints.some((existingPoint) => existingPoint[0] === newPoint[0] && existingPoint[1] === newPoint[1]));

  if (!pointsMatch) {
    console.log('no walid');
    // throw new Error('Неправильная геометрия');
  }

  unionCandidates.forEach((v, i2) => {
    _polygon1 = concatPolygonAndLine(_polygon1, lineString(v), intersectPositionsCandidates[i2]);
  });

  if (favoriteUnionPolygonIndex !== -1 && favoriteAreaPolygonIndex !== favoriteUnionPolygonIndex) {
    return _polygon1;
  }
  return _polygon1;
};
