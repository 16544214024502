import { ref } from 'vue';
import EventBus from '@/services/eventBus/EventBus';
import { MultiPolygonModel } from '@/models/geojson/MultiPolygonModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { getByIntersectFeatures } from '@/lib/map/getByIntersectFeatures';
import { difference, featureCollection } from '@turf/turf';
import { FieldModel } from '@/models/field/FieldModel';
import mapboxgl from 'mapbox-gl';
import {
  Feature, MultiPolygon, Polygon, Position,
} from 'geojson';

const activeDrawer = ref(false);
const activeAction = ref<''|'addRing'|'addPolygon'|'delete'|'cut'|'outsideInside'>('addPolygon');
const drawerModel = ref<MultiPolygonModel>();
const drawerLayer = ref<MapLayerDrawerModel>();
const lineStringModel = ref<MultiPolygonModel>();
const historyCoords = ref([]);
const activeHistoryIndex = ref<number>(-1);
const drawerMode = ref<string>('create');
const isHavePolygon = ref(false);

export const useDrawer = () => {
  const cutDifference = () => {
    drawerModel.value.deleteDifference();
    EventBus.$emit('drawerLogType', 'deleteDifference');
    drawerLayer.value.refreshPolygin();
  };

  const deleteHole = (indexRing: number, indexGeometry: number) => {
    if (indexRing > 0) {
      drawerModel.value.getPolygon(indexGeometry).deleteLinearRing(indexRing);
      drawerLayer.value.refreshPolygin();
      EventBus.$emit('drawerLogType', 'deleteHole');
    }
  };

  const deletePolygon = (indexGeometry: number) => {
    drawerModel.value.deletePolygon(indexGeometry);
    drawerLayer.value.refreshPolygin();
    EventBus.$emit('drawerLogType', 'deletePolygon');
  };

  const union = () => {
    drawerModel.value.union(drawerModel.value.data.map((_, i) => i));
    EventBus.$emit('drawerLogType', 'union');
    drawerLayer.value.refreshPolygin();
  };

  const cutDifferenceByFields = (fields: FieldModel[], map: mapboxgl.Map) => {
    const intersectFeatures = getByIntersectFeatures(map, drawerModel.value.toFeature(), ['field']);
    const fieldsId = intersectFeatures.map((feature) => feature.id);
    const _fields = fields.filter((a) => fieldsId.includes(a.id)).map((f) => f.feature);
    const _f1 = drawerModel.value.data.map((a) => a.toFeature());
    const buffer: Feature<Polygon | MultiPolygon>[] = drawerModel.value.data.map((a) => a.toFeature());
    _f1.forEach((feature, index) => {
      _fields.forEach((field) => {
        const geometry = featureCollection([buffer[index], field]);
        const _difference = difference(geometry);
        buffer[index] = (_difference);
      });
    });
    const updetedCords: Position[][][] = buffer.flatMap((a) => (a.geometry.type !== 'Polygon' ? a.geometry.coordinates : [a.geometry.coordinates]));

    drawerModel.value.updatePolygons(updetedCords);
    EventBus.$emit('drawerLogType', 'deleteDifferenceByFields');
    drawerLayer.value.refreshPolygin();
  };
  return {
    activeDrawer,
    activeAction,
    cutDifference,
    drawerLayer,
    drawerModel,
    deleteHole,
    deletePolygon,
    union,
    cutDifferenceByFields,
    historyCoords,
    activeHistoryIndex,
    drawerMode,
    isHavePolygon,
  };
};
