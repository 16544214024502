import { Feature, LineString, MultiPolygon } from 'geojson';
import {
  booleanPointInPolygon, polygon, point, multiPolygon, lineString,
} from '@turf/turf';
import { splitPolygonByCurve } from '@/lib/map/splitPolygonByCurve';
import { splitPolygonByLine } from './splitPolygonByLine';
import { findCurveIntersections } from './findCurveIntersections';

/**
 * Разрезает полигоны в multiPolygon'е по указанной линии
 * @param multiPolygon Feature<MultiPolygon>
 * @param line Feature<LineString> одна линия содержащая 2 координаты
 */

export const splitMultiPolygonByLine = (_multiPolygon: Feature<MultiPolygon>, line: Feature<LineString>): Feature<MultiPolygon> => {
  const polygons = _multiPolygon.geometry.coordinates.flatMap((p) => splitPolygonByCurve(polygon(p), line).geometry.coordinates);

  return multiPolygon(polygons);
};
