import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import ApiService from '@/services/api/ApiService';
import { TaskMapMaterialType } from '@/constants/types/taskMap/TaskMapMaterialType';
import { TaskMapFactDto } from '@/services/api/dto/taskMap/TaskMapFactDto';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import chroma from 'chroma-js';
import { FeatureCollection } from 'geojson';

export class FieldTaskMapFactModel extends FieldTaskMapModel {
  public readonly availableMaterials: TaskMapMaterialType[] = [];

  public statistics;

  public ranges: { [key: string]: number[] } = {};

  public values: Record<number, number>[] = [];

  public skipColor = 'rgb(255, 255, 255)';

  public dropColor = 'rgb(150, 75, 0)';

  private readonly _materials: TaskMapMaterialType[] = [];

  constructor(dto: TaskMapFactDto) {
    super(dto);
    dto.materials.forEach((r) => {
      this._materials.push({
        id: r.id,
        task: r.task,
        name: r.name,
        key: r.key,
        borders: r.borders,
        order: r.order,
        dataType: r.data_type,
        differentialDetected: r.differential_detected,
        isActive: r.is_active,
        isTarget: r.is_target,
        targetMaterial: r.target_material,
      });
      this._materials.sort((a, b) => a.order - b.order);
    });
    this.availableMaterials = this.materials.filter((material) => material.dataType === 'number' && !material.isTarget);
    this._activeMaterial = this.availableMaterials.find((v) => !v.isTarget) || undefined;
  }

  private _stops: { from: number, to: number, color: string, norm?: number }[] = [];

  get stops(): { from: number; to: number; color: string; norm?: number }[] {
    return this._stops;
  }

  set stops(value: { from: number; to: number; color: string; norm?: number }[]) {
    this._stops = value;
  }

  private _activeMaterial: TaskMapMaterialType | undefined;

  get activeMaterial(): TaskMapMaterialType | undefined {
    return this._activeMaterial;
  }

  private _histogram: {from: number, to: number}[] = [];

  get histogram(): {from: number, to: number}[] {
    return this._histogram;
  }

  get materials(): TaskMapMaterialType[] {
    return this._materials;
  }

  setActiveMaterial(key: string | undefined): void {
    this._activeMaterial = this._materials.find((m) => m.key === key);
    this.setInputs();
  }

  async delete(): Promise<void> {
    await ApiService.taskMap.deleteTaskMap('fact', this.id);
  }

  async fetchData(): Promise<void> {
    if (!this.geojson) {
      const { data } = await ApiService.taskMap.getData('fact', this.id);
      this.geojson = { ...data.geojson };
      this.setInputs();

      this.setGeoJson(this.geojson);
      this.gatherDepositionStatistics(this.geojson);
    }
  }

  setInputs() {
    if (this._activeMaterial) {
      const key = this._activeMaterial.key;
      const values = this.geojson.features
        .map((f) => f.properties[key])
        .sort((a, b) => a - b);

      // Построение гистограммы значений внесения (для отладки)
      const histogram = new Map<number, number>();
      this.geojson.features.forEach((f) => {
        const value = f?.properties[key];
        if (typeof value === 'number') {
          histogram.set(value, (histogram.get(value) || 0) + 1);
        }
      });

      this.analyzeApplicationRates(histogram);

      this.values = Array.from(histogram.entries()).sort((a, b) => a[0] - b[0]);

      // Вычисляем границы нормального диапазона (например, 5-й и 95-й процентили)
      const lowerBound = values[Math.ceil(values.length * 0.05)];
      const upperBound = values[Math.floor(values.length * 0.95)];

      // Режим techniqueInput (без изменений)
      this.geojson.features.forEach((feature) => {
        const value: number = feature.properties[key];
        let ratio: number;
        let status: string;

        if (value < lowerBound) {
          // Значение ниже нижней границы – аномально низкое (пропуск)
          ratio = 0;
          status = 'skip';
        } else if (value > upperBound) {
          // Значение выше верхней границы – аномально высокое (сброс)
          ratio = 100;
          status = 'drop';
        } else {
          // Нормальное значение: ratio линейно меняется от 0 до 100
          ratio = ((value - lowerBound) / (upperBound - lowerBound)) * 100;
          status = 'normal';
        }
        feature.properties.techniqueInput = {
          value, // фактическое значение внесения
          ratio, // процентное соотношение от нормального диапазона (0–100)
          status, // 'skip', 'drop' или 'normal'
        };
        feature.properties.material_ratio = ratio;
      });

      // Новый режим: deflectionInput
      const targetKey = this.materials.find(
        (m) => m.id === this._activeMaterial.targetMaterial,
      )?.key;

      if (targetKey) {
        this.geojson.features.forEach((feature) => {
          const value: number = feature.properties[key];
          const targetValue: number = feature.properties[targetKey];

          // Убеждаемся, что оба значения числовые
          if (typeof value === 'number' && typeof targetValue === 'number') {
            const lowerDeflectionBound = 0.7 * targetValue;
            const upperDeflectionBound = 1.3 * targetValue;
            let deflectionRatio: number;
            let deflectionStatus: string;

            if (value < lowerDeflectionBound) {
              // Если значение ниже 30% от targetValue – считаем это пропуском
              deflectionRatio = 0;
              deflectionStatus = 'skip';
            } else if (value > upperDeflectionBound) {
              // Если значение выше 30% от targetValue – считаем это сбросом
              deflectionRatio = 0;
              deflectionStatus = 'drop';
            } else {
              // Вычисляем треугольную зависимость:
              // при value, равном targetValue, ratio = 100,
              // при значениях ровно 30% или 130% от targetValue – ratio = 0,
              // линейно интерполируем между ними.
              if (value <= targetValue) {
                deflectionRatio = ((value - lowerDeflectionBound) / (targetValue - lowerDeflectionBound)) * 100;
              } else {
                deflectionRatio = ((upperDeflectionBound - value) / (upperDeflectionBound - targetValue)) * 100;
              }
              deflectionStatus = 'normal';
            }
            // Если value точно равно targetValue – ratio выставляем в 100
            if (value === targetValue) {
              deflectionRatio = 100;
            }

            feature.properties.deflectionInput = {
              value, // фактическое значение внесения
              targetValue, // значение из targetKey
              ratio: deflectionRatio,
              status: deflectionStatus, // 'skip', 'drop', или 'normal'
            };
          }
        });
      }
    }
  }

  /**
   * Собирает статистику по вносу на основе geojson.
   * Для каждого feature используется площадь (area_ga) и данные внесения (techniqueInput).
   */
  gatherDepositionStatistics(geojson: any): void {
    let totalArea = 0;
    let weightedValueSum = 0;
    let count = 0;
    const statusValues: { [status: string]: { min: number, max: number } } = { skip: { min: Infinity, max: -Infinity }, drop: { min: Infinity, max: -Infinity }, normal: { min: Infinity, max: -Infinity } };
    const statusCounts: { [status: string]: number } = { skip: 0, drop: 0, normal: 0 };
    const areaByStatus: { [status: string]: number } = { skip: 0, drop: 0, normal: 0 };

    // Статистика по группам target. Для каждой группы собираем:
    // - expected: сумма значений из targetKey (ожидаемое значение)
    // - actual: сумма значений из основного ключа (фактическое значение)
    // - area: суммарная площадь объектов
    const targetStats: {
      [targetValue: string]: { expected: number; actual: number; area: number };
    } = {};

    geojson.features.forEach((feature: any) => {
      const area = feature.properties.area_ga;
      const technique = feature.properties.techniqueInput;

      // Статистика по технике (основной режим)
      if (typeof area === 'number' && technique && typeof technique.value === 'number') {
        totalArea += area;
        weightedValueSum += technique.value * area;
        count++;
        const status = technique.status;
        statusCounts[status] = (statusCounts[status] || 0) + 1;
        areaByStatus[status] = (areaByStatus[status] || 0) + area;
        statusValues[status].min = Math.min(technique.value, statusValues[status].min);
        statusValues[status].max = Math.max(technique.value, statusValues[status].max);
      }

      // Если присутствует режим deflectionInput – группируем по targetValue
      if (feature.properties.deflectionInput) {
        const deflection = feature.properties.deflectionInput;
        const targetValue = deflection.targetValue;
        // Группируем по уникальному значению targetValue (приводим его к строке)
        const groupKey = String(targetValue);
        if (!targetStats[groupKey]) {
          targetStats[groupKey] = { expected: 0, actual: 0, area: 0 };
        }
        targetStats[groupKey].expected += targetValue * area;
        targetStats[groupKey].actual += deflection.value * area;
        if (typeof area === 'number') {
          targetStats[groupKey].area += area;
        }
      }
    });

    const avgValuePerArea = totalArea ? weightedValueSum / totalArea : 0;

    this.statistics = {
      totalArea,
      count,
      avgValuePerArea,
      statusValues, // минимальное и максимальное значения внесения в зависимости от статуса
      statusCounts,
      areaByStatus,
      targetStats, // статистика по группам target (если имеются данные)
    };
  }

  setSelected(value: boolean): void {
    if (value) {
      EventBus.$emit(EventsEnum.SetSelectedTaskMap, this);
    }
    super.setSelected(value);
  }

  analyzeApplicationRates(data: Map<number, number>): void {
    if (data.size === 0) {
      throw new Error('Input array cannot be empty');
    }

    // 1. Собираем полное распределение значений
    const valueMap = new Map<number, number>();
    for (const [value, count] of data) {
      valueMap.set(value, (valueMap.get(value) || 0) + count);
    }

    // 2. Определяем нормы
    let norms: number[] = [];

    // Проверяем, есть ли указанные нормы через targetMaterial
    if (this._activeMaterial && this._activeMaterial.targetMaterial) {
      // Получаем ключ материала нормы
      const targetKey = this.materials.find(
        (m) => m.id === this._activeMaterial.targetMaterial,
      )?.key;

      if (targetKey && this.geojson) {
        // Собираем уникальные значения норм из targetKey
        const targetValues = new Set<number>();
        this.geojson.features.forEach((feature) => {
          const targetValue = feature.properties[targetKey];
          if (typeof targetValue === 'number' && !Number.isNaN(targetValue) && targetValue > 0) {
            targetValues.add(targetValue);
          }
        });

        // Преобразуем Set в массив и сортируем
        norms = Array.from(targetValues).sort((a, b) => a - b);
      }
    }

    // Если нормы не были указаны через targetMaterial, находим их через пики гистограммы
    if (norms.length === 0) {
      // Создаем гистограмму с динамическим размером бина
      const binSize = 10; // Размер бина гистограммы
      const histogram = new Map<number, number>();

      for (const [value, count] of valueMap) {
        const bin = Math.floor(value / binSize) * binSize;
        histogram.set(bin, (histogram.get(bin) || 0) + count);
      }

      // Находим значимые пики в гистограмме (нормы)
      const sortedHistogram = [...histogram.entries()].sort((a, b) => a[0] - b[0]);
      const peaks = this.findPeaks(sortedHistogram);
      norms = peaks.slice(0, 4).sort((a, b) => a - b);
    }

    // 3. Определяем диапазоны пропусков и сбросов с учетом норм
    const values = [...valueMap.keys()].sort((a, b) => a - b);

    // Изначальные пороги на основе процентилей
    const initialSkipThreshold = this.findSkipThreshold(values, valueMap);
    const initialDumpThreshold = this.findDumpThreshold(values, valueMap);

    // Корректируем пороги с учетом норм (сдвиг на 15% от ближайшей нормы)
    let skipThreshold = initialSkipThreshold;
    let dumpThreshold = initialDumpThreshold;

    if (norms.length > 0) {
      // Корректируем порог пропусков - он должен быть не выше, чем 85% от минимальной нормы
      const minNorm = norms[0];
      const maxNorm = norms[norms.length - 1];

      // Порог пропусков не должен быть выше 85% от минимальной нормы
      skipThreshold = Math.min(initialSkipThreshold, minNorm * 0.85);

      // Порог сбросов не должен быть ниже 115% от максимальной нормы
      dumpThreshold = Math.max(initialDumpThreshold, maxNorm * 1.15);
    }

    this.ranges = {
      skipRange: [values[0], skipThreshold],
      dumpRange: [dumpThreshold, values[values.length - 1]],
      assumedNorms: norms,
    };

    // 4. Формируем _stops на основе скорректированных диапазонов
    this._stops = [];

    // Создаем цветовую шкалу
    // Красный для пропусков, зеленый для нормы, синий для сбросов
    const positions = [
      { position: 0, color: '#da1f27' },
      { position: 10, color: '#e14f23' },
      { position: 20, color: '#e8801f' },
      { position: 30, color: '#efb11b' },
      { position: 50, color: '#f6e118' },
      { position: 60, color: '#cdd61e' },
      { position: 70, color: '#93b627' },
      { position: 85, color: '#5a9631' },
      { position: 100, color: '#21763b' },
    ];

    // Добавляем диапазон пропусков (если есть)
    if (this.ranges.skipRange[0] < this.ranges.skipRange[1]) {
      this._stops.push({
        from: this.ranges.skipRange[0],
        to: this.ranges.skipRange[1],
        color: this.skipColor,
        norm: 0, // Для пропусков норма равна 0
      });
    }

    const colorScale = chroma.scale(positions.map((c) => c.color)).domain(positions.map((c) => c.position / 100));

    // Добавляем диапазоны на основе assumedNorms
    if (norms.length > 0) {
      // Создаем массив границ диапазонов
      const boundaries: number[] = [];

      // Добавляем нижнюю границу (skipThreshold)
      boundaries.push(skipThreshold);

      // Добавляем промежуточные границы между нормами
      for (let i = 0; i < norms.length - 1; i++) {
        // Граница - середина между соседними нормами
        boundaries.push((norms[i] + norms[i + 1]) / 2);
      }

      // Добавляем верхнюю границу (dumpThreshold)
      boundaries.push(dumpThreshold);

      // Создаем диапазоны на основе границ и норм
      for (let i = 0; i < norms.length; i++) {
        const norm = norms[i];
        const lowerBound = boundaries[i];
        const upperBound = boundaries[i + 1];

        // Пропускаем создание диапазона, если границы совпадают или неправильно упорядочены
        if (lowerBound >= upperBound) continue;

        // Вычисляем позицию в цветовой шкале для нормальных значений
        // Используем градиент от 0.4 до 0.6 для зеленых оттенков
        const colorPosition = 0.4 + (i / Math.max(1, norms.length - 1)) * 0.2;

        this._stops.push({
          from: lowerBound,
          to: upperBound,
          color: colorScale(colorPosition).hex(),
          norm, // Норма - текущее значение
        });
      }
    } else {
      // Если нет assumedNorms, добавляем один диапазон от skipThreshold до dumpThreshold
      // eslint-disable-next-line no-lonely-if
      if (skipThreshold < dumpThreshold) {
        this._stops.push({
          from: skipThreshold,
          to: dumpThreshold,
          color: colorScale(0.5).hex(), // Зеленый для нормальных значений
          norm: (skipThreshold + dumpThreshold) / 2, // Норма - среднее между skipThreshold и dumpThreshold
        });
      }
    }

    // Добавляем диапазон сбросов (если есть)
    if (this.ranges.dumpRange[0] < this.ranges.dumpRange[1]) {
      this._stops.push({
        from: this.ranges.dumpRange[0],
        to: this.ranges.dumpRange[1],
        color: this.dropColor, // Синий для сбросов
        norm: 0, // Для сбросов норма равна 0
      });
    }

    // Если нужно динамически распределить цвета по всем диапазонам
    // Перезаписываем цвета на основе позиции в массиве
    const normalStops = this._stops.filter((stop) => stop.norm !== 0);

    if (normalStops.length > 0) {
      // Находим индексы нормальных диапазонов в общем массиве _stops
      const normalIndices = this._stops.map((stop, idx) => (stop.norm !== 0 ? idx : -1)).filter((idx) => idx !== -1);

      // Применяем градиент только к нормальным диапазонам
      normalStops.forEach((_, idx) => {
        const normalizedIndex = idx / (normalStops.length - 1 || 1);
        const stopIndex = normalIndices[idx];
        this._stops[stopIndex].color = colorScale(normalizedIndex).hex();
      });
    }
  }

  // Алгоритм поиска пиков с учетом минимальной значимости
  findPeaks(sortedHistogram: [number, number][]): number[] {
    const peaks: number[] = [];
    const minPeakHeight = 0.1 * Math.max(...sortedHistogram.map(([, count]) => count));

    for (let i = 1; i < sortedHistogram.length - 1; i++) {
      const [x, y] = sortedHistogram[i];
      const prevY = sortedHistogram[i - 1][1];
      const nextY = sortedHistogram[i + 1][1];

      if (y > prevY && y > nextY && y > minPeakHeight) {
        peaks.push(x + 5); // Центр бина
      }
    }

    return peaks.sort((a, b) => b - a); // Сортировка по убыванию значимости
  }

  // Определение порога пропусков (первые 5% данных)
  findSkipThreshold(sortedValues: number[], valueMap: Map<number, number>): number {
    const total = Array.from(valueMap.values()).reduce((a, b) => a + b, 0);
    let cumulative = 0;

    for (const value of sortedValues) {
      const count = valueMap.get(value) || 0;
      cumulative += count;
      if (cumulative / total > 0.05) {
        return value;
      }
    }
    return sortedValues[0];
  }

  // Определение порога сбросов (последние 5% данных)
  findDumpThreshold(sortedValues: number[], valueMap: Map<number, number>): number {
    const total = Array.from(valueMap.values()).reduce((a, b) => a + b, 0);
    let cumulative = 0;

    for (let i = sortedValues.length - 1; i >= 0; i--) {
      const value = sortedValues[i];
      const count = valueMap.get(value) || 0;
      cumulative += count;
      if (cumulative / total > 0.05) {
        return value;
      }
    }
    return sortedValues[sortedValues.length - 1];
  }

  /**
   * Генерирует features с цветами на основе значений из _stops
   * @param key Ключ свойства, по которому определяется значение для раскраски
   * @returns GeoJSON FeatureCollection с добавленными свойствами цвета
   */
  generateColoredFeatures(key?: string): FeatureCollection {
    if (!this.geojson) return { type: 'FeatureCollection', features: [] };

    const propertyKey = key || (this._activeMaterial?.key);
    if (!propertyKey) return { type: 'FeatureCollection', features: [] };

    // Определяем диапазоны для пропусков, норм и сбросов
    const skipStops = this._stops.filter((stop) => stop.norm === 0 && stop.from === this.ranges.skipRange[0]);
    const normalStops = this._stops.filter((stop) => stop.norm !== 0);
    const dumpStops = this._stops.filter((stop) => stop.norm === 0 && stop.to === this.ranges.dumpRange[1]);

    // Создаем функцию для получения цвета на основе значения
    const getColorForValue = (value: number): string => {
      // Для пропусков - единый цвет
      if (skipStops.length > 0 && value <= skipStops[0].to) {
        return skipStops[0].color;
      }

      // Для сбросов - единый цвет
      if (dumpStops.length > 0 && value >= dumpStops[0].from) {
        return dumpStops[0].color;
      }

      // Для нормальных значений - градиент между нормами
      for (let i = 0; i < normalStops.length; i++) {
        const currentStop = normalStops[i];

        // Если значение точно в диапазоне текущего стопа
        if (value >= currentStop.from && value <= currentStop.to) {
          // Если это первый нормальный стоп и значение между skipThreshold и первой нормой
          if (i === 0 && value < currentStop.norm) {
            // Градиент от границы пропусков до первой нормы
            const ratio = (value - currentStop.from) / (currentStop.norm - currentStop.from);
            return chroma.mix(skipStops.length > 0 ? skipStops[0].color : '#ff4d4d',
              currentStop.color,
              ratio).hex();
          }

          // Если это последний нормальный стоп и значение между последней нормой и границей сбросов
          if (i === normalStops.length - 1 && value > currentStop.norm) {
            // Градиент от последней нормы до границы сбросов
            const ratio = (value - currentStop.norm) / (currentStop.to - currentStop.norm);
            return chroma.mix(currentStop.color,
              dumpStops.length > 0 ? dumpStops[0].color : '#4d4dff',
              ratio).hex();
          }

          // Если значение между текущей нормой и следующей нормой
          if (i < normalStops.length - 1 && value > currentStop.norm) {
            const nextStop = normalStops[i + 1];
            const ratio = (value - currentStop.norm) / (nextStop.norm - currentStop.norm);
            return chroma.mix(currentStop.color, nextStop.color, ratio).hex();
          }

          // Если значение между предыдущей нормой и текущей нормой
          if (i > 0 && value < currentStop.norm) {
            const prevStop = normalStops[i - 1];
            const ratio = (value - prevStop.norm) / (currentStop.norm - prevStop.norm);
            return chroma.mix(prevStop.color, currentStop.color, ratio).hex();
          }

          // Если значение точно равно норме
          if (value === currentStop.norm) {
            return currentStop.color;
          }
        }
      }

      // Если не попали ни в один из диапазонов, возвращаем цвет по умолчанию
      return '#CCCCCC';
    };

    return {
      type: 'FeatureCollection',
      features: this.geojson.features.map((feature) => {
        const value = feature.properties[propertyKey];

        // Если значение не числовое, используем цвет по умолчанию
        if (typeof value !== 'number' || Number.isNaN(value)) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              __color: '#CCCCCC',
              __value: value,
              __range: null,
            },
          };
        }

        const color = getColorForValue(value);
        const range = this._stops.find((stop) => value >= stop.from && value <= stop.to);

        return {
          ...feature,
          properties: {
            ...feature.properties,
            __color: color,
            __value: value,
            __range: range,
          },
        };
      }),
    } as FeatureCollection;
  }
}
