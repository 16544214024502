<template lang="pug" src="./FactMapAnalyzeContent.pug"/>
<style lang="scss" src="./FactMapAnalyzeContent.scss"/>

<script lang="ts">
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import {
  Check, CirclePlus, Delete, Setting,
} from '@element-plus/icons-vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import OrderedList from '@/components/ui/OrderedList/OrderedList.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import Content from '@/components/shared/Content/Content.vue';
import { useRoute } from 'vue-router';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import TaskMapCard from '@/components/shared/TaskMapCard/TaskMapCard.vue';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerTaskMapFactModel } from '@/models/map/Layers/MapLayerTaskMapFactModel';
import FactMapMaterials from '@/pages/FactMapAnalyze/FactMapMaterials.vue';
import UiHistogram from '@/components/ui/Histogram/UiHistogram.vue';
import { jsonToTable } from '@/lib/tools/jsonToTable';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import NoData from '@/components/ui/NoData/NoData.vue';
import VueApexCharts from 'vue3-apexcharts';
import PrintJson from '@/components/features/PrintJson/PrintJson.vue';
import FactMapStatsWidget from '@/modules/taskMap/ui/widgets/FactMapStatsWidget.vue';
import FactMapStopsWidget from '@/modules/taskMap/ui/widgets/FactMapStopsWidget.vue';

export default defineComponent({
  name: 'FactMapAnalyzeContent',
  components: {
    FactMapStopsWidget,
    FactMapStatsWidget,
    PrintJson,
    UiDialog,
    RightPanel,
    Setting,
    CirclePlus,
    Delete,
    Check,
    OrderedList,
    Content,
    NoActiveField,
    TaskMapCard,
    FactMapMaterials,
    UiHistogram,
    VueJsonPretty,
    NoData,
    apexchart: VueApexCharts,
  },
  setup() {
    const {
      mapModel,
      activeField,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const loading = ref(false);

    const showStats = ref(false);

    const route = useRoute();

    const paintMode = ref<'default' | 'deflection'>('default');

    const activeTaskMapLayer = computed<MapLayerTaskMapFactModel | undefined>(() => mapModel.value?.getLayer(MapLayerTypeEnum.TASK_MAP_FACT) as unknown as MapLayerTaskMapFactModel | undefined);

    watch((paintMode), (newMode: 'default' | 'deflection') => {
      if (activeTaskMapLayer.value?.setPaintMode) {
        activeTaskMapLayer.value?.setPaintMode(newMode);
      }
    });

    const hideChartDrops = ref(true);

    const chartValues = computed(() => {
      const key = activeTaskMapLayer.value?.data?.activeMaterial.key;
      if (!key) return [];
      if (!activeTaskMapLayer.value?.data?.geojson?.features) return [];
      const values = new Map<number, number>([]);

      const stats = activeTaskMapLayer.value?.data.statistics;

      activeTaskMapLayer.value?.data?.geojson?.features.forEach((f) => {
        const k = f.properties[key] as number;
        if (!hideChartDrops.value || (k >= activeTaskMapLayer.value.data.stops[0].to && k <= activeTaskMapLayer.value.data.stops[activeTaskMapLayer.value.data.stops.length - 1].from)) {
          if (values.has(k)) {
            values.set(k, values.get(k) + 1);
          } else {
            values.set(k, 1);
          }
        }
      });
      return values;
    });

    const seriesData = computed(() => Array.from(chartValues.value.entries()).map(([x, y]) => ({ x, y })).sort((a, b) => b.x - a.x));

    const series = computed(() => [{
      name: 'Частота',
      data: seriesData.value,
    }]);

    const chartOptions = computed(() => ({
      chart: {
        type: 'area',
        zoom: { enabled: false },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth', // или 'smooth' для сглаживания
        width: 2,
      },
      xaxis: {
        type: 'numeric', // Числовая ось X
        title: { text: 'Норма внесения' },
        labels: {
          formatter: (value) => value.toFixed(0),
        },
      },
      yaxis: {
        type: 'numeric', // Числовая ось Y
        title: { text: 'Частота внесения' },
      },
    }));

    return {
      activeTaskMapLayer,
      loading,
      activeField,
      route,
      paintMode,
      jsonToTable,
      showStats,
      chartOptions,
      series,
      hideChartDrops,
    };
  },
});
</script>
