import type { MapModel } from '@/models/map/MapModel';
import {
  CircleLayerSpecification, GeoJSONSource, LngLat, Map,
} from 'mapbox-gl';
import { Feature, FeatureCollection, Point } from 'geojson';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';

export class MapCursor {
  private static dataTemplate: FeatureCollection = {
    type: 'FeatureCollection',
    features: [{
      id: 0,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0],
      } as Point,
      properties: {},
    } as Feature],
  };

  private _map: Map | undefined;

  constructor(mapModel: MapModel) {
    this._map = mapModel.map;
    mapModel.map.addSource('cursor-source', {
      type: 'geojson',
      data: MapCursor.dataTemplate,
    });
    this.createCursorLayer(mapModel.map);

    mapModel.events.onAnchorCreated(() => {
      console.log('created anchor', mapModel.container);
      mapModel.map.moveLayer('cursor-layer', MapAnchorEnum.CURSOR);
    });

    EventBus.$on(EventsEnum.MapMouseMove, (event: { lngLat: LngLat }) => {
      this._position = event;
      if (this._active) {
        const source = this._map?.getSource('cursor-source') as GeoJSONSource;
        if (source) {
          (MapCursor.dataTemplate.features[0].geometry as Point).coordinates = [this.position.lngLat.lng, this.position.lngLat.lat];
          source.setData(MapCursor.dataTemplate);
        }
      }
    });
  }

  private _active = false;

  get active(): boolean {
    return this._active;
  }

  private _position: { lngLat: LngLat } | undefined = undefined;

  get position(): { lngLat: LngLat } | undefined {
    return this._position;
  }

  setActive(flag: boolean): void {
    if (flag) {
      this._map?.setLayoutProperty('cursor-layer', 'visibility', 'visible');
    } else {
      this._map?.setLayoutProperty('cursor-layer', 'visibility', 'none');
    }
    this._active = flag;
  }

  private createCursorLayer(map: Map): void {
    map.addLayer({
      id: 'cursor-layer',
      type: 'circle',
      source: 'cursor-source',
      layout: {
        visibility: 'none',
      },
      paint: {
        'circle-radius': [
          'interpolate', ['linear'], ['zoom'],
          7, 1,
          11, 2,
          12, 4,
          15, 5,
        ],
        'circle-color': '#006C67',
        'circle-stroke-width': [
          'interpolate', ['linear'], ['zoom'],
          7, 1,
          11, 1,
          12, 2,
          15, 2,
        ],
        'circle-stroke-color': '#FFFFFF',
      },
    } as CircleLayerSpecification);
  }
}
