import { bbox } from '@turf/turf';
import mapboxgl, { GeoJSONFeature, LngLat, PointLike } from 'mapbox-gl';
import {
  Feature, MultiPolygon, Point, Polygon,
} from 'geojson';

export const getByBboxFeatures = (map: mapboxgl.Map, polygon: Feature<Polygon | MultiPolygon >, FilterTypeList: string[] = []): Feature<Polygon | MultiPolygon | Point>[] => {
  const areaBbox = bbox(polygon);

  const a = map.project(new LngLat(areaBbox[0], areaBbox[3]));
  const b = map.project(new LngLat(areaBbox[2], areaBbox[1]));

  const features = map.queryRenderedFeatures([a, b] as [PointLike, PointLike]) as GeoJSONFeature[];

  return features.filter((f) => {
    if (!['Polygon', 'MultiPolygon', 'Point'].includes(f.geometry.type)) {
      return false;
    }
    const metadata = f?.layer?.metadata as any;
    if (metadata?.type === undefined) {
      return false;
    }
    return FilterTypeList.length > 0 ? FilterTypeList.some((l) => metadata?.type === l) : true;
  }) as Feature<Polygon | MultiPolygon | Point>[];
};
