import { BlockNameType } from '@/constants/types/BlockNameType';
import { App, defineAsyncComponent } from 'vue';

class RegisteredComponents {
  public readonly componentsList: Record<BlockNameType, any> = {
    NavigationBlock: defineAsyncComponent(() => import('@/modules/navigation/ui/NavigationBlock.vue')),
    FieldsListBlock: defineAsyncComponent(() => import('@/modules/fields/ui/FieldsListBlock/FieldsListBlock.vue')),
    FieldDrawBlock: defineAsyncComponent(() => import('@/modules/fields/ui/FieldDrawBlock/FieldDrawBlock.vue')),
    FieldEditBlock: defineAsyncComponent(() => import('@/modules/fields/ui/FieldEditBlock/FieldEditBlock.vue')),
    ToolLayersBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolLayersBlock/ToolLayersBlock.vue')),
    ToolRulerBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolRulerBlock/ToolRulerBlock.vue')),
    ToolAreaBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolAreaBlock/ToolAreaBlock.vue')),
    ToolZoomBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolZoomBlock/ToolZoomBlock.vue')),
    ToolMyLocationBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolMyLocationBlock/ToolMyLocationBlock.vue')),
    ToolStructLocationBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolStructLocationBlock/ToolStructLocationBlock.vue')),
    Tool3DMapBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/Tool3DMapBlock/Tool3DMapBlock.vue')),
    AdminListBlock: defineAsyncComponent(() => import('@/pages/AdminListContent/AdminListContent.vue')),
    ToolSearch: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolSearch/ToolSearch.vue')),
    MapListBlock: defineAsyncComponent(() => import('@/modules/admin/ui/MapListBlock/MapListBlock.vue')),
    UserListBlock: defineAsyncComponent(() => import('@/pages/UserListContent/UserListContent.vue')),
    StructListBlock: defineAsyncComponent(() => import('@/pages/StructListContent/StructListContent.vue')),
    MapEditBlock: defineAsyncComponent(() => import('@/modules/admin/ui/MapEditBlock/MapEditBlock.vue')),
    UserEditBlock: defineAsyncComponent(() => import('@/modules/admin/ui/UserEditBlock/UserEditBlock.vue')),
    StructEditBlock: defineAsyncComponent(() => import('@/modules/admin/ui/StructEditBlock/StructEditBlock.vue')),
    POIListBlock: defineAsyncComponent(() => import('@/modules/poi/ui/POIListBlock/POIListBlock.vue')),
    POIGroupEditBlock: defineAsyncComponent(() => import('@/modules/poi/ui/POIGroupEditBlock/POIGroupEditBlock.vue')),
    POIAddBlock: defineAsyncComponent(() => import('@/modules/poi/ui/POIAddBlock/POIAddBlock.vue')),
    AddUserBlock: defineAsyncComponent(() => import('@/modules/admin/ui/AddUserBlock/AddUserBlock.vue')),
    AddStructBlock: defineAsyncComponent(() => import('@/modules/admin/ui/AddStructBlock/AddStructBlock.vue')),
    VectorDataBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/VectorDataBlock/VectorDataBlock.vue')),
    SatelliteLeftBlock: defineAsyncComponent(() => import('@/pages/SatelliteContent/SatelliteContent.vue')),
    SatelliteProgressBlock: defineAsyncComponent(() => import('@/modules/satellites/ui/SatelliteProgressBlock/SatelliteProgressBlock.vue')),
    SatelliteSearchBlock: defineAsyncComponent(() => import('@/modules/satellites/ui/SatelliteSearchBlock/SatelliteSearchBlock.vue')),
    SatelliteEstimationBlock: defineAsyncComponent(() => import('@/modules/satellites/ui/SatelliteEstimationBlock/SatelliteEstimationBlock.vue')),
    LegendColorBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/LegendColorBlock/LegendColorBlock.vue')),
    ToolPaintVectorBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolPaintVectorsBlock/ToolPaintVectorBlock.vue')),
    CustomColorsBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/CustomColorsBlock/CustomColorsBlock.vue')),
    StatusBarBlock: defineAsyncComponent(() => import('@/modules/statusBar/ui/StatusBarBlock/StatusBarBlock.vue')),
    AsfOrderBlock: defineAsyncComponent(() => import('@/pages/AsfOrderContent/AsfOrderContent.vue')),
    AsfOrderConfirmBlock: defineAsyncComponent(() => import('@/pages/AsfOrderConfirmContent/AsfOrderConfirmContent.vue')),
    UserProfileBlock: defineAsyncComponent(() => import('@/modules/user/ui/UserProfileBlock/UserProfileBlock.vue')),
    ToolUnderlaysBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolUnderlaysBlock/ToolUnderlaysBlock.vue')),
    DrawerHelperBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/DrawerHelperBlock/DrawerHelperBlock.vue')),
    ToolAutoIndexBlock: defineAsyncComponent(() => import('@/modules/mapbox/ui/ToolAutoIndexBlock/ToolAutoIndexBlock.vue')),
    MonitoringFieldsBlock: defineAsyncComponent(() => import('@/modules/monitoring/ui/MonitoringFieldsBlock/MonitoringFieldsBlock.vue')),
    AboutProgramBlock: defineAsyncComponent(() => import('@/modules/admin/ui/AboutProgramBlock/AboutProgramBlock.vue')),
    ControlRasterLayersBlock: defineAsyncComponent(() => import('@/modules/admin/ui/ControlRasterLayersBlock/ControlRasterLayersBlock.vue')),
    ScaleControlBlock: defineAsyncComponent(() => import('@/modules/map/container/ScaleControlBlock/ScaleControlBlock.vue')),
    ViewTaskHistoryBlock: defineAsyncComponent(() => import('@/modules/viewTask/ui/ViewTaskHistoryBlock/ViewTaskHistoryBlock.vue')),
    ViewTasksFieldsBlock: defineAsyncComponent(() => import('@/modules/viewTask/ui/ViewTasksFieldsBlock/ViewTasksFieldsBlock.vue')),
    ReceivedTaskFieldsBlock: defineAsyncComponent(() => import('@/modules/viewTask/ui/ReceivedTaskFieldsBlock/ReceivedTaskFieldsBlock.vue')),
    MonitoringChartBlock: defineAsyncComponent(() => import('@/modules/monitoring/ui/MonitoringChartBlock/MonitoringChartBlock.vue')),
    WeatherWidgetBlock: defineAsyncComponent(() => import('@/modules/monitoring/ui/WeatherWidgetBlock/WeatherWidgetBlock.vue')),
    FieldCropRateFieldsBlock: defineAsyncComponent(() => import('@/modules/fields/ui/FieldCropRateFieldsBlock/FieldCropRateFieldsBlock.vue')),
    FieldCropRateManageBlock: defineAsyncComponent(() => import('@/pages/FieldCropRateManageContent/FieldCropRateManageContent.vue')),
    CropRotationModal: defineAsyncComponent(() => import('@/modules/cropRotation/ui/CropRotationModal/CropRotationModal.vue')),
    Integration: defineAsyncComponent(() => import('@/modules/admin/ui/Integration/Integration.vue')),
    StructViewBlock: defineAsyncComponent(() => import('@/modules/struct/ui/StructViewBlock/StructViewBlock.vue')),
    IntegrationModal: defineAsyncComponent(() => import('@/modules/integration/ui/IntegrationModal/IntegrationModal.vue')),
    ReceivedTaskHistoryBlock: defineAsyncComponent(() => import('@/modules/viewTask/ui/ReceivedTaskHistoryBlock/ReceivedTaskHistoryBlock.vue')),
    POITableModal: defineAsyncComponent(() => import('@/modules/poi/ui/POITableModal/POITableModal.vue')),
    ConsoleLoggerBlock: defineAsyncComponent(() => import('@/services/logger/ui/ConsoleLoggerBlock.vue')),
    SelectTaskMapBlock: defineAsyncComponent(() => import('@/pages/task-map/create/TaskMapContent.vue')),
    TaskMapNormaFormBlock: defineAsyncComponent(() => import('@/modules/taskMap/ui/TaskMapNormaFormBlock/TaskMapNormaFormBlock.vue')),
    TaskMapZoneFormBlock: defineAsyncComponent(() => import('@/modules/taskMap/ui/TaskMapZoneFormBlock/TaskMapZoneFormBlock.vue')),
    ApiKeysBlock: defineAsyncComponent(() => import('@/modules/admin/ui/ApiKeysBlock/ApiKeysBlock.vue')),
    RatingNdviBlock: defineAsyncComponent(() => import('@/pages/RatingNdviContent/RatingNdviContent.vue')),
    CropRotationContent: defineAsyncComponent(() => import('@/pages/CropRotationContent/CropRotationContent.vue')),
  }

  public registerComponents(app: App): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(this.componentsList).forEach((blockName: BlockNameType) => {
      app.component(blockName, this.componentsList[blockName]);
    });
  }
}

export default new RegisteredComponents();
