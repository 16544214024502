import { MapLineModel } from '@/models/map/data/MapLineModel';

export class MapRulerModel extends MapLineModel {
  get distance(): number {
    return this._distance;
  }

  set distance(value: number) {
    this._distance = value;
  }

  private _distance: number
}
