import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { LngLat } from 'mapbox-gl';
import { WeatherDataDto } from '@/services/api/dto/weather/WeatherDataDto';

export const endpoints = {
  weatherData: (lat: number, lng: number) => `https://services.truefields.ru/${process.env.VUE_APP_SERVER_LABEL}/openweathermap/data/2.5/weather?lat=${lat}&lon=${lng}&units=metric&lang=ru`,
  getWikiPage: (page: number) => `https://services.truefields.ru/${process.env.VUE_APP_SERVER_LABEL}/wiki/page/${page}`,

};

export class ApiTruefieldsServices extends ApiHandler {
  public async getWeather(coords: LngLat): Promise<ApiResponse<WeatherDataDto>> {
    return await
    this.request({ auth: true, serviceToken: true, ignoreSeason: true }).get<WeatherDataDto>(endpoints.weatherData(coords.lat, coords.lng));
  }

  public async getWikiPage(page: number): Promise<ApiResponse<any>> {
    return await this.request({ auth: true, ignoreSeason: true }).get<any>(endpoints.getWikiPage(page));
  }
}
