import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_POIEditBlock = _resolveComponent("POIEditBlock")!
  const _component_FieldDrawBlock = _resolveComponent("FieldDrawBlock")!
  const _component_FieldEditBlock = _resolveComponent("FieldEditBlock")!
  const _component_SidePanelContent = _resolveComponent("SidePanelContent")!

  return (_openBlock(), _createBlock(_component_SidePanelContent, {
    onClose: _ctx.close,
    title: _ctx.title
  }, {
    default: _withCtx(() => [
      (_ctx.activePoi && _ctx.informationMode === 'poi')
        ? (_openBlock(), _createBlock(_component_POIEditBlock, {
            key: 0,
            poi: _ctx.activePoi
          }, null, 8, ["poi"]))
        : _createCommentVNode("", true),
      (_ctx.activeDrawer)
        ? (_openBlock(), _createBlock(_component_FieldDrawBlock, {
            key: 1,
            open: _ctx.activeDrawer,
            "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeDrawer) = $event)),
            action: _ctx.drawerMode,
            onClose: _ctx.close
          }, null, 8, ["open", "action", "onClose"]))
        : (_ctx.activeField && _ctx.informationMode === 'field')
          ? (_openBlock(), _createBlock(_component_FieldEditBlock, {
              key: 2,
              field: _ctx.activeField
            }, null, 8, ["field"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onClose", "title"]))
}