export const handleFileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String = reader.result as string;
    // Убираем префикс "data:*/*;base64," если нужно
    const cleanBase64 = base64String.split(',')[1];
    resolve(cleanBase64);
  };
  reader.onerror = (error) => reject(error);
});
