import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "SatelliteLeftBlock" }
const _hoisted_2 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_SatelliteEstimationBlock = _resolveComponent("SatelliteEstimationBlock")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_Content = _resolveComponent("Content")

  return (_openBlock(), _createBlock(_component_Content, { title: "Спутниковые снимки" }, {
    footer: _withCtx(() => [
      (_ctx.selectMode !== 2)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            effect: "dark",
            placement: "top",
            "raw-content": true,
            content: _ctx.selectMode !== 1 ? _ctx.$t('downloadRgbBtnTooltip1') : _ctx.$t('downloadRgbBtnTooltip2')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: _ctx.searchMode,
                type: _ctx.selectMode === 1 ? 'primary' : 'default',
                disabled: _ctx.selectMode === 1 && _ctx.selectedFields.length === 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Поиск снимков")
                ]),
                _: 1
              }, 8, ["onClick", "type", "disabled"])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.selectMode !== 1)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            effect: "dark",
            placement: "top",
            "raw-content": true,
            content: _ctx.selectMode !== 1 ? _ctx.$t('calculateIndexesBtnTooltip1') : _ctx.$t('calculateIndexesBtnTooltip2')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                onClick: _ctx.indexesMode,
                type: _ctx.selectMode === 2 ? 'primary' : 'default',
                disabled: _ctx.selectMode === 2 && _ctx.selectedFields.length === 0
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Расчет индексов")
                ]),
                _: 1
              }, 8, ["onClick", "type", "disabled"])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.selectMode !== 0)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 2,
            size: "small",
            onClick: _ctx.unsetMode
          }, {
            default: _withCtx(() => [
              _createTextVNode("Отмена")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.activeField === undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "выберите поле"))
          : (_openBlock(), _createBlock(_component_SatelliteEstimationBlock, { key: 1 }))
      ])
    ]),
    _: 1
  }))
}