import { useApp } from '@/composables/useApp';
import { useAuth } from '@/composables/useAuth';
import BillingService from '@/modules/billing/BillingService';
import FieldsList from '@/modules/fields/FieldsList';
import MonitoringService from '@/modules/monitoring/MonitoringService';
import StructFilesList from '@/modules/struct/StructFilesList';
import StructList from '@/modules/struct/StructList';
import AuthService from '@/services/auth/AuthService';
import StorageService from '@/services/storage/StorageService';
import StorageServiceStruct from '@/services/storage/StorageStructService';
import { useStruct } from '@/composables/useStruct';
import ShapeStandardList from '@/modules/assets/shapeStandard/ShapeStandardList';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import LoggerService from '@/services/logger/LoggerService';
import SeasonList from '@/modules/season/SeasonList';

class CoreLoaderService {
  async fetchData(): Promise<void> {
    useApp().setLoadingScreen('core-loader-service', 'Загрузка данных приложения');
    const structId = useStruct().structId.value;
    if (structId) {
      await AuthService.tryStorageLogin();
      if (useAuth().isAuth()) {
        await LoadingStatus.awaitLoad(LoadingNamesEnum.SERVICE_TOKEN);
        await StructList.fetchStructs();
        await StructList.setActiveStruct(structId);
        await SeasonList.fetch();
        if (StructList.activeStruct.value?.id) {
          try {
            await SeasonList.fetch();
            await Promise.allSettled([
              await StructList.activeStruct.value.fetchUnitDetails(),
              await StructList.activeStruct.value.fetchStatistics(),
              await FieldsList.fetchFields(StructList.activeStruct.value.id),
              await StructFilesList.fetchRasters(),
              await StructFilesList.fetchVectors(),
              await BillingService.fetchStructBilling(),
              await StorageService.fetchStorage(),
              await StorageServiceStruct.fetchStorage(),
              await MonitoringService.fetchKinds(),
              await ShapeStandardList.fetch(),
            ]).catch((e) => {
              LoggerService.error(e);
              useApp().setLoadingScreen('core-loader-service', 'Загрузка данных приложения');
            });
          } catch (e) {
            LoggerService.error(e);
            useApp().setLoadingScreen('core-loader-service', 'Загрузка данных приложения');
          }
        }
      }
    }
    useApp().setLoadingScreen('core-loader-service');
  }
}

export default new CoreLoaderService();
