import { PolygonModel } from '@/models/geojson/PolygonModel';
import { Model } from '@/models/Model';
import {
  Feature, FeatureCollection, MultiPolygon, Polygon, Position,
} from 'geojson';
import {
  featureCollection, union, difference, intersect, polygon,
} from '@turf/turf';
import EventBus from '@/services/eventBus/EventBus';

export class MultiPolygonModel extends Model {
  get activePolygon(): PolygonModel | null {
    if (this._activePolygon && this._data.length >= this._activePolygon) {
      return this._data[this._activePolygon];
    }
    return null;
  }

  get selectedPolygons(): PolygonModel[] {
    return this._data.filter((v, idx) => this._selectedPolygons.includes(idx));
  }

  get data(): PolygonModel[] {
    return this._data;
  }

  /**
   * Получить полигон по индексу в массиве мультиполигона, либо по id полигона
   * @param index
   */
  getPolygon(index: number): PolygonModel | undefined {
    if (index > 1000000) {
      return this._data.find((v) => v.id === index);
    }
    return this._data[index];
  }

  selectPolygon(index: number): void {
    if (this._data.length > index && !this._selectedPolygons.includes(index)) {
      this._selectedPolygons.push(index);
    }
  }

  toggleSelectPolygon(index: number): void {
    if (this._data.length > index) {
      if (!this._selectedPolygons.includes(index)) {
        this._selectedPolygons.push(index);
      } else {
        this._selectedPolygons.splice(this._selectedPolygons.indexOf(index), 1);
      }
    }
  }

  unselectPolygon(index: number): void {
    if (this._selectedPolygons.includes(index)) {
      this._selectedPolygons.splice(this._selectedPolygons.indexOf(index), 1);
    }
  }

  activatePolygon(index: number): void {
    if (this._data.length > index) {
      this._activePolygon = index;
    }
  }

  toggleActivePolygon(index: number): void {
    if (this._activePolygon === index) {
      this._activePolygon = null;
    } else {
      this._activePolygon = index;
    }
  }

  deactivatePolygon(): void {
    this._activePolygon = null;
  }

  private _data: PolygonModel[] = [];

  private _activePolygon: number | null = null;

  private _selectedPolygons: number[] = [];

  static counter = 1000000;

  private readonly _id: number;

  constructor(coordinates: Position[][][]) {
    super();
    this._data = coordinates.map((p) => new PolygonModel(p));
    this._id = MultiPolygonModel.counter++;
  }

  toFeature(): Feature<MultiPolygon> {
    return {
      type: 'Feature',
      properties: {
        id: this._id,
      },
      id: this._id,
      geometry: {
        type: 'MultiPolygon',
        coordinates: this._data.map((v) => v.data.map((p) => p.data.map((k) => k.data))),
      },
    };
  }

  toFeatureCollection(): FeatureCollection<MultiPolygon> {
    this._data.map((v) => polygon(v.data.map((p) => p.data.map((k) => k.data))));
    return featureCollection();
  }

  /**
   * Удалить полигон по индексу в массиве мультиполигона, либо по id полигона
   * @param index
   */
  deletePolygon(index: number): void{
    if (index > 1000000) {
      this._data = this._data.filter((v) => v.id !== index);
      return;
    }
    this._data.splice(index, 1);
  }

  addPolygon(coordinates: Position[][]): void {
    this._data.push(new PolygonModel(coordinates));
  }

  updatePolygons(coordinates: Position[][][]): void {
    this._data = coordinates.map((a) => new PolygonModel(a));
  }

  union(indexes: number[]) {
    const result = union(featureCollection(this._data.filter((_, i) => indexes.includes(i)).map((a) => a.toFeature()))) as Feature<Polygon | MultiPolygon>;

    this._data = this._data.filter((_, i) => !indexes.includes(i));
    if (result.geometry.type === 'Polygon') {
      this._data.push(new PolygonModel(result.geometry.coordinates));
    } else if (result.geometry.type === 'MultiPolygon') {
      this._data = result.geometry.coordinates.map((a) => new PolygonModel(a));
    }
  }

  deleteDifference = () => {
    const _f = this._data.map((a) => a.toFeature());
    const buffer: Feature<Polygon | MultiPolygon>[] = _f.map((a) => a);
    _f.forEach((g1, idx1) => {
      _f.forEach((g2, idx2) => {
        if (idx2 > idx1) {
          const geometry = featureCollection([buffer[idx1], g2]);
          if (intersect(geometry)) {
            const _difference = difference(geometry);

            buffer[idx1] = (_difference);
          }
        }
      });
    });
    const updetedCords: Position[][][] = buffer.flatMap((a) => (a.geometry.type !== 'Polygon' ? a.geometry.coordinates : [a.geometry.coordinates]));
    this.updatePolygons(updetedCords as Position[][][]);
  }
}
