<template lang="pug" src="./FactMapUploadDialog.pug" />

<style lang="scss" src="./FactMapUploadDialog.scss" />

<script  lang="ts">

import {
  computed, defineComponent, ref, watch,
} from 'vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { useStruct } from '@/composables/useStruct';
import {
  genFileId, UploadInstance, UploadProps, UploadRawFile, UploadUserFile,
} from 'element-plus';
import { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import ApiService from '@/services/api/ApiService';
import SeasonList from '@/modules/season/SeasonList';
import { v4 as uuid } from 'uuid';
import { TaskMapMaterialTypeEnum } from '@/constants/enums/TaskMapMaterialTypeEnum';

export default defineComponent({
  name: 'FactMapUploadDialog',
  components: {
    UiDialog,

  },
  emits: ['close'],
  setup(props, { emit }) {
    const isOpenAddDialog = ref(true);
    const showDialogAttributes = ref(false);
    const { structId } = useStruct();
    const fileList = ref<UploadUserFile[]>([]);
    const upload = ref<UploadInstance>();
    const attributes = ref<{
      'name': string,
      'type': string,
    }[]>([]);
    const selectedAttributes = ref <{
      column: string,
      'is_target': boolean,
      norma: 0,
      name: string,
      'target_column': string,
      'is_active': boolean,
      unit: string,
      type: TaskMapMaterialTypeEnum,
      'modify_value': {
        add: 0,
        mul: 0
      }[],
    }[]>([{
      column: '',
      norma: 0,
      unit: 'кг',
      type: TaskMapMaterialTypeEnum.SEED,
      name: 'keyTest',
      is_target: false,
      is_active: true,
      target_column: '',
      modify_value: [],
    }]);
    const featureCollection = ref<FeatureCollection<Polygon | MultiPolygon>>();
    const blockUploadFile = computed(() => fileList.value?.length === 0);
    const uploadIdentifier: string = uuid();

    watch([isOpenAddDialog, showDialogAttributes], (a) => {
      if (!a[1] && !a[1]) {
        emit('close');
      }
    });

    const save = async () => {
      const formData = new FormData();

      formData.append('file', fileList.value[0].raw);
      formData.append('identifier', uploadIdentifier);
      await ApiService.struct.postUpload(formData);
      const response = await ApiService.struct.getUploadParams(uploadIdentifier);
      attributes.value = response.data.attributes;
      showDialogAttributes.value = true;
      isOpenAddDialog.value = false;
    };

    const saveUpload = async () => {
      const data = {
        file: fileList.value[0].raw,
        rules: {},
        config: {
          materials: selectedAttributes.value,
        },
        upload_identifier: uploadIdentifier,
        season_year: SeasonList.activeSeason.value.id,
      };

      const formData = new FormData();

      formData.append('file', fileList.value[0].raw);
      formData.append('rules', '{}');
      formData.append('config', JSON.stringify({ materials: data.config.materials }));
      formData.append('upload_identifier', uploadIdentifier);
      formData.append('season_year', JSON.stringify(data.season_year));

      await ApiService.taskMap.postUploadFactMap(formData);
      emit('close');
    };

    const handleExceed: UploadProps['onExceed'] = (files) => {
      upload.value!.clearFiles();
      const file = files[0] as UploadRawFile;
      file.uid = genFileId();
      upload.value!.handleStart(file);
    };

    const usageAttrs = computed(() => (selectedAttributes.value.flatMap((v) => Object.values(v)).filter((a) => typeof a === 'string' && a.length > 0)));

    const checkUsageAttrs = computed(() => (att: string) => usageAttrs.value.some((v) => v === att));

    const deleteMaterial = (index: number) => {
      selectedAttributes.value.splice(index, 1);
    };

    const addMaterial = () => {
      selectedAttributes.value.push({
        column: '',
        norma: 0,
        unit: 'кг',
        type: TaskMapMaterialTypeEnum.SEED,
        name: '',
        is_target: false,
        is_active: false,
        target_column: '',
        modify_value: [],
      });
    };

    return {
      isOpenAddDialog,
      showDialogAttributes,
      featureCollection,
      fileList,
      upload,
      handleExceed,
      save,
      blockUploadFile,
      selectedAttributes,
      attributes,
      checkUsageAttrs,
      usageAttrs,
      saveUpload,
      deleteMaterial,
      TaskMapMaterialTypeEnum,
      addMaterial,
    };
  },
});
</script>
