<script  lang="ts">
import { VectorColors } from '@/assets/data/VectorColors';
import { ExperimentsCollection } from '@/collection/ExperimentsCollection';
import Content from '@/components/shared/Content/Content.vue';
import Hotkey from '@/components/shared/Hotkey/Hotkey.vue';
import EmptyList from '@/components/ui/EmptyList/EmptyList.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import ProgressCard from '@/components/ui/ProgressCard/ProgressCard.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUnload } from '@/composables/useUnload';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { ExperimentStorageType } from '@/constants/types/ExperimentStorageType';
import hexToRgba from '@/lib/convertors/hexToRgba';
import round from '@/lib/tools/round';
import { GradientModel } from '@/models/assets/GradientModel';
import { PaletteModel } from '@/models/assets/PaletteModel';
import { ExperimentModel, ExperimentModelJsonType } from '@/models/ExperimentModel';
import { FieldModel } from '@/models/field/FieldModel';
import { MapLayerExperimentsModel } from '@/models/map/Layers/MapLayerExperimentsModel';
import PermissionsList from '@/modules/permissions/PermissionsList';
import { useExperiments } from '@/pages/task-map/create/experiments/composables/useExperiments';
import ExperimentAddForm
  from '@/pages/task-map/create/experiments/ui/ExperimentsBlock/ExperimentAddForm.vue';
import ExperimentCard
  from '@/pages/task-map/create/experiments/ui/ExperimentsBlock/ExperimentCard.vue';
import ExperimentSelected
  from '@/pages/task-map/create/experiments/ui/ExperimentsBlock/ExperimentSelected.vue';
import ExperimentSelectTaskMap
  from '@/pages/task-map/create/experiments/ui/ExperimentsBlock/ExperimentSelectTaskMap.vue';
import ApiService from '@/services/api/ApiService';
import AssetsGradients from '@/services/assets/AssetsGradients';
import EventBus from '@/services/eventBus/EventBus';
import LoadingStatus from '@/services/loading/LoadingStatus';
import {
  area, center, featureCollection, intersect,
} from '@turf/turf';
import { ElMessage } from 'element-plus';
import { Feature, GeoJsonProperties, Polygon } from 'geojson';
import { LngLat, Point } from 'mapbox-gl';
import {
  computed, defineComponent, onMounted, reactive, ref, watch, watchEffect,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'ExperimentContent',
  components: {
    NoActiveField,
    UiIcon,
    Content,
    ExperimentSelectTaskMap,
    Hotkey,
    EmptyList,
    ExperimentCard,
    ExperimentSelected,
    ExperimentAddForm,
    ProgressCard,
  },
  setup(props, { emit }) {
    const {
      refreshMap,
      activeExperiment,
      setActiveExperiment,
      selectedExperiments,
      toggleSelectedExperiment,
      clearSelectedExperiments,
      removeExperiment,
      experiments,
      addNewExperiment,
      clearExperiments,
      calculateTitle,
      setMap,
      refreshContour,
    } = useExperiments();
    const {
      activeField,
      setActiveField,
      mapModel,
      isFieldFocus,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const route = useRoute();

    const id = ref(0);

    const isExperimentAddActive = ref(false);

    const gradients = ref<GradientModel[]>([]);
    const ExperimentSelectRef = ref();

    const activeGradient = ref<GradientModel | undefined>();
    const experimentLayer = ref<MapLayerExperimentsModel>();
    const intersectExperimentUuid = ref<string>();
    const buffer = reactive<{
      points: Point[],
      feature: Feature<Polygon, GeoJsonProperties>,
      title: string,
      color: string,
      proc: number,
      procSeed: number,
    }[]>([] as ExperimentModel[]);
    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      const unit = useStruct().structId.value;
      AssetsGradients.fetch();
      LoadingStatus.awaitLoad(LoadingNamesEnum.ASSETS_GRADIENTS_LIST, unit).then(() => {
        gradients.value = AssetsGradients.data;
      });
    });

    const baseTaskMap = computed(() => ExperimentSelectRef.value?.baseTaskMap);

    const activateExperimentAdd = () => {
      isExperimentAddActive.value = true;
      setActiveExperiment();
      clearSelectedExperiments();
    };
    const activePalette = ref<PaletteModel | undefined>();

    const defaultActivePalette = () => {
      gradients.value.forEach((p) => {
        if (p.name === 'ASF') {
          activeGradient.value = p as GradientModel;
        }
      });
      if (!activeGradient.value && gradients.value.length > 0) {
        activeGradient.value = gradients.value[0] as GradientModel;
      }
    };
    defaultActivePalette();

    watch(() => gradients, defaultActivePalette, { deep: true });

    const intersectData = ref<{uuidExp: string, data: { zone: string, areaHa: number, }[]}[]>([]);

    const computedDrag = computed(() => experiments.some((a) => a.dragging !== null));
    const coumputedExperiment = computed(() => experiments.find((a) => a.uuid === intersectExperimentUuid.value));

    const calculateIntersect = (exp: ExperimentModel) => {
      const intersectList: { zone: string, areaHa: number }[] = [];
      const experimentPolygon = exp.feature;

      if (baseTaskMap.value?.geojson) {
        baseTaskMap.value.geojson.features.forEach((polygon2) => {
          const findZone = intersectList.find((s) => s.zone === polygon2.properties?.zone);
          const inter = intersect(featureCollection([experimentPolygon, polygon2 as Feature<Polygon>]));
          if (inter && findZone === undefined) {
            intersectList.push({ zone: polygon2.properties?.zone as string, areaHa: area(inter) / 10000 });
          } else if (inter && findZone) {
            findZone.areaHa += area(inter) / 10000;
          }
        });
      }

      return intersectList;
    };

    let time1: ReturnType<typeof setTimeout>;

    // Отслеживаем изменения в эксперименте
    watchEffect(() => {
      if (intersectExperimentUuid.value && !computedDrag.value && coumputedExperiment.value.feature) {
        clearTimeout(time1);
        // Устанавливаем таймаут перед вычислением
        time1 = setTimeout(() => {
          const expActive = experiments.find((a) => a.uuid === intersectExperimentUuid.value);// Текущий эксперимент
          const element = intersectData.value.find((a) => a.uuidExp === expActive?.uuid);
          if (element) {
            element.data = calculateIntersect(expActive as ExperimentModel);
          } else {
            intersectData.value.push(
              {
                uuidExp: intersectExperimentUuid.value as string,
                data: calculateIntersect(expActive as ExperimentModel),
              },
            );
          }
        }, 200);
      }
    });

    /** Вызывается при клике кнопки Добавить в форме добавления эксперемента */
    const addCreatedExperiment = (experiment: ExperimentModel) => {
      experiment.isCreating = false;
      experiment.isSticky = false;
      experiment.id = id.value++;
      experiment.opacity = 1;
      isExperimentAddActive.value = false;
      refreshMap();
    };

    let counter = 100;

    const interval = setInterval(() => {
      if (mapModel.value) {
        experimentLayer.value = mapModel.value.render(new ExperimentsCollection(experiments as ExperimentModel[])) as MapLayerExperimentsModel;
        clearInterval(interval);
      }
      if (counter-- === 0) {
        clearInterval(interval);
      }
    }, 50);

    onMounted(() => {
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP).then(() => {
        setMap(mapModel.value);
      });
    });

    const saveExperiment = () => {
      if (baseTaskMap.value?.id) {
        const data: {
          // eslint-disable-next-line camelcase
          exp_name: string,
          color: string,
          zone: string,
          proc: number,
          // eslint-disable-next-line camelcase
          proc_seed: number,
          geom: Polygon
        }[] = experiments.map((exp) => ({
          exp_name: exp.title,
          color: exp.color,
          zone: '0',
          proc: exp.proc,
          proc_seed: exp.procSeed,
          geom: {
            type: 'Polygon',
            coordinates: exp.feature.geometry.coordinates,
          },
        }));
        ApiService.taskMap.createFeatureExperiment(baseTaskMap.value.id, data).then(() => {
          ElMessage({
            message: 'Карта с опытами создана',
            type: 'success',
          });
          activeField.value?.fetchTaskMaps(true);
        });
      }
    };

    /** Проверка на наличие выбранного поля */
    const hasSelected = computed(() => experiments.some((exp) => exp.selected));

    const hasSticky = computed(() => experiments.some((exp) => exp.isSticky));

    const clickExperiment = () => {
      refreshContour();
    };
    // endregion

    const handlerAddExperiment = (points: number[][]) => {
      const exp = new ExperimentModel(id.value++, activeField.value as FieldModel);
      exp.title = calculateTitle();
      exp.isCreating = false;
      exp.isSticky = false;
      exp.points = points.map((coords) => new Point(coords[0], coords[1]));
      addNewExperiment(exp);
      setActiveExperiment(exp.uuid);
    };

    const handlerClickOnExperiment = (_experiments: ExperimentModel[]) => {
      if (!isExperimentAddActive.value && _experiments.length > 0) {
        // setActiveExperiment(_experiments[0].uuid);
      }
    };

    const keyEventHandler = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        clearSelectedExperiments();
      }
      if (event.code === 'KeyC' && event.ctrlKey) {
        if (!hasSelected.value) {
          ElMessage({
            message: 'Нет выбранных опытов для копирования.',
            type: 'warning',
          });
        } else {
          buffer.splice(0, buffer.length);

          experiments.filter((v) => v.selected).forEach((v) => {
            buffer.push({
              points: [...v.points],
              feature: v.feature,
              title: v.title,
              color: v.color,
              proc: v.proc,
              procSeed: v.procSeed,
            });
          });
          ElMessage({
            message: 'Выбранные опыты добавлены в буфер обмена.',
            type: 'success',
          });
        }
      }
      if (mapModel.value?.cursor.position && event.code === 'KeyV' && event.ctrlKey) {
        if (buffer.length === 0) {
          ElMessage({
            message: 'Буффер обмена пуст.',
            type: 'warning',
          });
        } else {
          experiments.forEach((v) => {
            v.selected = false;
          });
          const cp = center(featureCollection(buffer.map((v) => v?.feature).filter((v) => !!v)));
          const pos = mapModel.value?.cursor.position.lngLat as LngLat;
          const offset = new LngLat(pos.lng - cp.geometry.coordinates[0], pos.lat - cp.geometry.coordinates[1]);
          buffer.forEach((v) => {
            const exp = new ExperimentModel(id.value++, activeField.value as FieldModel);
            exp.title = v.title;
            exp.color = v.color;
            exp.proc = v.proc;
            exp.procSeed = v.procSeed;
            exp.selected = true;
            exp.isCreating = false;
            exp.isSticky = false;
            exp.points = v.points.map((coords) => new Point(coords.x + offset.lng, coords.y + offset.lat));
            addNewExperiment(exp);
          });

          ElMessage({
            message: 'Выбранные опыты добавлены в буфер обмена.',
            type: 'success',
          });
        }
      }
    };
    const hasIntersection = ref(false);

    const calculateHasIntersection = () => experiments.some((v1) => experiments.filter((v3) => v3.uuid !== v1.uuid).some((v2) => {
      const intersection = intersect(v1.feature, v2.feature);
      if (intersection && intersection?.geometry?.type === 'Polygon') {
        return area(intersection) > 1;
      }
      return false;
    }));
    const hasDuplicateNames = computed(() => Array.from(new Set(experiments.map((v) => v.title))).length !== experiments.length);

    const deleteExperiment = (uuid: string) => {
      removeExperiment(uuid);
      isExperimentAddActive.value = false;
    };

    let time: ReturnType<typeof setTimeout>;
    watch(experiments, (a) => {
      clearTimeout(time);
      time = setTimeout(() => {
        hasIntersection.value = calculateHasIntersection();
        const storageArray = (JSON.parse(localStorage.getItem('experiments') || '[]') as ExperimentStorageType[]).filter((v) => v.taskMapId !== baseTaskMap.value?.id);
        localStorage.setItem('experiments', JSON.stringify([
          ...storageArray,
          {
            fieldId: baseTaskMap.value?.field,
            taskMapId: baseTaskMap.value?.id,
            data: experiments.filter((v) => !v.isCreating).map((v) => v.toJSON()),
          } as ExperimentStorageType,
        ]));
      }, 500);
    });

    watch(baseTaskMap, (v) => {
      experiments.splice(0, experiments.length);
      if (experiments.length === 0) {
        const storageData = JSON.parse(localStorage.getItem('experiments') || '[]');
        if (Array.isArray(storageData)) {
          (storageData as { fieldId: number, taskMapId: number, data: ExperimentModelJsonType[]}[])
            .find((row) => row.fieldId === activeField.value?.id && row.taskMapId === baseTaskMap.value?.id)
            ?.data.forEach((json) => {
              const _exp = new ExperimentModel(id.value++, activeField.value as FieldModel);
              _exp.fromJSON(json);
              experiments.push(_exp);
            });
          refreshMap();
        }
      }
      isFieldFocus.value = !!v;
    });

    const doRemoveExperiment = (experiment: ExperimentModel) => {
      removeExperiment(experiment.uuid);
      isExperimentAddActive.value = false;
    };

    const setActiveGradient = (gradient: GradientModel) => {
      activeGradient.value = gradient;
    };

    const selectIntersect = (uuid: string) => {
      if (intersectExperimentUuid.value === uuid) {
        intersectExperimentUuid.value = undefined;
      } else {
        intersectExperimentUuid.value = uuid;
      }
    };

    const getColorZone = (zone: string) => {
      const colors = VectorColors.find((palette) => palette.name === 'zone');
      const color = colors?.palette.find((item) => item.value === zone)?.color;
      if (color) {
        return hexToRgba(color);
      }
      return [0, 0, 0, 0];
    };

    watch(activeGradient, refreshMap);

    onMounted(() => {
      window.addEventListener('keydown', keyEventHandler);
    });

    EventBus.$on('AddExperimentByCoords', handlerAddExperiment);

    EventBus.$on('CLICK_ON_EXPERIMENTS', handlerClickOnExperiment);

    useUnload(() => {
      isFieldFocus.value = false;
      window.removeEventListener('keydown', keyEventHandler);
      setActiveField();
      EventBus.$off('AddExperimentByCoords', handlerAddExperiment);
      EventBus.$off('CLICK_ON_EXPERIMENTS', handlerClickOnExperiment);
      mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_EXPERIMENT);
      mapModel.value?.removeLayer(MapLayerTypeEnum.TASK_MAP_BASE);
    });

    return {
      PermissionsList,
      activeField,
      setActiveField,
      mapModel,
      refreshMap,
      activeExperiment,
      setActiveExperiment,
      selectedExperiments,
      toggleSelectedExperiment,
      clearSelectedExperiments,
      removeExperiment,
      experiments,
      addNewExperiment,
      clearExperiments,
      calculateTitle,
      activateExperimentAdd,
      isExperimentAddActive,
      baseTaskMap,
      addCreatedExperiment,
      saveExperiment,
      clickExperiment,
      doRemoveExperiment,
      setActiveGradient,
      hasSelected,
      deleteExperiment,
      getColorZone,
      hasIntersection,
      hasDuplicateNames,
      intersectData,
      area,
      round,
      intersectExperimentUuid,
      selectIntersect,
      activeGradient,
      gradients,
      route,
      activePalette,
      hasSticky,
      ExperimentSelectRef,
    };
  },
});
</script>

<template lang="pug" src="./ExperimentContent.pug"/>

<style  lang="scss" src="./ExperimentContent.scss"/>
