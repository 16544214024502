import { ActiveModeFeatureExperimentEnum } from '@/constants/enums/ActiveModeFeatureExperimentEnum';
import { Model } from '@/models/Model';
import { Point } from 'mapbox-gl';
import { FieldModel } from '@/models/field/FieldModel';
import { destination, point } from '@turf/turf';
import {
  Feature, Point as TurfPoint, Polygon, Position,
} from 'geojson';
import { invertColor } from '@/lib/map/invertColor';

export type ExperimentModelJsonType = {

  title: string,
  color: string,
  turn: number,
  height: number,
  width: number,
  proc: number,
  procSeed: number,
  center: [number, number],
  isCreating: boolean,
  isSticky: boolean,
  points: [number, number][],
  opacity: number,
}

export class ExperimentModel extends Model {
  /** Флаг признака создаваемого експеримента. */
  public isCreating = true;

  /** Флаг актуален только для создаваемого експеримента. True - координата центра эксперимента будет привязана к курсору. */
  public isSticky = true;

  public isHovered = false;

  public dragging: Point[] | null = null;

  public points: Point[] = [];

  public opacity = 1;

  public selected = false;

  public active = false;

  private _labelColor = invertColor('#FBFC02', true);

  constructor(id: number, field: FieldModel) {
    super();
    this._id = id;
    this._center = new Point(field.bounds.getCenter().lng, field.bounds.getCenter().lat);
  }

  private _title = '';

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  private _id = 0;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  private _color= '#FBFC02';

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
    this._invertColor = invertColor(value);
    this._labelColor = invertColor(value, true);
  }

  private _invertColor = invertColor(this._color);

  private _mode: ActiveModeFeatureExperimentEnum = ActiveModeFeatureExperimentEnum.ADD;

  get mode(): ActiveModeFeatureExperimentEnum {
    return this._mode;
  }

  set mode(value: ActiveModeFeatureExperimentEnum) {
    this._mode = value;
  }

  private _turn = 0;

  get turn(): number {
    return this._turn;
  }

  set turn(value: number) {
    this._turn = value;
  }

  private _height = 80;

  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value;
  }

  private _width = 80;

  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  private _proc = 100;

  get proc(): number {
    return this._proc;
  }

  set proc(value: number) {
    this._proc = value;
  }

  private _procSeed = 100;

  get procSeed(): number {
    return this._procSeed;
  }

  set procSeed(value: number) {
    this._procSeed = value;
  }

  private _center: Point;

  get center(): Point {
    return this._center;
  }

  set center(value: Point) {
    this._center = value;
  }

  get feature(): Feature<Polygon> {
    if (this.isCreating) {
      const distance = Math.sqrt((this.width / 2) ** 2 + (this.height / 2) ** 2);
      const angle = Math.atan(this.width / this.height) * (180 / Math.PI);

      const setPoint = (i: number, p: Feature<TurfPoint>) => {
        if (this.points[i]) {
          this.points[i].x = p.geometry.coordinates[0];
          this.points[i].y = p.geometry.coordinates[1];
        } else {
          this.points[i] = new Point(p.geometry.coordinates[0], p.geometry.coordinates[1]);
        }
      };

      setPoint(0, destination(point([this.center.x, this.center.y]), distance / 1000, angle + this.turn));
      setPoint(1, destination(point([this.center.x, this.center.y]), distance / 1000, 180 - angle + this.turn));
      setPoint(2, destination(point([this.center.x, this.center.y]), distance / 1000, 180 + angle + this.turn));
      setPoint(3, destination(point([this.center.x, this.center.y]), distance / 1000, 360 - angle + this.turn));
    }

    const _positions: Position[] = this.points.map((p) => [p.x, p.y] as Position);
    _positions.push(_positions[0]);

    return {
      id: this.id,
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [_positions],
      },
      properties: {
        uuid: this.uuid,
        color: this.color,
        invertColor: this._invertColor,
        labelColor: this._labelColor,
        opacity: this.opacity,
        hover: this.isHovered,
        selected: this.selected,
      },
    };
  }

  fromJSON(data: ExperimentModelJsonType) {
    this.title = data.title;
    this.color = data.color;
    this.turn = data.turn;
    this.height = data.height;
    this.width = data.width;
    this.proc = data.proc;
    this.procSeed = data.procSeed;
    this.center = new Point(data.center[0], data.center[1]);
    this.isCreating = data.isCreating;
    this.isSticky = data.isSticky;
    this.points = data.points.map((v) => new Point(v[0], v[1]));
    this.opacity = data.opacity;
  }

  toJSON(): ExperimentModelJsonType {
    return {
      title: this.title,
      color: this.color,
      turn: this.turn,
      height: this.height,
      width: this.width,
      proc: this.proc,
      procSeed: this.procSeed,
      center: [this.center.x, this.center.y],
      isCreating: this.isCreating,
      isSticky: this.isSticky,
      points: this.points.map((p) => [p.x, p.y]),
      opacity: this.opacity,
    };
  }
}
