<template src="./FieldCropRateManageContent.pug" lang="pug"/>
<style src="./FieldCropRateManageContent.scss" lang="scss"/>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import {
  Check, CirclePlus, Delete, Setting,
} from '@element-plus/icons-vue';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import OrderedList from '@/components/ui/OrderedList/OrderedList.vue';
import { useMapLayout } from '@/composables/useMapLayout';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import DictionaryList from '@/modules/dictionary/DictionaryList';
import Content from '@/components/shared/Content/Content.vue';
import CropList from '@/modules/crop/CropList';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import LoadingStatus from '@/services/loading/LoadingStatus';
import FieldsList from '@/modules/fields/FieldsList';
import PublicCropList from '@/modules/crop/PublicCropList';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import { useApp } from '@/composables/useApp';
import { CropRateColors } from '@/assets/data/CropRateColors';
import { CropModel } from '@/models/crop/CropModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { useUnload } from '@/composables/useUnload';

export default defineComponent({
  name: 'FieldCropRateManageContent',
  components: {
    RightPanel,
    Setting,
    CirclePlus,
    Delete,
    Check,
    OrderedList,
    Content,
  },
  setup() {
    const {
      mapModel,
      selectedFields,
      cleanSelectedFields,
      fields,
      setSelectState,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { showCropManage } = useMapLayout();
    const fieldsLayer = ref<MapLayerFieldsModel>();
    const manageMode = ref(false);

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      CropList.fetchCrops();
      FieldsList.fetchCropRates();
      PublicCropList.fetchPublicCrop();
      FieldsEvents.emitChangeCropRate();
      fieldsLayer.value = (mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel);
      fieldsLayer.value?.setOpacity(100);

      fields.value.forEach((field) => {
        if (field.feature.properties) field.feature.properties.__cropRateColor = '#ffffff';
      });
      fieldsLayer.value.selectedPaint = 'crop-rotation';
      mapModel.value?.events.emitFieldUpdated();
      mapModel.value?.events.emitFieldPaint();
    });

    watch(manageMode, (v) => {
      if (v) {
        useMapLayout().showBlock('FieldCropRateFieldsBlock');
      } else {
        useMapLayout().hideBlock('FieldCropRateFieldsBlock');
      }
    });

    watch(CropList.selected, () => {
      // FieldsEvents.emitChangeCropRate();
    });

    useUnload(() => {
      CropList.selected.value = undefined;
      setSelectState('single');
      if (manageMode.value) {
        useMapLayout().hideBlock('FieldCropRateFieldsBlock');
      }

      selectedFields.value.forEach((a) => {
        if (a.feature.properties) delete a.feature.properties.__cropRateColor;
      });
      if (fieldsLayer.value) {
        fieldsLayer.value.selectedPaint = 'none';
        mapModel.value?.events.emitFieldPaint();
      }
      fieldsLayer.value?.setOpacity(0);
    });

    const selectCrop = (crop: CropModel) => {
      CropList.toggleSelected(crop);

      if (fieldsLayer.value) {
        const _selectCrop: CropModel | undefined = CropList.selected.value;

        fields.value.forEach((field) => {
          if (field.feature.properties) field.feature.properties.__cropRateColor = '#ffffff';
          const cropRate = field.cropRates.find((f) => f.cropItem === _selectCrop?.id && f.rate);
          if (cropRate && field.feature.properties) {
            field.feature.properties.__cropRateColor = cropRate.color;
          }
        });
        fieldsLayer.value.selectedPaint = 'crop-rotation';
        mapModel.value?.events.emitFieldUpdated();
        mapModel.value?.events.emitFieldPaint();
      }
    };

    const setCropRate = async (value: number) => {
      useApp().setLoadingScreen('FieldCropRateFieldsBlock-set', 'Сохранение рейтинга полей');
      await Promise.all(selectedFields.value.map((field) => field.setCropRate(CropList.selected.value?.name, value)));

      selectedFields.value.forEach((a) => {
        if (a.feature.properties) a.feature.properties.__cropRateColor = '#ffffff';
        const cropRate = a.cropRates.find((f) => f.cropItem === CropList.selected.value?.id && f.rate !== undefined && f.rate);
        if (cropRate && a.feature.properties) {
          a.feature.properties.__cropRateColor = cropRate.color;
        }
      });

      mapModel.value?.events.emitFieldUpdated();
      mapModel.value?.events.emitFieldPaint();

      cleanSelectedFields();
      useApp().setLoadingScreen('FieldCropRateFieldsBlock-set');
    };

    return {
      PublicCropList,
      CropList,
      CropRateColors,
      DictionaryList,
      setCropRate,
      Delete,
      Check,
      manageMode,
      showCropManage,
      selectCrop,
      setSelectState,
      selectedFields,
    };
  },
});
</script>
