import { Position } from 'geojson';
import { crossProduct } from '@/lib/map/crossProduct';

export const isPointInline = (a: Position, b: Position, c: Position): boolean => {
  const crossProductValue = crossProduct(a, b, c);
  if (Math.abs(crossProductValue) > 1e-10) return false; // Если точки не на одной прямой

  const dotProduct = (c[0] - a[0]) * (b[0] - a[0]) + (c[1] - a[1]) * (b[1] - a[1]);
  if (dotProduct < 0) return false;

  const squaredLengthAB = (b[0] - a[0]) ** 2 + (b[1] - a[1]) ** 2;
  return dotProduct <= squaredLengthAB;
};
