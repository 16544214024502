import { Feature, Polygon, Position } from 'geojson';
import { LinearRingModel } from '@/models/geojson/LinearRingModel';
import { Model } from '@/models/Model';

export class PolygonModel extends Model {
  get id(): number {
    return this._id;
  }

  get data(): LinearRingModel[] {
    return this._data;
  }

  static counter = 1000000;

  private readonly _id: number;

  private _data: LinearRingModel[] = [];

  constructor(coordinates: Position[][]) {
    super();
    this._data = coordinates.map((v) => new LinearRingModel(v));
    this._id = PolygonModel.counter++;
  }

  addLinearRing(coords: Position[]) {
    this._data.push(new LinearRingModel(coords));
  }

  deleteLinearRing(index: number) {
    if (index > 1000000) {
      this._data = this._data.filter((v) => v.id !== index);
      return;
    }
    this._data.splice(index, 1);
  }

  update(coordinates: Position[][]) {
    this._data = coordinates.map((v) => new LinearRingModel(v));
  }

  toFeature(): Feature<Polygon> {
    return {
      type: 'Feature',
      properties: {
        id: this._id,
      },
      id: this._id,
      geometry: {
        type: 'Polygon',
        coordinates: this._data.map((v) => v.data.map((p) => p.data)),
      },
    };
  }
}
