import { polygonToPoints } from '@/lib/map/polygonToPoints';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { MapModel } from '@/models/map/MapModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import mapboxgl, { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import {
  Feature, FeatureCollection, MultiPolygon, Polygon, Position,
} from 'geojson';
import {
  area,
  bboxPolygon,
  booleanPointInPolygon,
  distance,
  featureCollection,
  multiPolygon,
  point,
  lineString,
  polygon,
} from '@turf/turf';
import { formatArea } from '@/utils/formatArea';
import EventBus from '@/services/eventBus/EventBus';
import { fieldDrawerActionType } from '@/constants/types/fieldDrawer/fieldDrawerActionType';
import { getByIntersectFeatures } from '@/lib/map/getByIntersectFeatures';
import FieldsList from '@/modules/fields/FieldsList';
import { MultiPolygonModel } from '@/models/geojson/MultiPolygonModel';
import { getSelfIntersections } from '@/lib/map/getSelfIntersections';
import { checkHoleIntersections } from '@/lib/map/checkHoleIntersections';

export class MapLayerDrawerModel extends MapLayerModel implements IMapLayerModel {
  get showArea(): boolean {
    return this._showArea;
  }

  set showArea(value: boolean) {
    this._showArea = value;
  }

  /** Индекс выбранного полигона в массиве _positionDrawerBuffer */
  public indexPolygon = 0;

  public indexLinearRing = 0;

  public magnetActive = false;

  public magnetDistance = 15

  private _positionDrawerBuffer: Position[][][] = [];

  private movePointIndex = -1;

  private bindMoveMouse = this.handlerMoveMouse.bind(this);

  private bindClick = this.handlerClick.bind(this);

  private _timeoutLabel: ReturnType<typeof setTimeout>

  private _showArea = false

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'drawer', input.uuid);
    this._data = input as MultiPolygonModel;

    this._positionDrawerBuffer = this.removeCloseCoords(this._data.data.map((a) => a.data.map((b) => b.data.map((c) => c.data))));

    this.createSourceLayer();
    this.handlerHoverPoint();
    this._mapModel?.map && (this._mapModel.map.getCanvas().style.cursor = 'pointer');
    this.layerIds.push(...[this.layerId, `${this.layerId}-contour`, `${this.layerId}-points`, `${this.layerId}-label-area`]);
    this.sourceIds.push(...[this.sourceId, `${this.sourceId}-points`, `${this.sourceId}-label-area`]);
    this.initializePointHandlers();

    this._mapModel?.map?.on('mousemove', this.bindMoveMouse);
    this._mapModel.map.on('click', this.bindClick);
  }

  get cordsDrawer(): Position[][][] {
    return this._positionDrawerBuffer;
  }

  private _data: MultiPolygonModel;

  get data(): MultiPolygonModel {
    return this._data;
  }

  private _activeMode: 'none' | 'create' | 'edit' = 'none';

  get activeMode(): 'none' | 'create' | 'edit' {
    return this._activeMode;
  }

  offEventListener() {
    this._mapModel?.map?.off('mousemove', this.bindMoveMouse);
    this._mapModel?.map?.off('click', this.bindClick);
  }

  createSourceLayer() {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this._data.toFeature(),
    });
    this._mapModel?.map?.addSource(`${this.sourceId}-points`, {
      type: 'geojson',
      data: polygonToPoints(this._data.toFeature()),
    });
    this._mapModel?.map?.addSource(`${this.sourceId}-label-area`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      } as FeatureCollection,
    });

    this._mapModel?.map?.addLayer({
      id: this.layerId,
      type: 'fill',
      source: this.sourceId,
      metadata: { type: 'drawer-fill' },
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['boolean', ['get', 'isSelfIntersect'], false],
          '#ff0000',
          '#00ffea',
        ],
        'fill-opacity': 0.4,
      },
    });
    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-contour`,
      type: 'line',
      source: this.sourceId,
      metadata: { type: 'drawer-line' },
      paint: {
        'line-color': '#000000',
        'line-width': 2,
      },
    });
    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-points`,
      type: 'circle',
      source: `${this.sourceId}-points`,
      metadata: { type: 'drawer-point' },
      paint: {
        'circle-radius': 5,
        'circle-color': '#000000',
      },
    });

    this._mapModel?.map?.addLayer({
      id: `${this.layerId}-label-area`,
      type: 'symbol',
      source: `${this.sourceId}-label-area`,
      metadata: { type: 'drawer-label' },
      layout: {
        'text-field': ['get', 'label'],
        'text-radial-offset': 0.5,
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-size': [
          'interpolate', ['linear'], ['zoom'],
          9, 0,
          10, 5,
          11, 10,
          12, 13,
          20, 14,
        ],
        'text-justify': 'center',
        'text-font': [
          'literal',
          ['Inter Regular'],
        ],
      },
      paint: {
        'text-color': '#25282B',
        'text-halo-color': 'rgba(255,255,255,0.6)',
        'text-halo-width': 2,
      },
    });

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-contour`, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-points`, MapAnchorEnum.DRAWER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-label-area`, MapAnchorEnum.DRAWER);
  }

  private checkValid = (coords: Position[][]): boolean => {
    try {
      return coords[0].length > 1 && (
        getSelfIntersections(lineString(coords[0])).length > 0
        || (coords[0].length > 4 && checkHoleIntersections(polygon(coords)))
      );
    } catch (e) {
      return false;
    }
  }

  splitMultiPolygon = (): Feature<Polygon>[] => this.duplicateFirstElement(this._positionDrawerBuffer).map((polygonCoords, index) => ({
    type: 'Feature',
    properties: {
      // Todo вызывает небольшие пропуски кадров
      isSelfIntersect: this.checkValid(polygonCoords),
    },
    geometry: {
      type: 'Polygon',
      coordinates: polygonCoords,
    },
  }));

  refreshPolygin() {
    if (this._data.data) {
      this._positionDrawerBuffer = (this._data.toFeature().geometry.coordinates as [number, number][][][]).map((a) => a.map((b) => b.filter((v, i, arr) => i !== arr.length - 1)));
    }
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(featureCollection(this.splitMultiPolygon()));
    (this._mapModel?.map?.getSource(`${this.sourceId}-points`) as GeoJSONSource)?.setData(polygonToPoints(this._data.toFeature()));
  }

  updatePolygon(cords: Position[][][], type: fieldDrawerActionType | undefined = undefined) {
    clearTimeout(this._timeoutLabel);
    this._positionDrawerBuffer = cords;
    if (type) {
      this._data.updatePolygons(this.duplicateFirstElement(cords));
      EventBus.$emit('drawerLogType', type);
    }

    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(featureCollection(this.splitMultiPolygon()));
    (this._mapModel?.map?.getSource(`${this.sourceId}-points`) as GeoJSONSource)?.setData(polygonToPoints(multiPolygon(this.duplicateFirstElement(this._positionDrawerBuffer))));

    this._timeoutLabel = setTimeout(() => {
      this.updateLabel();
    }, 1000);
  }

  updateLabel() {
    if ((this._data.data[0].data).length > 2) {
      // const labelCoords = polylabel(this._data.polygon.geometry?.coordinates, 10000, false);

      const fc = area(this._data.toFeature());
      (this._mapModel?.map?.getSource(`${this.sourceId}-label-area`) as GeoJSONSource).setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
        properties: {
          label: formatArea(fc),
        },
      } as Feature);
    } else {
      (this._mapModel?.map?.getSource(`${this.sourceId}-label-area`) as GeoJSONSource).setData({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
        properties: {
          label: '',
        },
      } as Feature);
    }
  }

  setActiveMode(mode: 'edit'| 'create'|'none') {
    this._activeMode = mode;
    this._mapModel.events.emitUpdateDrawerMode(mode);
  }

  clearDraw() {
    this._positionDrawerBuffer = [];
    this.updatePolygon([], 'clear');
  }

  stopDraw() {
    this.updatePolygon(this.removeCloseCoords(this._data.toFeature().geometry.coordinates), 'addRing');
    this._mapModel?.map && (this._mapModel.map.getCanvas().style.cursor = '');
    this.setActiveMode('edit');

    this.indexPolygon = 0;
    this.indexLinearRing = 0;
  }

  handlerClick(e: MapMouseEvent): void {
    if (this.activeMode === 'create') {
      const features = this._mapModel?.map?.queryRenderedFeatures(e.point, {
        layers: [`${this.layerId}-points`],
      });

      const idx = `${this.indexPolygon}_${this.indexLinearRing}_${this._positionDrawerBuffer[this.indexPolygon]?.[this.indexLinearRing]?.length - 1 || 0}`;

      if (
        this._positionDrawerBuffer[this.indexPolygon]?.[this.indexLinearRing]?.length === 1
        && features?.length > 1
      ) {
        //
      } else if (
        features
        && this._positionDrawerBuffer[this.indexPolygon]?.[this.indexLinearRing]?.length > 1
        && features.filter((f) => {
          const { indexA, indexB } = this.parseFeatureIndex(f);

          if (this.indexPolygon === indexA && this.indexLinearRing === indexB) return true;
          return false;
        }).length > 1
        && features.some((a) => (a.properties?.index as string)?.startsWith(idx))

      ) {
        this.stopDraw();
      } else {
        if (!this._positionDrawerBuffer[this.indexPolygon]) {
          this._positionDrawerBuffer[this.indexPolygon] = [];
        }
        if (!this._positionDrawerBuffer[this.indexPolygon][this.indexLinearRing]) {
          this._positionDrawerBuffer[this.indexPolygon][this.indexLinearRing] = [];
        }
        this.handleAddPoint(e);
      }
    } else if (this.activeMode === 'edit' && e.originalEvent.shiftKey) {
      // const { geometryIndex } = this.findGeometryAndRing(e);
      // if (this.data.selectedPolygons.includes(geometryIndex)) {
      //   this.data.selectedPolygons = this.data.selectedPolygons.filter((v) => v !== geometryIndex);
      // } else {
      //   this.data.selectedPolygons.push(geometryIndex);
      // }
      // this.refreshPolygin();
    }
  }

  magnetMouse = (e: { lngLat: { lng: number; lat: number } }) => {
    const getBoundingBox = (_point: {lng: number, lat: number;}, buffer: number): [number, number, number, number] => {
      const earthRadius = 6378137; // Радиус Земли в метрах (сферическая модель)

      const bufferLng = (buffer / (earthRadius * Math.cos((Math.PI * _point.lat) / 180))) * (180 / Math.PI);
      const bufferLat = (buffer / earthRadius) * (180 / Math.PI);
      [];

      return [
        _point.lng - bufferLng, // minLng
        _point.lat - bufferLat, // minLat
        _point.lng + bufferLng, // maxLng
        _point.lat + bufferLat, // maxLat
      ];
    };

    const features = getByIntersectFeatures(this._mapModel.map as mapboxgl.Map, bboxPolygon(getBoundingBox(e.lngLat, this.magnetDistance)) as Feature<Polygon>, ['field', 'drawer-point']);

    let minDistance = Infinity;
    let closestCoordinate: [number, number] | null = null;

    for (const f of features) {
      if (f.id) {
        for (const _polygon of (FieldsList.findFieldById(f.id)?.feature as Feature<MultiPolygon>).geometry.coordinates) {
          for (const ring of _polygon) {
            for (const cord of ring) {
              const _distance = distance(point([e.lngLat.lng, e.lngLat.lat]), point(cord as [number, number]));
              if (_distance < minDistance && _distance * 1000 <= this.magnetDistance) {
                minDistance = _distance;
                closestCoordinate = cord as [number, number];
              }
            }
          }
        }
      } else {
        const { indexA, indexB, indexC } = this.parseFeatureIndex(f);
        if (this.indexPolygon === indexA && this.indexLinearRing === indexB && this._positionDrawerBuffer[indexA][indexB].length === indexC) continue;
        const cord = this._positionDrawerBuffer[indexA][indexB][indexC];

        const _distance = distance(point([e.lngLat.lng, e.lngLat.lat]), point(cord as [number, number]));
        if (_distance < minDistance && _distance * 1000 <= this.magnetDistance) {
          minDistance = _distance;
          closestCoordinate = cord as [number, number];
        }
      }
    }

    return { minDistance, closestCoordinate };
  };

  handlerMoveMouse(e: MapMouseEvent) {
    if (this._positionDrawerBuffer.length > 0 && this.activeMode === 'create') {
      const updatedCoords = this.getUpdatedCoordsOnMove(e);
      this.updatePolygon(updatedCoords);
    }
  }

  handlerHoverPoint() {
    this._mapModel?.map?.on('mouseover', `${this.layerId}-points`, () => {
      if (this._mapModel?.map) {
        this._mapModel.map.getCanvas().style.cursor = 'pointer';
      }
    });
    this._mapModel?.map?.on('mouseleave', `${this.layerId}-points`, () => {
      if (this._mapModel?.map) {
        this._mapModel.map.getCanvas().style.cursor = '';
      }
    });
  }

  initializePointHandlers(): void {
    let startLngLat: [number, number] | null = null;
    let _dragDisabled = false;
    const onMouseMove = (e: MapMouseEvent) => {
      if (this.activeMode === 'edit' && startLngLat && this.movePointIndex !== -1) {
        this.updatePolygonAtIndex(this.movePointIndex, this.getNewPoint(e));
      } else if (this.data.getPolygon(this.indexPolygon) && false) {
        // const delta = calculateDelta({ lng: startLngLat[0], lat: startLngLat[1] }, e.lngLat);
        // const arr: Position[][][] = this._positionDrawerBuffer.map((level1, i) => (this.indexPolygon !== i ? level1 : level1.map(
        //   (level2) => level2.map((level3) => [level3[0] + delta.lng, level3[1] + delta.lat]),
        // )));
        // this.updatePolygon(arr);
      }
    };

    const onMouseDown = (e: MapMouseEvent) => {
      if (this.activeMode !== 'edit') return;
      _dragDisabled = !this._mapModel.map.dragPan.isEnabled();
      const features = this.queryFeatures(e.point);

      if (!features.length) return;
       this._mapModel?.map?.dragPan.disable();
       if (features[0]?.layer?.type === 'circle') {
         const { indexA, indexB, indexC } = this.parseFeatureIndex(features[0]);
         this.indexPolygon = indexA;
         this.indexLinearRing = indexB;
         this.movePointIndex = indexC;
       } if (features[0]?.layer.type === 'fill') {
         const { geometryIndex } = this.findGeometryAndRing(e);
         this.indexPolygon = geometryIndex;
       }
       startLngLat = [e.lngLat.lng, e.lngLat.lat];

      this._mapModel?.map?.on('mousemove', onMouseMove);
    };

    const onMouseUp = (e: MapMouseEvent) => {
      if (this.activeMode !== 'edit' || (!startLngLat)) return;

      const endLngLat: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      const hasMoved = !this.areCoordinatesEqual(startLngLat, endLngLat);
      if (!hasMoved) {
        this.handlePointClick(e);
      } else if (this.movePointIndex !== -1) {
        this.updatePolygonAtIndex(this.movePointIndex, this.getNewPoint(e), 'movePoint');
      }
      !_dragDisabled && this._mapModel?.map?.dragPan.enable();
      startLngLat = null;
      this.movePointIndex = -1;
      this.indexPolygon = -1;
      this._mapModel?.map?.off('mousemove', onMouseMove);
    };

    this._mapModel?.map?.on('mousedown', onMouseDown);
    this._mapModel?.map?.on('mouseup', onMouseUp);
    this._mapModel?.map?.on('mouseout', onMouseUp);
  }

  findGeometryAndRing = (e: MapMouseEvent): {linearRingIndex: number | null, geometryIndex: number | null} => {
    let foundGeometryIndex: number | null = null;
    let foundLinearRingIndex: number | null = null;
    this._data.data.forEach((geometry, geometryIndex) => {
      geometry.data.forEach((ring, ringIndex) => {
        if (ring.data.length <= 3) return;

        const isInside = booleanPointInPolygon(
          point([e.lngLat.lng, e.lngLat.lat]),
          ring.toFeature(),
        );

        if (isInside) {
          foundGeometryIndex = geometryIndex;
          foundLinearRingIndex = ringIndex;
        }
      });
    });

    return {
      linearRingIndex: foundLinearRingIndex,
      geometryIndex: foundGeometryIndex,
    };
  };

  private duplicateFirstElement(cords: Position[][][]): Position[][][] {
    return cords.map((level1) => level1.map((level2) => {
      const firstElement = level2[0];
      return firstElement ? [...level2, firstElement] : level2;
    }));
  }

  private removeCloseCoords(cords: Position[][][]): Position[][][] {
    return cords.map((level1) => level1.map((level2) => level2.filter((_, idx) => level2.length - 1 !== idx)));
  }

  private handleAddPoint(e: { lngLat: { lng: number; lat: number } }) {
    console.log('ckick');
    const newPoint = this.getNewPoint(e);
    const newCoords = this.removeCloseCoords([...this._data.toFeature().geometry.coordinates]);
    newCoords[this.indexPolygon][this.indexLinearRing].push(newPoint);
    this.updatePolygon(newCoords, 'addPoint');

    if (this.shouldUpdatePolygon()) {
      const updatedCoords = this.getUpdatedCoords(newPoint);
      this.updatePolygon(updatedCoords);
    }
  }

  private shouldUpdatePolygon(): boolean {
    return this._positionDrawerBuffer.length > 0 && this.activeMode === 'create';
  }

  private getUpdatedCoords(newPoint: [number, number]): [number, number][][][] {
    const updatedCoords = JSON.parse(JSON.stringify(this._positionDrawerBuffer));
    updatedCoords[this.indexPolygon][this.indexLinearRing].push(newPoint);
    return updatedCoords;
  }

  private getNewPoint(e: { lngLat: { lng: number; lat: number } }): [number, number] {
    if (this.magnetActive) {
      const { closestCoordinate } = this.magnetMouse(e);
      if (closestCoordinate) {
        return closestCoordinate;
      }
    }
    return [e.lngLat.lng, e.lngLat.lat];
  }

  private getUpdatedCoordsOnMove(e: MapMouseEvent): [number, number][][][] {
    const updatedCoords = JSON.parse(JSON.stringify(this.removeCloseCoords(this.data.toFeature().geometry.coordinates)));
    const newPoint = this.getNewPoint(e);
    updatedCoords[this.indexPolygon][this.indexLinearRing].push(newPoint);
    return updatedCoords;
  }

  private queryFeatures(_point: any): any[] | null {
    return this._mapModel?.map?.queryRenderedFeatures(_point, {
      layers: [`${this.layerId}-points`],
    }) ?? null;
  }

  private parseFeatureIndex(feature: Feature): { indexA: number; indexB: number, indexC: number} {
    const [indexA, indexB, indexC] = feature?.properties?.index?.split('_').map(Number) || [0, 0, 0];
    return { indexA, indexB, indexC };
  }

  private areCoordinatesEqual(coord1: [number, number], coord2: [number, number]): boolean {
    return coord1[0] === coord2[0] && coord1[1] === coord2[1];
  }

  private updatePolygonAtIndex(index: number, coords: [number, number], type: 'movePoint' = undefined): void {
    this._positionDrawerBuffer[this.indexPolygon][this.indexLinearRing][index] = coords;
    this.updatePolygon(this._positionDrawerBuffer, type);
  }

  private handlePointClick(e: MapMouseEvent): void {
    const features = this.queryFeatures(e.point);
    if (!features) return;
    if (features[0]?.layer.type === 'circle') {
      const { indexA, indexB, indexC } = this.parseFeatureIndex(features[0]);

      this.indexPolygon = indexA;
      this.indexLinearRing = indexB;

      if (e.originalEvent.ctrlKey) {
        this.removePoint(indexC);
      } else {
        this.addMidpoints(indexC);
      }
    }
  }

  private removePoint(index: number): void {
    const arr = [...this._positionDrawerBuffer];
    arr[this.indexPolygon][this.indexLinearRing].splice(index, 1);

    this.updatePolygon(arr, 'deletePoint');

    if (this._positionDrawerBuffer.length === 0) {
      this.clearDraw();
    }
  }

  private addMidpoints(index: number): void {
    const calcMidpoint = (a: Position, b: Position): Position => [
      (a[0] + b[0]) / 2,
      (a[1] + b[1]) / 2,
    ];

    this._positionDrawerBuffer[this.indexPolygon][this.indexLinearRing] = this._positionDrawerBuffer[this.indexPolygon][this.indexLinearRing].flatMap((item, i, arr) => {
      if (i !== index) return [item];

      const prev = arr[i - 1] ?? arr[arr.length - 1];
      const next = arr[i + 1] ?? arr[0];

      return [calcMidpoint(prev, item), item, calcMidpoint(item, next)];
    });

    this.updatePolygon(this._positionDrawerBuffer, 'addMidPoint');
  }
}
