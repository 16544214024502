<template src="./POITableModal.pug" lang="pug"/>
<style src="./POITableModal.scss" lang="scss"/>

<script lang="ts">
import FieldsList from '@/modules/fields/FieldsList';
import PoisList from '@/modules/poi/PoisList';
import WindowBox from '@/components/shared/WindowBox/WindowBox.vue';
import { useMapLayout } from '@/composables/useMapLayout';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { POIParams } from '@/assets/data/POIParams';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { Document, Search } from '@element-plus/icons-vue';
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';
import ApiService from '@/services/api/ApiService';
import { UiTableKeyType } from '@/constants/types/ui/UiTableKeyType';
import { PoiParamsType } from '@/constants/types/PoiParamsType';
import { SimpleType } from '@/constants/types/BasedTypes';
import { useAuth } from '@/composables/useAuth';
import { TableKeyEnum } from '@/constants/enums/TableKeyEnum';
import { useTranslate } from '@tolgee/vue';
import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import { cleanObject } from '@/utils/cleanObject';
import POITable from '@/modules/poi/ui/POITable/POITable.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { PoiModel } from '@/models/poi/PoiModel';

export default defineComponent({
  name: 'POITableModal',
  components: {
    WindowBox,
    Document,
    POITable,
  },
  props: {
    poiGroupId: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { hideBlock } = useMapLayout();
    const { mapModel } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const computedPoiId = computed(() => props.poiGroupId);
    const viewMode = ref(false);
    const enterPoiId = ref<number>(0);
    const enterPoiParam = ref<string>('');
    const { accessToken } = useAuth();
    const poiFormInfo = ref<{
      name: string,
      descr: string,
      visited: boolean,
      params: Record<string, any>[]
    }>({
      name: '',
      descr: '',
      visited: false,
      params: [],
    });
    const editParam = ref<any>();
    const table = ref();
    const searchValue = ref('');
    const searchParam = ref();
    const showListParams = ref<string[]>([]);
    const { t } = useTranslate('poi');
    const copyPoiKeys = ['n_prob', 'x', 'y', 'field', 'description', ...OrderedPoiKeys];

    const computedPois = computed(() => PoisList.pois.value?.filter((v) => v.group === computedPoiId.value));

    const computedPoisKeysParams = computed(() => {
      const dp = new Set<string>();
      computedPois.value.forEach((p: PoiModel) => {
        Object.keys(p.params).forEach((k: string) => {
          if (![undefined, null, true, false, -1].some((e) => e === p.params[k]) && !['y', 'x', 'n_prob'].includes(k)) {
            dp.add(k);
          }
        });
      });

      return Array.from(dp);
    });

    const tabsCheck = () => {
      let arr: POICategoryEnum[] = [];
      const poiType = PoisList.getGroupModel(computedPoiId.value)?.type;
      if (poiType === 1) {
        arr = [POICategoryEnum.CUT, POICategoryEnum.LIMIT_FACTOR, POICategoryEnum.AGRO_CHEMISTRY];
      } if (poiType === 2) {
        arr = [POICategoryEnum.AGRO_CHEMISTRY];
      }
      return arr;
    };

    const categoryList = computed(() => tabsCheck());

    const sortLabels = (a: string, b: string) => {
      const indexA = copyPoiKeys.indexOf(a);
      const indexB = copyPoiKeys.indexOf(b);
      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    };

    const poiLabelsSort = computed((): string[] => Array.from([...computedPoisKeysParams.value,
      ...Object.keys(POIParams).map((key) => (POIParams[key].category === POICategoryEnum.SOIL ? key : '')).filter((v) => !!v)]
      .reduce((set: Set<string>, v: string) => set.add(v), new Set<string>(['n_prob', 'x', 'y', 'field', 'description'])))
      .sort(sortLabels));

    const getAlias = (a: string) => {
      if (a === 'n_prob') {
        return t.value('n_prob');
      }
      return t.value(a);
    };

    const computedData = computed(() => {
      const arr: Record<string, any>[] = [];

      computedPois.value.forEach((poi) => {
        const obj: Record<string, any> = {
          n_prob: {
            name: poi.name,
            photoList: poi.photos.map((item) => `${process.env.VUE_APP_API_URL}/scouting/photo/${item.id}/file/?access_token=${accessToken.value}`),
            description: poi.description,
          },
          x: poi.x.toFixed(5),
          y: poi.y.toFixed(5),
          id: poi.id,
          field: poi.field !== null ? FieldsList.getFieldModel(poi.field)?.name || FieldsList.getFieldModel(poi.field)?.nameRus : '',
          description: poi.description,
        };

        Object.keys(poi.params).forEach((key) => {
          if (([undefined, ''] as SimpleType[]).includes(poi.params[key])) {
            return;
          }
          if (['n_prob', 'x', 'y', 'id', 'field', 'description'].includes(key)) {
            return;
          }
          if (['int', 'number'].some((s) => s === POIParams[key]?.type)) {
            if (!poi.params.hasOwnProperty(key) || poi.params[key] === undefined) {
              obj[key] = -1;
            } else {
              obj[key] = Number.isNaN(Number(poi.params[key])) ? 0 : Number(poi.params[key]);
            }
          } else if (['stars'].some((s) => s === POIParams[key]?.type)) {
            obj[key] = poi.params[key] === undefined ? 0 : Number(poi.params[key]);
          } else {
            obj[key] = poi.params[key];
          }
        });

        arr.push(obj);
      });
      return arr;
    });

    const computedKeys = computed((): UiTableKeyType[] => {
      const arr: UiTableKeyType[] = [];

      const type = (key: string): TableKeyEnum => {
        if (key === 'n_prob') {
          return TableKeyEnum.poiName;
        }
        if (['field', 'y', 'x'].includes(key)) {
          return TableKeyEnum.view;
        }
        if (POIParams[key]?.type) {
          return POIParams[key]?.type as TableKeyEnum;
        }
        return TableKeyEnum.string;
      };

      const paramCategory = (param: string) => {
        const p = POIParams[param];
        if (param === 'n_prob' || param === 'field') {
          return POICategoryEnum.NAME;
        }
        if (param === 'x' || param === 'y') {
          return POICategoryEnum.COORDS;
        }
        if (param === 'description') {
          return POICategoryEnum.DESCRIPTION;
        }
        return p?.category || POICategoryEnum.NOT_STANDARDIZED;
      };

      poiLabelsSort.value.forEach((a: string) => {
        arr.push({
          name: a,
          type: type(a),
          alias: getAlias(a),
          searchAble: true,
          sortAble: true,
          editAble: !copyPoiKeys.some((b) => b === a),
          hideAble: ['x', 'y'].some((b) => a === b) || computedData.value.every((b) => ([-1, undefined, ''] as SimpleType[]).includes(b[a] as SimpleType)),
          lock: false,
          columnMetrics: 1,
          tableDownload: a !== 'field',
          precision: POIParams[a]?.precision,
          params: {
            selectValues: type(a) === 'select' ? POIParams[a]?.select as PoiParamsType || [] : [],
            clearAble: type(a) === 'string',
            deleteAble: !copyPoiKeys.some((b) => b === a),
            category: paramCategory(a),
          },
        });
      });

      Object.keys(POIParams).forEach((a) => {
        const param = POIParams[a];
        if (param.category && categoryList.value.includes(param.category) && !arr.some((element) => element.name === a)) {
          arr.push({
            name: a,
            type: type(a),
            alias: getAlias(a),
            searchAble: true,
            sortAble: true,
            editAble: !copyPoiKeys.some((b) => b === a),
            hideAble: ['x', 'y'].some((b) => a === b) || computedData.value.every((b) => ([-1, undefined, ''] as SimpleType[]).includes(b[a] as SimpleType)),
            lock: false,
            columnMetrics: 1,
            tableDownload: a !== 'field',
            precision: param?.precision,
            params: {
              selectValues: type(a) === 'select' ? POIParams[a]?.select as PoiParamsType || [] : [],
              clearAble: type(a) === 'string',
              deleteAble: !copyPoiKeys.some((b) => b === a),
              category: param.category,
            },
          });
        }
      });
      return arr;
    });

    const isCheckElementType = (poiKeyParam: string, type: string) => POIParams[poiKeyParam]?.type === type;

    const isActiveInput = (id: number, param: string) => enterPoiId.value === id && enterPoiParam.value === param;

    const checkParams = () => {
      const poiParam = poiFormInfo.value.params.find((s) => s.key === enterPoiParam.value);
      if (poiParam === undefined) {
        poiFormInfo.value.params.push({
          key: enterPoiParam.value,
          value: editParam.value,
        });
      }
    };

    const save = async () => {
      checkParams();

      const formatedParams: any = {};
      const poi = PoisList.getPoiModel(enterPoiId.value);
      if (poi) {
        if (enterPoiParam.value === 'description') {
          poi.description = editParam.value;
        } else {
          poiFormInfo.value.params.forEach((param) => {
            if (![undefined].some((s) => s === param.value)) {
              if (param.type === 'stars') {
                formatedParams[param.key] = Number(param.value);
              } else {
                formatedParams[param.key] = param.value;
              }
            }
          });
          Object.keys(poi.params).forEach((key) => {
            const value = poi.params[key] || undefined;
            if ([undefined].some((s) => s === formatedParams[key])) {
              formatedParams[key] = value;
            }
          });
          poi.params = {
            ...cleanObject(formatedParams),
          };
        }

        poi.name = poi.params.n_prob as string;
        await poi.saveModel(true);
        EventBus.$emit(EventsEnum.MapRedrawPois);
        poiFormInfo.value = {
          name: '',
          descr: '',
          visited: false,
          params: [],
        };
      }
    };

    onMounted(() => {
      showListParams.value.push(...computedPoisKeysParams.value);
    });

    const handlerChangeTable = (key: UiTableKeyType, r: SimpleType) => {
      editParam.value = r;
      save();
    };
    const handlerClickTable = (key: UiTableKeyType, row: Record<string, SimpleType>) => {
      enterPoiParam.value = key.name;
      editParam.value = row[key.name];
      enterPoiId.value = row.id as number;
    };

    const addNewParam = (key: UiTableKeyType) => {
      ApiService.scouting.patchPoiGroupParamAddColumn(computedPoiId.value || 0, [{ new: key.name, default: '' }]);
    };
    const deleteParam = (key: UiTableKeyType) => {
      ApiService.scouting.patchPoiGroupParamDeleteColumn(computedPoiId.value || 0, [key.name]);
      PoisList.fetchPois(true);
    };

    const handlerColumnRename = async (oldName: string, newName: string) => {
      await ApiService.scouting.patchPoiGroupParamRenameColumn(computedPoiId.value || 0, [{ new: newName, old: oldName }]);
      PoisList.fetchPois(true);
    };
    console.log(tabsCheck(), categoryList.value, computedKeys.value);
    return {
      Search,
      editParam,
      PoisList,
      computedData,
      POIParams,
      computedPois,
      viewMode,
      poiFormInfo,
      enterPoiParam,
      enterPoiId,
      isCheckElementType,
      isActiveInput,
      table,
      save,
      showListParams,
      searchParam,
      searchValue,
      poiLabelsSort,
      Document,
      handlerChangeTable,
      computedKeys,
      handlerClickTable,
      addNewParam,
      deleteParam,
      handlerColumnRename,
      computedPoiId,
    };
  },
});

</script>
