import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { StructModel } from '@/models/struct/StructModel';
import ApiService from '@/services/api/ApiService';
import { Ref, ref, UnwrapRef } from 'vue';

class StructList {
  private _structs = ref<StructModel[]>([]);

  get structs(): Ref<UnwrapRef<StructModel[]>> {
    return this._structs;
  }

  private _activeStruct = ref<StructModel | undefined>();

  get activeStruct(): Ref<StructModel | undefined> {
    return this._activeStruct;
  }

  async setActiveStruct(id: string | number | undefined) {
    this._activeStruct.value = this._structs.value.find((m) => m.id === Number(id)) as StructModel | undefined;
  }

  structPath(struct: UnwrapRef<StructModel>, path: string[] = []): string[] {
    path.unshift(struct.name);
    const parent = this._structs.value.find((v) => v.id === struct.parent);
    if (parent) {
      return this.structPath(parent, path);
    }
    return path;
  }

  async fetchStructs(force = false) {
    return fetcher(LoadingNamesEnum.STRUCT_LIST, 'default', force, async () => {
      const { data } = await ApiService.struct.structUnits();
      data.forEach((v) => {
        if (!data.find((s) => s.id === v.parent)) {
          v.parent = null;
        }
        return v;
      });
      this._structs.value = [];
      data
        .sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }))
        .forEach((dto) => {
          this._structs.value.push(new StructModel(dto));
        });
    }, true);
  }
}

export default new StructList();
