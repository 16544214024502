<template lang="pug" src="./FieldEditBlock.pug"/>
<style lang="scss" src="./FieldEditBlock.scss"/>

<script lang="ts">
import { LandCategory } from '@/assets/data/LandCategory';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import UiInput from '@/components/ui/Input/UiInput.vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import { useAuth } from '@/composables/useAuth';
import { useFormatter } from '@/composables/useFormatter';
import { useMap } from '@/composables/useMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMapLayout } from '@/composables/useMapLayout';
import { useNavigation } from '@/composables/useNavigation';
import { FIELD_MAX_AREA_SIZE } from '@/constants/constants/fields';
import { createHelperHtml, editHelperHtml } from '@/constants/constants/helpers';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { RuleFieldName } from '@/constants/rules/RuleFieldName';
import { FieldModel } from '@/models/field/FieldModel';
import { MapAreaModel } from '@/models/map/data/MapAreaModel';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldsList from '@/modules/fields/FieldsList';
import MonitoringChartBlock
  from '@/modules/monitoring/ui/MonitoringChartBlock/MonitoringChartBlock.vue';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import type { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import EventBus from '@/services/eventBus/EventBus';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { fieldSquare } from '@/utils/fieldSquare';
import {
  Delete, Download, Edit, EditPen, Place,
} from '@element-plus/icons-vue';
import { area } from '@turf/turf';
import {
  ElMessageBox, ElNotification, FormInstance, FormRules,
} from 'element-plus';
import { MultiPolygon, Polygon } from 'geojson';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import { useStruct } from '@/composables/useStruct';
import { useDrawer } from '@/composables/useDrawer';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { FieldsCollection } from '@/collection/FieldsCollection';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import FieldSplittingBlock from '@/modules/fields/ui/FieldSplittingBlock/FieldSplittingBlock.vue';
import FieldCombiningBlock from '@/modules/fields/ui/FieldCombiningBlock/FieldCombiningBlock.vue';

export default defineComponent({
  name: 'FieldEditBlock',
  components: {
    Edit,
    UiIcon,
    Download,
    EditPen,
    Delete,
    Place,
    RightPanel,
    UiInput,
    MonitoringChartBlock,
    NotFound,
    FieldSplittingBlock,
    FieldCombiningBlock,
  },
  props: {
    field: {
      type: Object as PropType<FieldModel>,
      required: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const { hideBlock, showBlock } = useMapLayout();
    const {
      fields,
      fitStruct,
      mapModel,
      helper,
      informationMode,
      mapContainerMode,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const { accessToken } = useAuth();
    const { activeSector } = useNavigation();
    const { activeDrawer, drawerMode } = useDrawer();
    const { drawerStatus } = useMap();
    const active = ref<boolean>(false);
    const activeDrag = ref<boolean>(false);
    const square = (sq: number) => fieldSquare(sq, 'g-w');
    const exportDialog = ref(false);
    const isOpenSplittingField = ref(false);
    const isOpenCombineFields = ref(false);
    const weather = ref();

    const form = ref({
      name: '',
      name_rus: '',
      farmunit: StructList.activeStruct.value?.id,
      descr: '',
      sq_acc: 0,
      rates: [] as string[],
    });

    const disabled = computed(() => drawerStatus.value.mode !== 'inactive');

    const editFeature = computed(() => props.field?.dto);

    const toggleEdit = () => {
      active.value = !active.value;
      if (active.value) {
        form.value.name = editFeature.value?.properties.name || '';
        form.value.name_rus = editFeature.value?.properties.name_rus || '';
        form.value.descr = editFeature.value?.properties.descr || '';
        form.value.sq_acc = editFeature.value?.properties.sq_acc || 0;
        form.value.rates = editFeature.value?.properties.rating ? editFeature.value?.properties.rating.split('-') : [];
        if (form.value.rates.length === 0) form.value.rates = ['', ''];
        if (form.value.rates.length === 1) form.value.rates.push('');
      }
      editFeature.value && EventBus.$emit(EventsEnum.MapFeatureEdit, { feature: editFeature.value, edit: active.value });
    };
    const openSplittingField = () => {
      isOpenSplittingField.value = true;
    };

    const openCombineFields = () => {
      isOpenCombineFields.value = true;
    };

    const toggleEditDrag = () => {
      activeDrawer.value = !activeDrawer.value;
      drawerMode.value = 'edit';
      mapContainerMode.value = 'editField';
    };

    watch(activeDrawer, (a) => {
      const fieldsLayer = mapModel.value.getLayer(MapLayerTypeEnum.FIELDS) as MapLayerFieldsModel;
      const collection = fieldsLayer.data as FieldsCollection;
      if (a) {
        collection.collection = collection.collection.filter((f) => f.id !== props.field.id);
        fieldsLayer.redrawFields();
      } else {
        collection.collection.push(props.field);
        fieldsLayer.redrawFields();
      }
    });

    const exportRequest = () => {
      exportDialog.value = true;
    };

    const validateName = (rule: any, value: any, callback: any) => {
      if (fields.value.some((f) => f.name === form.value.name && f.id !== Number(editFeature.value?.properties.pk))) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const fieldEditRef = ref<FormInstance>();
    const rules = reactive<FormRules>({
      name: [
        ...RuleFieldName,
        {
          validator: validateName,
          trigger: 'blur',
        },
      ],
      name_rus: [
        {
          min: 0,
          max: 256,
          message: 'Длина полного наименования поля не должна превышать 256 символов',
          trigger: ['blur', 'change'],
        },
      ],
    });

    const isMultipolygon = computed(() => editFeature.value?.geometry.coordinates[0].length > 1 || editFeature.value?.geometry.coordinates.length > 1);

    const save = async () => {
      active.value = false;
      const data = {
        name: form.value.name,
        name_rus: form.value.name_rus,
        descr: form.value.descr,
        sq_acc: form.value.sq_acc,
        rating: form.value.rates.filter((v) => v).join('-'),
      };

      const fieldId = Number(editFeature.value?.properties.pk);
      if (fieldId) {
        await ApiService.struct.patchField(fieldId, data);
        await FieldsList.fetchField(fieldId);
          mapModel.value?.events.emitFieldUpdated();
      }
    };

    const onSubmit = async (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          save();
        }
      });
    };

    const closeEdit = () => {
      hideBlock('FieldEditBlock');
      setTimeout(() => {
        if (activeSector.value?.block === 'FieldsListBlock') {
          showBlock('StructViewBlock');
          fitStruct();
        }
      }, 100);

      EventBus.$emit(EventsEnum.FieldToggleActivate, editFeature.value?.properties.pk, false);
      EventBus.$emit(EventsEnum.MapFeatureEdit, { feature: editFeature.value, edit: false });
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
    };

    const deleteField = () => {
      ElMessageBox.prompt(
        `Чтобы удалить поле, введите delete/${editFeature.value?.properties.name}`,
        'Удаление поля',
        {
          inputValidator: (text) => text === `delete/${editFeature.value?.properties.name}`,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
          inputErrorMessage: `Введите delete/${editFeature.value?.properties.name}`,
        },
      )
        .then(() => {
          if (editFeature.value) {
            FieldsList.deleteField(Number(editFeature.value.properties.pk)).then(() => {
              EventBus.$emit(EventsEnum.MapFeaturesUpdated);
              closeEdit();
              informationMode.value = 'none';
            });
          }
        });
    };

    const landCategories = computed(() => [
      {
        value: '',
        label: 'Не выбрано',
      },
      ...Object.keys(LandCategory).sort().map((key) => ({
        value: key.toString(),
        label: key,
      })),
    ]);

    const file = (type: 'contour', format: 'esri' | 'kml') => {
      if (type === 'contour') {
        const structId = useStruct().structId.value;
        return `${process.env.VUE_APP_API_URL}/gis/export_fields/?farmunit=${structId}&fieldlist=${editFeature.value?.properties.pk}&format=${format}&access_token=${accessToken.value}`;
      }
      return '';
    };

    const structTitle = computed(() => (StructList.activeStruct.value ? `<span>${StructList.structPath(StructList.activeStruct.value).join('</span> <span>')}</span>` : ''));

    const nameError = computed(() => 'yes');

    const isNumber = (evt: KeyboardEvent): void => {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      }
    };

    const stopDrag = (e: KeyboardEvent): void => {
      if (e.keyCode === 27 && activeDrag.value) {
        toggleEditDrag();
      }
    };

    const fetchWeatherData = async () => {
      if (props.field !== undefined) {
        const { data } = await ApiService.services.getWeather(props.field.bounds.getCenter());
        weather.value = data;
      } else {
        weather.value = undefined;
      }
    };

    // region хуки
    watch(editFeature, () => {
      active.value = false;
      activeDrag.value && toggleEditDrag();
      fetchWeatherData();
    });

    onBeforeUnmount(() => {
      closeEdit();
    });

    document.addEventListener('keydown', stopDrag);

    onUnmounted(() => {
      EventBus.$emit(EventsEnum.FieldToggleActivate, editFeature.value?.properties.pk);
      EventBus.$emit(EventsEnum.MapPolygonDrawStop);
      EventBus.$emit(EventsEnum.MapEditOutline, { feature: editFeature.value, edit: false });
      useMap().activeFieldId.value = undefined;
      document.removeEventListener('keydown', stopDrag);
      activeDrag.value && toggleEditDrag();
    });

    const handlerUpdateDrawerMode = (mode: string) => {
      if (activeDrag.value) {
        if (mode === 'edit') {
          helper.value = editHelperHtml;
        } if (mode === 'create') {
          helper.value = createHelperHtml;
        } if (mode === 'none') {
          helper.value = '';
        }
      }
    };

    onMounted(() => {
      fetchWeatherData();
    });
    mapModel.value.events.onUpdateDrawerMode(handlerUpdateDrawerMode);
    onUnmounted(() => {
      mapModel.value.events.offUpdateDrawerMode(handlerUpdateDrawerMode);
    });

    // endregion
    return {
      FIELD_MAX_AREA_SIZE,
      disabled,
      activeDrag,
      toggleEditDrag,
      Download,
      EditPen,
      Delete,
      deleteField,
      editFeature,
      isMultipolygon,
      active,
      toggleEdit,
      save,
      closeEdit,
      structTitle,
      form,
      onSubmit,
      nameError,
      rules,
      fieldEditRef,
      exportRequest,
      exportDialog,
      landCategories,
      square,
      file,
      isNumber,
      weather,
      capitalizeFirstLetter,
      isOpenSplittingField,
      isOpenCombineFields,
      openCombineFields,
      openSplittingField,
    };
  },
});
</script>
