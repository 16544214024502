import { Feature, Point, Position } from 'geojson';
import { Model } from '@/models/Model';

export class PositionModel extends Model {
  get data(): Position {
    return this._data;
  }

  get lng(): number {
    return this._data[0];
  }

  get lat(): number {
    return this._data[1];
  }

  get id(): number {
    return this._id;
  }

  private _data: Position

  static counter = 1000000;

  private readonly _id: number;

  constructor(coordinates: Position) {
    super();
    this._data = coordinates;
    this._id = PositionModel.counter++;
  }

  move(lng: number, lat: number): void {
    this._data = [lng, lat];
  }

  toFeature(): Feature<Point> {
    return {
      id: this.id,
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: this._data,
      },
      properties: {
      },
    };
  }
}
