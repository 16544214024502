import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import ApiService from '@/services/api/ApiService';
import { reactive } from 'vue';
import { CropRotationModel } from '@/models/cropRotation/CropRotationModel';
import DictionaryEvents from '@/modules/dictionary/DictionaryEvents';
import { useApp } from '@/composables/useApp';
import StructEvents from '@/modules/struct/StructEvents';
import { fetcher } from '@/lib/tools/fetcher';
import { CropModel } from '@/models/crop/CropModel';
import FieldsList from '@/modules/fields/FieldsList';

class CropRotationList {
  public readonly rotations = reactive<CropRotationModel[]>([]);

  constructor() {
    DictionaryEvents.onCropDeleted(async () => {
      useApp().setLoadingScreen('crop-rotation', 'Обновление данных о севообороте');
      await this.fetchRotation(true);
      useApp().setLoadingScreen('crop-rotation');
    });
    StructEvents.onChangeStructUnit(async () => {
      await this.fetchRotation(true);
    });
  }

  public getFieldData = (fieldId: number) => this.rotations.find((v) => v.fieldId === fieldId);

  /** get current season crops */
  public getCrops = (): CropModel[] => {
    const fieldIds = FieldsList.fields.map((field) => field.id);
    return this.rotations
      .filter((rotation) => fieldIds.includes(rotation.fieldId))
      .map((rotation) => rotation.cropItem)
      .filter((crop, index, self) => self.findIndex((c) => c.id === crop.id) === index) as CropModel[];
  }

  async fetchRotation(force = false) {
    await fetcher(LoadingNamesEnum.CROP_ROTATION, 'default', force, async () => {
      this.rotations.splice(0, this.rotations.length);
      const { data } = await ApiService.cropRotation.getField();
      data.forEach((dto) => this.rotations.push(new CropRotationModel(dto)));
    });
  }
}

export default new CropRotationList();
