import { ApiHandler } from '@/services/api/ApiHandler';
import { ApiResponse } from '@/services/api/ApiResponse';
import { CropItemDto } from '@/services/api/dto/crop/CropItemDto';
import { PublicCropDto } from '@/services/api/dto/crop/PublicCropDto';
import { useStruct } from '@/composables/useStruct';
import { SeasonDto } from '@/services/api/dto/struct/SeasonDto';

export const endpoints = {
  getPublicCrop: (): string => '/api/v1/books/catalog/public/crop/',
  getCrops: (farmunit: number): string => `/api/v1/books/catalog/${farmunit}/crop/verbose/`,
  createCrop: (farmunit: number): string => `/api/v1/books/catalog/${farmunit}/crop/`,
  updateCrop: (farmunit: number, id: number): string => `/api/v1/books/catalog/${farmunit}/crop/${id}/`,
  deleteCrop: (farmunit: number, id: number): string => `/api/v1/books/catalog/${farmunit}/crop/${id}/`,
  reorderCrops: (farmunit: number): string => `/api/v1/books/catalog/${farmunit}/crop/many/`,
  addHybrid: (farmunit: number, id: number): string => `/api/v1/books/catalog/${farmunit}/crop/${id}/hybrid/`,
  updateHybrid: (farmunit: number, id: number, hybridId: number): string => `/api/v1/books/catalog/${farmunit}/crop/${id}/hybrid/${hybridId}/`,
  deleteHybrid: (farmunit: number, cropId: number, hybridId: number): string => `/api/v1/books/catalog/${farmunit}/crop/${cropId}/hybrid/${hybridId}/`,

  getSeasonById: (farmunit: number, seasonId: number): string => `/api/v1/books/rotation/${farmunit}/season/${seasonId}/`,
};

export class ApiCrop extends ApiHandler {
  public async getCrops(): Promise<ApiResponse<CropItemDto[]>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true, pagination: 9999 }).get<CropItemDto[]>(endpoints.getCrops(unit));
  }

  public async getPublicCrop(): Promise<ApiResponse<PublicCropDto[]>> {
    return await this.request({ auth: true, pagination: 9999 }).get<PublicCropDto[]>(endpoints.getPublicCrop());
  }

  // eslint-disable-next-line camelcase
  public async createCrop(data: { name: string, color: string, order: number, is_green_manure: boolean, is_perennial: boolean, hybrids: { name: string }[]}): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<void>(endpoints.createCrop(unit), data);
  }

  public async reorderCrops(data: { id: number, order: number }[]): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<void>(endpoints.reorderCrops(unit), data);
  }

  public async updateCrop(cropId: number, data: any): Promise<ApiResponse<CropItemDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<CropItemDto>(endpoints.updateCrop(unit, cropId), data);
  }

  public async deleteCrop(cropId: number): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).delete<void>(endpoints.deleteCrop(unit, cropId));
  }

  public async addHybrid(cropId: number, name: string): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).post<void>(endpoints.addHybrid(unit, cropId), { name });
  }

  public async deleteHybrid(cropId: number, hybridId: number): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).delete<void>(endpoints.deleteHybrid(unit, cropId, hybridId));
  }

  public async updateHybrid(cropId: number, hybridId: number, name: string): Promise<ApiResponse<void>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).patch<void>(endpoints.updateHybrid(unit, cropId, hybridId), { name });
  }

  public async getSeasonById(siasonId: number): Promise<ApiResponse<SeasonDto>> {
    const unit = useStruct().structId.value;
    return await this.request({ auth: true }).get<SeasonDto>(endpoints.getSeasonById(unit, siasonId));
  }
}
