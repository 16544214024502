import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { GeoJSONSource } from 'mapbox-gl';

export class MapLayerTaskMapFactModel extends MapLayerModel implements IMapLayerModel {
  readonly data: FieldTaskMapFactModel;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, data: MapInputType) {
    super(mapModel, type, 'task-map-fact', data.uuid);
    this.showLegend.value = false;
    this.data = data as FieldTaskMapFactModel;
    this.createSourceLayer().then(() => {
      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
      this.setPaintMode('default');
    });
  }

  setPaintMode = (value: 'default' | 'deflection'): void => {
    if (value === 'deflection') {
      this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-color', ['case',
        ['==', ['get', 'status', ['get', 'deflectionInput']], 'skip'], 'rgba(150, 75, 0, 0.85)',
        ['==', ['get', 'status', ['get', 'deflectionInput']], 'drop'], 'rgba(150, 75, 0, 0.85)',
        ['has', 'deflectionInput'], ['interpolate', ['linear'], ['get', 'ratio', ['get', 'deflectionInput']], 0, 'rgba(255, 0, 0, 0.45)', 50, 'rgba(255, 255, 0, 0.45)', 100, 'rgba(0, 255, 0, 0.45)'],
        'rgba(255, 255, 255, 0.45)',
      ]);
    } else if (value === 'default') {
      this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-color', ['get', '__color']);
    }
  }

  createSourceLayer = async (): Promise<void> => {
    if (!this.data.geojson?.features) {
      await this.data.fetchData();
    }
    if (this.data.geojson?.features) {
      this._mapModel?.map?.addSource(this.sourceId, {
        type: 'geojson',
        data: this.data.generateColoredFeatures(),
      });
      this._mapModel?.map?.addLayer({
        id: this.layerId,
        type: 'fill',
        source: this.sourceId,
        layout: {},
        metadata: { type: 'task-map-fact' },
        paint: { 'fill-opacity': 1 },
      });
      this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.TASK_MAP);
    }
  }

  public update(): void {
    const source = (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource) || undefined;
    if (source) {
      source.setData(this.data.generateColoredFeatures());
    }
  }

  setOpacity = (value: number): void => {
    if (this.layerIds.includes(this.layerId)) {
      this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', value / 100);
    }
  }
}
