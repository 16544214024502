import { Feature, Position, LineString } from 'geojson';
import { isPointInline } from '@/lib/map/isPointInline';
import { lineString } from '@turf/turf';

export const sliceLineStringByCoords = (p1: Position, p2: Position, feature: Feature<LineString>, precision = 6): Feature<LineString> => {
  if (feature.geometry.coordinates.length < 2) throw new Error('Линия должна содержать минимум 2 точки!');
  if (precision) {}

  const featureCoords = feature.geometry.coordinates;

  if (featureCoords.length === 2) {
    if (!isPointInline(featureCoords[0], featureCoords[1], p1) || !isPointInline(featureCoords[0], featureCoords[1], p2)) {
      throw new Error('p1 или p2 не лежат на линии!');
    }
    return lineString([p1, p2]);
  }
  const count = featureCoords.reduce((acc, coords, i) => {
    if (i < featureCoords.length - 1) {
      if (isPointInline(coords, featureCoords[i + 1], p1)) {
        acc.p1 = true;
      }

      if (isPointInline(coords, featureCoords[i + 1], p2)) {
        acc.p2 = true;
      }
    }
    return acc;
  }, { p1: false, p2: false });
  if (!count.p1 || !count.p2) {
    throw new Error('p1 или p2 не лежат на линии!');
  }

  let found = false;
  let finish = false;

  const result = featureCoords.reduce((acc, coords, i) => {
    if (i < featureCoords.length && !finish) {
      if (isPointInline(coords, featureCoords[i + 1], p1) && isPointInline(coords, featureCoords[i + 1], p2)) {
        acc.push(p1, p2);
        found = true;
        finish = true;
      }
      if (!found) {
        if (isPointInline(coords, featureCoords[i + 1], p1)) {
          acc.push(p1, featureCoords[i + 1]);
          found = true;
        }
        if (isPointInline(coords, featureCoords[i + 1], p2)) {
          acc.push(p2, featureCoords[i + 1]);
          found = true;
        }
      } else if (!finish) {
        if (isPointInline(coords, featureCoords[i + 1], p1)) {
          acc.push(p1);
          finish = true;
        }
        if (isPointInline(coords, featureCoords[i + 1], p2)) {
          acc.push(p2);
          finish = true;
        }
        if (!finish) {
          acc.push(featureCoords[i + 1]);
        }
      }
    }
    return acc;
  }, [] as Position[]);
  return lineString(result);
};
