import { SidePanelType } from '@/constants/types/SidePanelType';
import FieldsPanel from '@/modules/map/container/SidePanel/FieldsPanel/FieldsPanel.vue';
import PoisPanel from '@/modules/map/container/SidePanel/PoisPanel/PoisPanel.vue';
import ImagesPanel from '@/modules/map/container/SidePanel/ImagesPanel/ImagesPanel.vue';
import TaskMapPanel from '@/modules/map/container/SidePanel/TaskMapPanel/TaskMapPanel.vue';
import { TaskTypeEnum } from '@/constants/enums/TaskTypeEnum';
import MonitoringPanel from '@/modules/map/container/SidePanel/MonitoringPanel/MonitoringPanel.vue';
import { SidePanelNameEnum } from '@/constants/enums/SidePanelNameEnum';

const SidePanelItems: SidePanelType[] = [
  {
    name: SidePanelNameEnum.FIELDS,
    tooltip: 'fields-contour',
    component: FieldsPanel,
    options: {},
    icon: '/images/icons/fields.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: SidePanelNameEnum.POI,
    tooltip: 'pois',
    component: PoisPanel,
    options: {},
    icon: '/images/icons/poi.svg',
    width: 370,
    compareAble: true,
  },
  {
    name: SidePanelNameEnum.IMAGES,
    tooltip: 'images-panel',
    component: ImagesPanel,
    options: {},
    icon: 'mdiLayersOutline',
    width: 300,
    compareAble: false,
  },
  {
    name: SidePanelNameEnum.FACT_TASK_MAP,
    tooltip: 'task-map-type-fact-plurar',
    component: TaskMapPanel,
    options: {
      tab: TaskTypeEnum.FACT,
    },
    icon: '/images/icons/fact-map.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: SidePanelNameEnum.TASK_MAP,
    tooltip: 'task-map-plurar',
    component: TaskMapPanel,
    options: {
      tab: TaskTypeEnum.WORK,
    },
    icon: '/images/icons/work-map.svg',
    width: 300,
    compareAble: true,
  },
  {
    name: SidePanelNameEnum.MONITORING,
    tooltip: 'monitoring',
    component: MonitoringPanel,
    options: {},
    icon: '/images/icons/monitoring.svg',
    width: 350,
    compareAble: true,
    permission: {
      target: 'monitoringconfiguration',
      action: 'view',
    },
  },

];

export default SidePanelItems;
