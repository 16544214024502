<template src="./NavigationStructUnits.pug" lang="pug"/>
<style src="./NavigationStructUnits.scss" lang="scss"/>

<script lang="ts">
import {
  computed, defineComponent, nextTick, ref, watch,
} from 'vue';
import { useBilling } from '@/composables/useBilling';
import StructList from '@/modules/struct/StructList';
import { StructModel } from '@/models/struct/StructModel';
import { useTolgee } from '@tolgee/vue';
import { Tree } from 'element-plus/lib/components/tree-v2/src/types';
import { ElTree } from 'element-plus';
import { useUser } from '@/composables/useUser';
import { useStruct } from '@/composables/useStruct';

export default defineComponent({
  name: 'NavigationStructUnits',

  setup() {
    const { contract } = useBilling();
    const { user } = useUser();
    const { structId } = useStruct();
    const searchStruct = ref('');

    const drawer = ref(false);
    const treeRef = ref<InstanceType<typeof ElTree>>();

    const filterTags = ref<number[]>([]);

    const confirmStructChange = ref({
      active: false,
      value: null,
    });

    const forcedIds = new Set<number>();

    // Функция для обхода всего дерева и заполнения forcedIds
    function updateForcedIds(filterValue: [string, number[]], treeData: Array<any>): void {
      forcedIds.clear();
      const searchText = filterValue[0]?.toLowerCase().trim() || '';
      if (!searchText) return; // если строка пуста, ничего не форсим

      // Рекурсивная функция для обхода дерева
      function traverse(node: Tree & { label: string; tags: number[]; children: Array<any>; parent: number; id: number }) {
        // Если label узла совпадает, то все его потомки показываем
        if (node.label.toLowerCase().includes(searchText)) {
          const addAllDescendants = (n: Tree & { children: Array<any>; id: number }) => {
            n.children.forEach((child) => {
              forcedIds.add(child?.id);
              addAllDescendants(child);
            });
          };
          addAllDescendants(node);
        }
        // Обходим всех детей, независимо от того, совпал ли текущий узел
        node.children.forEach((child) => traverse(child));
      }

      treeData.forEach((node) => traverse(node));
    }

    // Фильтрующая функция для el-tree (принимает только два аргумента)
    const filterNode = (
      value: [string, number[]],
      data: Tree & { label: string; tags: number[]; children: Array<any>; parent: number; id: number },
    ): boolean => {
      // Если фильтр пуст – показываем все узлы
      if (!value[0] && (!value[1] || value[1].length === 0)) {
        return true;
      }

      // Если текущий узел попал в принудительный набор – возвращаем true
      if (forcedIds.has(data.id)) {
        return true;
      }

      const searchText = value[0]?.toLowerCase().trim() || '';
      const searchTags = value[1] || [];

      // Проверяем совпадение label
      const isLabelMatch = searchText ? data.label.toLowerCase().includes(searchText) : false;
      // Проверяем совпадение по тегам
      const isTagMatch = searchTags.length > 0 ? searchTags.some((tag) => data.tags.includes(tag)) : false;

      if (isLabelMatch || isTagMatch) {
        return true;
      }

      // Если узел сам не совпадает, но у него есть потомки, удовлетворяющие фильтру – оставляем его
      return data.children.some((child) => filterNode(value, child));
    };

    const allTags = computed(() => {
      const uniqueTags = new Map();

      StructList.structs.value.forEach((struct) => {
        struct.tags.forEach((tag) => {
          if (!uniqueTags.has(tag.id)) {
            uniqueTags.set(tag.id, tag);
          }
        });
      });
      return Array.from(uniqueTags.values());
    });

    const structTree = computed(() => {
      const rootItems = [];
      const lookup = {};

      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const {
          id, name, tags, parent,
        } = item;
        lookup[id] = {
          id, label: name, children: [], tags: tags.map((a) => a.id), parent,
        };
      }

      // Строим дерево
      for (let i = 0; i < StructList.structs.value.length; i++) {
        const item = StructList.structs.value[i];
        const { id, parent } = item;
        const treeItem = lookup[id];

        if (parent === null) {
          // Если у элемента нет родителя, добавляем его в корневые элементы
          rootItems.push(treeItem);
        } else {
          // Если у элемента есть родитель, добавляем его в список детей родителя
          lookup[parent].children.push(treeItem);
        }
      }
      return rootItems;
    });

    watch([searchStruct, filterTags], (a) => {
      nextTick(() => {
        updateForcedIds([searchStruct.value, filterTags.value], structTree.value);
        treeRef.value?.filter(a);
      });
    });
    const structTreeProps = {
      children: 'children',
      label: 'label',
    };

    const selectUnit = async (unit: StructModel) => {
      if (unit.isSelectable) {
        window.location.href = `/app/struct/${unit.id}`;
      }
    };

    const title = computed(() => {
      if (StructList.activeStruct.value) {
        return `<span>${StructList.structPath(StructList.activeStruct.value).join('</span><span>')}</span>`;
      }
      return useTolgee().value?.t('struct-is-not-selected', { ns: 'navigation' });
    });

    const goToStructConfirmation = (node: { id: number, label: string }) => {
      const unit = StructList.structs.value.find((s) => s.id === node.id);
      if (unit?.isSelectable) {
        confirmStructChange.value.value = node;
        confirmStructChange.value.active = true;
      }
    };

    const goToStruct = (node: { id: number, label: string }) => {
      if (node.id === structId.value) return;
      const unit = StructList.structs.value.find((s) => s.id === node.id);
      if (unit?.isSelectable) {
        window.location.href = `/app/struct/${node.id}`;
      }
    };

    return {
      searchStruct,
      StructList,
      selectUnit,
      title,
      contract,
      structTree,
      structTreeProps,
      filterNode,
      goToStruct,
      goToStructConfirmation,
      confirmStructChange,
      allTags,
      drawer,
      treeRef,
      filterTags,
      user,
      structId,
    };
  },
});
</script>
