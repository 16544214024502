<template lang="pug" src="./NavigationSeason.pug"/>
<style lang="scss" src="./NavigationSeason.scss"/>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useBilling } from '@/composables/useBilling';
import { useUser } from '@/composables/useUser';
import SeasonList from '@/modules/season/SeasonList';
import NavigationSeasonItem
  from '@/modules/navigation/ui/NavigationSeason/NavigationSeasonItem.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import SeasonForm from '@/modules/season/ui/SeasonForm/SeasonForm.vue';
import { SeasonModel } from '@/models/season/SeasonModel';

export default defineComponent({
  name: 'NavigationSeason',
  components: {
    UiIcon,
    NavigationSeasonItem,
    SeasonForm,
  },
  setup() {
    const { contract } = useBilling();
    const { user } = useUser();
    const drawer = ref(false);
    const isOpenNewSeason = ref(false);
    const isOpenEditSeason = ref(false);
    const editSeason = ref<SeasonModel>();
    const _seasonList = ref();

    const openEditDialog = (season: SeasonModel) => {
      isOpenEditSeason.value = true;
      editSeason.value = season;
    };

    return {
      SeasonList,
      contract,
      drawer,
      user,
      isOpenNewSeason,
      isOpenEditSeason,
      editSeason,
      openEditDialog,
      _seasonList,
    };
  },
});
</script>
