<template lang="pug" src="./NoData.pug"/>
<style lang="scss" src="./NoData.scss"/>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({

  props: {
    message: {
      type: String,
    },
  },
  name: 'NoData',
});
</script>
