import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { taskMapBaseSourceDef } from '@/models/map/Layers/defs/TaskMapBaseDef';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import {
  taskMapContourLabelsLayerDef,
  taskMapContourLayerDef,
  taskMapContourLinesLayerDef,
} from '@/models/map/Layers/defs/TaskMapContourDef';
import { FeatureSelector, GeoJSONSource } from 'mapbox-gl';
import EventBus from '@/services/eventBus/EventBus';
import { isNumber } from '@/utils/isNumber';

export class MapLayerTaskMapModel extends MapLayerModel implements IMapLayerModel {
  readonly data: FieldTaskMapBaseModel | FieldTaskMapWorkModel;

  private hoveredPolygonId: number | null = null;


  constructor(mapModel: MapModel, type: MapLayerTypeEnum, layerName: string, uuid: string, data: FieldTaskMapBaseModel | FieldTaskMapWorkModel) {
    super(mapModel, type, layerName, uuid);
    this.data = data;
    setTimeout(() => {
      this.init();
    });
  }

  private _fillSettings: 0 | 1 | 2 = 2;

  get fillSettings(): 0 | 1 | 2 {
    return this._fillSettings;
  }

  async init(): Promise<void> {
    if (!this.data.geojson?.features) {
      await this.data.fetchData();
    }
    if (this._mapModel?.map) {
      this._mapModel.map
        .addSource(this.sourceId,
          {
            type: 'geojson',
            data: taskMapBaseSourceDef(this.data),
          })
        .addLayer(taskMapContourLayerDef(this.sourceId, this.layerId))
        .moveLayer(this.layerId, MapAnchorEnum.TASK_MAP)
        .addLayer(taskMapContourLinesLayerDef(this.sourceId, `contour-${this.layerId}`))
        .moveLayer(`contour-${this.layerId}`, MapAnchorEnum.TASK_MAP)
        .addLayer(taskMapContourLabelsLayerDef(this.sourceId, `labels-${this.layerId}`))
        .moveLayer(`labels-${this.layerId}`, MapAnchorEnum.TASK_MAP);
      this.layerIds.push(...[this.layerId, `contour-${this.layerId}`, `labels-${this.layerId}`]);
      this.sourceIds.push(this.sourceId);
      this.setOpacity(this.data.opacity);
    }

    EventBus.$on(`${this.data.uuid}-set-opacity`, (v) => {
      if (isNumber(v) && v >= 0 && v <= 100) {
        this.setOpacity(v);
      }
    });
    this._mapModel?.map?.on('mousemove', this.layerId, (e) => {
      if (this._fillSettings === 1 && (e.features || []).length > 0) {
        const id = Number(e.features[0].id);
        if (this.hoveredPolygonId !== id) {
          this._mapModel?.map?.setFeatureState(
            { source: this.sourceId, id: this.hoveredPolygonId },
            { hover: false },
          );
          this._mapModel?.map?.setFeatureState(
            { source: this.sourceId, id } as FeatureSelector,
            { hover: true },
          );
          this.hoveredPolygonId = id || null;
        }
      }
    });
    this._mapModel?.map?.on('mouseleave', this.layerId, () => {
      if (this._fillSettings === 1 && this.hoveredPolygonId !== null) {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: this.hoveredPolygonId } as FeatureSelector,
          { hover: false },
        );
      }
      this.hoveredPolygonId = null;
    });
  }

  setSettingsFill(fill: 0 | 1 | 2) {
    this._fillSettings = fill;
    if (this.data?.geojson) {
      this.data.geojson.features.forEach((f) => {
        this._mapModel?.map?.setFeatureState(
          { source: this.sourceId, id: Number(f.properties?.id) },
          {
            inactive: fill === 0,
            active: fill === 2,
          },
        );
      });
    }

    this._mapModel?.map?.setPaintProperty(this.layerId, 'fill-opacity', ['case',
      ['boolean', ['feature-state', 'inactive'], false], 0,
      ['boolean', ['feature-state', 'active'], false], this.data.opacity / 100,
      ['boolean', ['feature-state', 'hover'], false], this.data.opacity / 100,
      0,
    ]);
    this.update();
  }

  update(): void {
    const source = this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource;
    if (source) {
      source.setData(taskMapBaseSourceDef(this.data));
    }
  }
}
