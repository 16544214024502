export const jsonToTable = (jsonData) => {
  const table = document.createElement('table');
  for (const key in jsonData) {
    const row = table.insertRow();
    const cellKey = row.insertCell(0);
    const cellValue = row.insertCell(1);
    cellKey.textContent = key;
    cellValue.textContent = jsonData[key];
  }
  return table.outerHTML;
};
