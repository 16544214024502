import { Position } from 'geojson';
import { crossProduct } from '@/lib/map/crossProduct';

const getLinesIntersection = (A: Position, B: Position, C: Position, D: Position): Position | null => {
  const d1 = crossProduct(C, D, A);
  const d2 = crossProduct(C, D, B);
  const d3 = crossProduct(A, B, C);
  const d4 = crossProduct(A, B, D);

  if (d1 * d2 < 0 && d3 * d4 < 0) {
    // Найдем точку пересечения
    const t = d1 / (d1 - d2);
    const x = A[0] + t * (B[0] - A[0]);
    const y = A[1] + t * (B[1] - A[1]);
    return [x, y];
  }
  return null;
};
export default getLinesIntersection;
