import {
  Feature, LineString, MultiPolygon, Polygon,
} from 'geojson';
import {
  booleanPointInPolygon, lineString, multiPolygon, point, polygon,
} from '@turf/turf';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import { splitPolygonByLine } from '@/lib/map/splitPolygonByLine';
import { sliceLineStringByCoords } from '@/lib/map/sliceLineStringByCoords';
import { sortCoordinatesByLine } from '@/lib/map/sortCoordinatesByLine';
import { getOutsideSegmentIndexes } from '@/lib/map/getOutsideSegmentIndexes';

const trimOutsideSegments = (lineCoords: number[][], outsideSegmentIndexes: number[]) => {
  if (outsideSegmentIndexes.length === 0) {
    return [...lineCoords];
  }

  const start = outsideSegmentIndexes[0];
  const end = outsideSegmentIndexes[outsideSegmentIndexes.length - 1];
  return lineCoords.slice(start, end + 1);
};

const findValidMidpoints = (_lineCoords, _poly, left, right, successArray) => {
  if (left >= right) return;

  // Находим середину между двумя точками
  const [x1, y1] = _lineCoords[left];
  const [x2, y2] = _lineCoords[right];
  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;
  const startMid = [midX, midY];

  const prevLine = sliceLineStringByCoords(_lineCoords[left], startMid, lineString(_lineCoords));
  const nextLine = sliceLineStringByCoords(startMid, _lineCoords[right], lineString(_lineCoords));

  const prevIntersections = findCurveIntersections(lineString(_poly[0]), prevLine);
  const nextIntersections = findCurveIntersections(lineString(_poly[0]), nextLine);

  if (prevIntersections.length === 2) {
    successArray.push(startMid);
  }

  if (successArray.length * 2 === prevIntersections.length && nextIntersections.length === 2) {
    return;
  }

  // Рекурсивно проверяем левую и правую половину отрезка
  findValidMidpoints(_lineCoords, _poly, left, Math.floor((left + right) / 2), successArray);
  findValidMidpoints(_lineCoords, _poly, Math.floor((left + right) / 2), right, successArray);
};

export const splitPolygonByCurve = (polygonFeature: Feature<Polygon>, curve: Feature<LineString>): Feature<MultiPolygon> => {
  let lineCoords = [...curve.geometry.coordinates];
  const polygonStack = [];

  const polygonQueue = [[...polygonFeature.geometry.coordinates]];
  while (polygonQueue.length !== 0) {
    const poly = polygonQueue.shift()!;
    let outsideSegmentIndexes = getOutsideSegmentIndexes(lineCoords, poly);
    const isAllOutside = outsideSegmentIndexes.every((v, i, arr) => i === arr.length - 1 || v + 1 === arr[i + 1]);

    lineCoords = trimOutsideSegments(lineCoords, outsideSegmentIndexes);
    outsideSegmentIndexes = getOutsideSegmentIndexes(lineCoords, poly);

    const isLineIntersect = lineCoords.length < 2 ? [] : findCurveIntersections(lineString(poly[0]), lineString(lineCoords));

    if (lineCoords.length < 2 || outsideSegmentIndexes.length < 2 || (isLineIntersect.length === 0 && isAllOutside)) {
      polygonStack.push(poly);
      continue;
    }

    let firstDisjointIndex = outsideSegmentIndexes.findIndex((value, index, array) => {
      if (index === 0) return false;
      return value !== array[index - 1] + 1;
    });

    firstDisjointIndex = firstDisjointIndex === -1 ? lineCoords.length - 1 : firstDisjointIndex;
    let _intersectLine = sliceLineStringByCoords(lineCoords[0], lineCoords[firstDisjointIndex], lineString(lineCoords));
    let intersectLength = findCurveIntersections(_intersectLine, lineString(poly[0]));

    if (firstDisjointIndex === 1 && intersectLength.length > 2) {
      const successArrayPosition = [];
      findValidMidpoints(lineCoords, poly, 0, lineCoords.length - 1, successArrayPosition);

      lineCoords.splice(1, 0, ...successArrayPosition);
      outsideSegmentIndexes = getOutsideSegmentIndexes(lineCoords, poly);
      firstDisjointIndex = outsideSegmentIndexes.findIndex((value, index, array) => {
        if (index === 0) return false;
        return value !== array[index - 1] + 1;
      });
      firstDisjointIndex = firstDisjointIndex === -1 ? lineCoords.length - 1 : firstDisjointIndex;
      _intersectLine = sliceLineStringByCoords(lineCoords[0], lineCoords[firstDisjointIndex], lineString(lineCoords));
      intersectLength = findCurveIntersections(_intersectLine, lineString(poly[0]));
    }
    while (!(intersectLength.length <= 2)) {
      _intersectLine = sliceLineStringByCoords(lineCoords[0], lineCoords[firstDisjointIndex], lineString(lineCoords));
      intersectLength = findCurveIntersections(_intersectLine, lineString(poly[0]));
      if (intersectLength.length <= 2) {
        break;
      }
      firstDisjointIndex--;
    }

    const segmentIndexes = outsideSegmentIndexes.splice(0, firstDisjointIndex + 1);
    const segmentBounds = [segmentIndexes[0], segmentIndexes[segmentIndexes.length - 1] + 1];

    const segment = lineCoords.slice(...segmentBounds);

    const newPolygons = splitPolygonByLine(polygon(poly), lineString(segment)).geometry.coordinates;
    newPolygons.forEach((_polygon) => {
      polygonQueue.push(_polygon);
    });
    lineCoords.splice(0, segment.length - 1);
  }
  return multiPolygon(polygonStack);
};
