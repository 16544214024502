import LoggerService from '@/services/logger/LoggerService';
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  MultiPolygon,
  Point,
  Polygon,
  Position,
} from 'geojson';
import { point } from '@turf/turf';

export const polygonToPoints = (
  poly: Feature<Polygon | MultiPolygon, GeoJsonProperties> | Polygon | MultiPolygon,
  options: Record<string, any> = {},
): FeatureCollection<Point, GeoJsonProperties> => {
  const points: FeatureCollection<Point, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: [],
  };

  if ('geometry' in poly) {
    poly = poly.geometry;
  }

  if (poly.type === 'Polygon' || poly.type === 'MultiPolygon') {
    const polygons: Position[][][] = poly.type === 'Polygon' ? [poly.coordinates] : poly.coordinates;

    polygons.forEach((polygon: Position[][], polygonIndex: number) => {
      polygon.forEach((ring: Position[], ringIndex: number) => {
        // Проходим по всем точкам в кольце, кроме последней (замыкающей)
        ring.forEach((pointCoord: Position, pointIndex: number) => {
          if (pointIndex !== ring.length - 1) {
            points.features.push(point(pointCoord, {
              ...options,
              index: `${polygonIndex}_${ringIndex}_${pointIndex}`,
            }));
          }
        });
      });
    });
  } else {
    // @ts-ignore
    LoggerService.error(`polygonToPoints error: Unsupported geometry type ${poly.type}`);
  }

  return points;
};
