<template lang="pug" src="./FieldDrawTool.pug"/>
<style lang="scss" src="./FieldDrawTool.scss"/>

<script lang="ts">
import {
  defineComponent, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { MapMouseEvent } from 'mapbox-gl';
import EventBus from '@/services/eventBus/EventBus';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { useDrawer } from '@/composables/useDrawer';
import { MapLayerLineModel } from '@/models/map/Layers/MapLayerLineModel';
import { lineString } from '@turf/turf';
import { splitMultiPolygonByLine } from '@/lib/map/splitMultiPolygonByLine';
import { reshapePolygon } from '@/lib/map/reshapePolygon';
import { LineStringModel } from '@/models/geojson/LineStringModel';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import WikiContent from '@/components/shared/WikiContent/WikiContent.vue';
import { Position } from 'geojson';

export default defineComponent({
  name: 'FieldDrawTool',
  components: {
    UiIcon,
    WikiContent,
  },
  setup() {
    const { mapModel } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const {
      activeAction,
      cutDifference,
      drawerModel,
      drawerLayer,
      deleteHole,
      deletePolygon,
      union,
      activeHistoryIndex,
      historyCoords,
      isHavePolygon,
    } = useDrawer();
    const magnetActive = ref(false);
    const isBlockDrag = ref(false);
    const lineModel = ref<LineStringModel>();
    const lineLayer = ref<MapLayerLineModel>();
    const bufferDrawerLayerCoordinates = ref<Position[][][]>();

    watch(activeAction, (a, b) => {
      if (['addRing', 'addPolygon'].includes(a)) {
        // layerModel.value.setActiveMode('create');
        lineModel.value !== undefined && mapModel.value.removeLayer(MapLayerTypeEnum.LINE);
        bufferDrawerLayerCoordinates.value = drawerLayer.value.cordsDrawer;
      } else if (['cut', 'outsideInside'].includes(a)) {
        if (a === b) {
          lineModel.value !== undefined && mapModel.value.removeLayer(MapLayerTypeEnum.LINE);
          return;
        }
        if (lineModel.value === undefined) {
          lineModel.value = new LineStringModel([]);
          lineLayer.value = mapModel.value.render(lineModel.value) as MapLayerLineModel;
        }
      } else if (drawerLayer.value.activeMode !== 'edit') {
        drawerLayer.value.setActiveMode('edit');
        lineModel.value !== undefined && mapModel.value.removeLayer(MapLayerTypeEnum.LINE);
      }
      if (['cut', 'outsideInside'].includes(b) && !['cut', 'outsideInside'].includes(a)) {
        lineLayer.value.removeSubscribeEvents();
        mapModel.value.removeLayer(lineModel.value.uuid);
        lineModel.value = undefined;
      }
    });

    watch(magnetActive, (a) => {
      drawerLayer.value.magnetActive = a;
    });

    const setAction = (action: ''|'addRing'|'addPolygon'|'delete'|'cut'|'outsideInside') => {
      if (action === activeAction.value) {
        activeAction.value = '';
      } else {
        activeAction.value = action;
      }
    };

    const toggleBlockDrag = () => {
      isBlockDrag.value = !isBlockDrag.value;
      if (isBlockDrag.value) {
        mapModel.value.map.dragPan.disable();
      } else {
        mapModel.value.map.dragPan.enable();
      }
    };

    const pointInRing = (e: MapMouseEvent) => {
      const { linearRingIndex, geometryIndex } = drawerLayer.value?.findGeometryAndRing(e);
      switch (activeAction.value) {
      case 'addRing':
        if (drawerLayer.value.activeMode !== 'create') {
          drawerLayer.value.indexPolygon = geometryIndex;
          drawerLayer.value.indexLinearRing = drawerModel.value.getPolygon(geometryIndex).data.length;
          drawerModel.value.getPolygon(geometryIndex).addLinearRing([]);
          drawerLayer.value.setActiveMode('create');
        }
        break;
      case 'addPolygon':
        if (drawerLayer.value.activeMode !== 'create') {
          drawerLayer.value.indexPolygon = drawerModel.value.data.length;
          drawerModel.value.addPolygon([[]]);
          drawerLayer.value.setActiveMode('create');
        }
        break;
      case 'delete':
        if (linearRingIndex) {
          deleteHole(linearRingIndex, geometryIndex);
        } else if (geometryIndex !== null) {
          deletePolygon(geometryIndex);
        }
        EventBus.$emit('drawerLogType', 'delete');
        break;
      case 'outsideInside':
        //
        break;
      default:
        //
      }
    };

    const handlerMouseDown = (e: MapMouseEvent) => {
      if (drawerLayer.value) {
        pointInRing(e);
      }
    };
    mapModel.value.map.on('mousedown', handlerMouseDown);

    mapModel.value.events.onUpdateDrawerMode((e) => {
      if (e === 'edit') {
        activeAction.value = '';
      }
    });
    const spaseHandler = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        if (activeAction.value === 'cut') {
          const lines = lineModel.value.toFeature().geometry.coordinates;

          drawerModel.value.updatePolygons(splitMultiPolygonByLine(drawerModel.value.toFeature(), lineString(lines)).geometry.coordinates);
          EventBus.$emit('drawerLogType', 'cut');
        }
        if (activeAction.value === 'outsideInside') {
          const lines = lineModel.value.toFeature().geometry.coordinates;

          const intersectPolygon = drawerModel.value.data.map((a) => findCurveIntersections(lineString(a.toFeature().geometry.coordinates[0]), lineModel.value.toFeature()).length > 0);
          const intersectPolygonLength = intersectPolygon.filter(Boolean).length;
          if (intersectPolygonLength === 1) {
            const intersectIndex = intersectPolygon.findIndex((a) => a);
            const intersectResult = reshapePolygon(drawerModel.value.getPolygon(intersectIndex).toFeature(), lineString(lines)).geometry.coordinates;
            drawerModel.value.getPolygon(intersectIndex).update(intersectResult);
            EventBus.$emit('drawerLogType', 'reshape');
          }
        }
        if (activeAction.value === 'addPolygon') {
          drawerLayer.value.stopDraw();
        }
        if (activeAction.value === 'addRing') {
          drawerLayer.value.stopDraw();
        }

        drawerLayer.value.refreshPolygin();
        lineModel.value.update([]);
        lineLayer.value.refreshLayer();
      }
    };

    const escHandler = (e: KeyboardEvent) => {
      if (e.key === 'esc' || e.key === 'Escape') {
        if (['addPolygon', 'addRing'].includes(activeAction.value)) {
          drawerModel.value.updatePolygons(bufferDrawerLayerCoordinates.value);
        }

        if (['outsideInside', 'cut'].includes(activeAction.value)) {
          lineModel.value !== undefined && mapModel.value.removeLayer(MapLayerTypeEnum.LINE);
        }
        drawerLayer.value.setActiveMode('edit');
        setAction('');
      }
    };

    const undoRedoHandler = (e: KeyboardEvent) => {
      if (e.code === 'KeyZ' && e.ctrlKey) {
        (activeHistoryIndex.value > 0) && activeHistoryIndex.value--;
      } if (e.code === 'KeyZ' && e.shiftKey && e.ctrlKey) {
        (activeHistoryIndex.value !== historyCoords.value.length - 1 || historyCoords.value.length === 0) && activeHistoryIndex.value++;
      }
    };

    document.addEventListener('keydown', spaseHandler);
    document.addEventListener('keydown', undoRedoHandler);
    document.addEventListener('keydown', escHandler);

    onMounted(() => {
      drawerLayer.value = mapModel.value.getLayer(MapLayerTypeEnum.AREA) as MapLayerDrawerModel;
      drawerModel.value = drawerLayer.value?.data;
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', spaseHandler);
      document.removeEventListener('keydown', undoRedoHandler);
      document.removeEventListener('keydown', escHandler);
      if (lineModel.value) {
        lineLayer.value.removeSubscribeEvents();
        mapModel.value.removeLayer(lineModel.value?.uuid);
        activeAction.value = '';
      }
      mapModel.value.map.off('mousedown', handlerMouseDown);
      mapModel.value.map.dragPan.enable();
    });

    return {
      activeAction,
      setAction,
      drawerLayer,
      magnetActive,
      cutDifference,
      isBlockDrag,
      toggleBlockDrag,
      union,
      activeHistoryIndex,
      historyCoords,
      isHavePolygon,
    };
  },
});
</script>
