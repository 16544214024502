<template lang="pug" src="./BaseMapEditor.pug"/>
<style lang="scss" src="./BaseMapEditor.scss"/>

<script lang="ts">
import { BaseMapColors } from '@/assets/data/BaseMapColors';
import { useBaseMapEditor } from '@/composables/baseMap/useBaseMapEditor';
import { useImageEditorHistory } from '@/composables/imageEditor/useImageEditorHistory';
import { useCreateBaseMap } from '@/composables/useCreateBaseMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { useUnload } from '@/composables/useUnload';
import { BASE_MAP_IMAGE_SCALE_SIZE } from '@/constants/constants/constants';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { canvasImageDataToZones } from '@/lib/tiff/canvasImageDataToZones';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { MapLayerCanvasModel } from '@/models/map/Layers/MapLayerCanvasModel';
import EventBus from '@/services/eventBus/EventBus';
import { BrushFilled, Grid, Pointer } from '@element-plus/icons-vue';
import { CanvasSource, MapMouseEvent } from 'mapbox-gl';
import {
  computed, defineComponent, onBeforeUnmount, onMounted, ref, watch, watchEffect,
} from 'vue';

export default defineComponent({
  name: 'BaseMapEditor',
  components: {
    Pointer,
    Grid,
    BrushFilled,
  },
  setup() {
    const {
      initHistory,
      addToHistory,
      undo,
      redo,
      reset,
      undoAvailable,
      redoAvailable,
      resetAvailable,
    } = useImageEditorHistory();
    const {
      canvasEditor,
      canvasEditorCtx,
      canvasSnap,
      canvasSnapCtx,
      canvasCursorSquare,
      canvasCursorSquareCtx,
      activeCandidate,
      activeGrid,
      snapLayer,
      cursorLayer,
      editorLayer,
      opacity,
    } = useCreateBaseMap();

    const {
      activeTool,
    } = useBaseMapEditor();

    const {
      fitField,
      activeField,
      mapModel,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);

    const busy = ref(false);

    const width = ref();
    const height = ref();

    const brushing = ref({
      active: false,
      size: 10,
    });

    const activeZone = ref(1);

    const canvasTypes = [
      {
        id: 'CreateBaseMapCanvas-editor', refs: { canvas: canvasEditor, ctx: canvasEditorCtx, layer: editorLayer }, map: mapModel,
      },
      {
        id: 'CreateBaseMapCanvas-snap', refs: { canvas: canvasSnap, ctx: canvasSnapCtx, layer: snapLayer }, map: mapModel,
      },
      {
        id: 'CreateBaseMapCanvas-cursorSquare', refs: { canvas: canvasCursorSquare, ctx: canvasCursorSquareCtx, layer: cursorLayer }, map: mapModel,
      },
    ];

    /** Сохранить изменения из canvas в модель изображения */
    const save = () => {
      if (activeCandidate.value?.taskImage && canvasEditorCtx.value) {
        const buffer = (canvasEditorCtx.value.getImageData(0, 0, width.value, height.value)).data;
        activeCandidate.value.taskImage.imageDataZoned = canvasImageDataToZones(buffer);
        activeCandidate.value.taskImage.clearPixelsOutsidePolygon();
        canvasTypes[0].refs.layer.value.updateImage();
      }
    };

    const removeLayers = () => {
      canvasTypes.forEach(({
        id, refs, map,
      }) => {
        map.value?.removeLayer(refs.layer.value.uuid);
        document.getElementById(id).outerHTML = '';
      });
    };

    /** Обновить изображение canvas при изменении данных в модели изображения */
    const updateImage = () => {
      if (canvasEditorCtx.value) {
        const buffer = (canvasEditorCtx.value.getImageData(0, 0, width.value || 0, height.value || 0)).data.buffer;
        const data = new Uint8ClampedArray(buffer);

        activeCandidate.value.taskImage.imageDataZoned.forEach((pxl: number, i: number) => {
          data[i * 4] = BaseMapColors[pxl][0];
          data[i * 4 + 1] = BaseMapColors[pxl][1];
          data[i * 4 + 2] = BaseMapColors[pxl][2];
          data[i * 4 + 3] = BaseMapColors[pxl][3];
        });
        canvasEditorCtx.value.putImageData(new ImageData(data, width.value, height.value), 0, 0);
      }
    };

    EventBus.$on('BaseMap-editor-update-image', () => {
      updateImage();
      canvasTypes[0].refs.layer.value.updateImage();
    });

    EventBus.$on('BaseMap-editor-remove-layers', removeLayers);

    // region helpers functions
    const getCursorPosition = (evt: MapMouseEvent) => {
      if (activeCandidate.value?.taskImage) {
        const pos = {
          x: 0,
          y: 0,
        };
        const cBbox = activeCandidate.value?.taskImage.bbox;
        const boxWidth = cBbox[2] - cBbox[0];
        const boxHeight = cBbox[3] - cBbox[1];
        const offsetX = ((evt.lngLat.lng - cBbox[0]) / boxWidth) * width.value;
        if (offsetX < 0 || offsetX > width.value) {
          return undefined;
        }
        pos.x = Math.round(offsetX);

        if (activeGrid.value) {
          pos.x = Math.floor(pos.x / brushing.value.size) * brushing.value.size + brushing.value.size / 2;
        }
        const offsetY = ((cBbox[3] - evt.lngLat.lat) / boxHeight) * height.value;
        if (offsetY < 0 || offsetY > height.value) {
          return undefined;
        }
        pos.y = Math.round(offsetY);

        if (activeGrid.value) {
          pos.y = Math.floor(pos.y / brushing.value.size) * brushing.value.size + brushing.value.size / 2;
        }
        return pos;
      }
      return undefined;
    };

    const getPixel = (id: Uint8ClampedArray, x: number, y: number, cw: number) => {
      const pos = ((y * cw) + x) * 4;
      return [id[pos], id[pos + 1], id[pos + 2], id[pos + 3]];
    };

    const setColor = (img: ImageData, i: number, color: number[]) => {
      img.data[(i * 4)] = color[0];
      img.data[(i * 4) + 1] = color[1];
      img.data[(i * 4) + 2] = color[2];
      img.data[(i * 4) + 3] = color[3];
    };
    // endregion

    watchEffect(() => {
      if (snapLayer.value !== undefined) {
        const _opacity = activeTool.value === 'brush' && activeGrid.value ? 1 : 0;
        mapModel.value?.map?.setPaintProperty(
          snapLayer.value?.layerId || '',
          'raster-opacity',
          _opacity,
        );
      }
    });

    const drawSnap = () => {
      if (canvasSnap.value && canvasSnapCtx.value) {
        canvasSnapCtx.value.clearRect(0, 0, canvasSnap.value.width, canvasSnap.value.height);

        const bw = Math.floor(canvasSnap.value.width / brushing.value.size) * brushing.value.size;
        const bh = Math.floor(canvasSnap.value.height / brushing.value.size) * brushing.value.size;
        canvasSnapCtx.value.beginPath();

        for (let x = 0; x <= bw; x += brushing.value.size) {
          canvasSnapCtx.value.moveTo(0.25 + x, 0);
          canvasSnapCtx.value.lineTo(0.25 + x, canvasSnap.value.height);
        }
        for (let y = 0; y <= bh; y += brushing.value.size) {
          canvasSnapCtx.value.moveTo(0, 0.25 + y);
          canvasSnapCtx.value.lineTo(bw, 0.25 + y);
        }
        canvasSnapCtx.value.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        canvasSnapCtx.value.lineWidth = 0.5;
        canvasSnapCtx.value.stroke();
        if (activeCandidate.value?.taskImage && mapModel.value?.map) {
          const cBbox = activeCandidate.value.taskImage.bbox;
          const source = (mapModel.value?.map.getSource(snapLayer.value?.sourceId || '') as CanvasSource);
          if (source) source.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);

          const sourceCursor = (mapModel.value?.map.getSource(cursorLayer.value?.sourceId || '') as CanvasSource);
          if (sourceCursor) sourceCursor.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);
        }
      }
    };

    watch(activeTool, () => {
      drawSnap();
    });

    watch(() => brushing.value.size, () => {
      drawSnap();
    });

    const initImageData = () => {
      if (activeCandidate.value?.taskImage?.zones
        && activeCandidate.value.taskImage.zones.length
        && canvasEditor.value
        && canvasSnap.value
        && canvasCursorSquare.value
      ) {
        width.value = activeCandidate.value.taskImage.scaledWidth;
        height.value = activeCandidate.value.taskImage.scaledHeight;
        activeZone.value = activeCandidate.value.taskImage.zones[0].value;
        canvasEditor.value.width = width.value;
        canvasEditor.value.height = height.value;
        canvasSnap.value.width = width.value;
        canvasSnap.value.height = height.value;
        canvasCursorSquare.value.width = width.value;
        canvasCursorSquare.value.height = height.value;
        drawSnap();
      }
    };

    const brushID = computed(() => {
      const id = new ImageData(brushing.value.size, brushing.value.size);
      for (let i = 0; i < brushing.value.size ** 2; i++) {
        setColor(id, i, BaseMapColors[activeZone.value]);
      }
      return id;
    });

    // region Brush tool
    const brushBeforePos = ref();

    const applyBrush = (x: number, y: number) => {
      if (canvasEditorCtx.value) {
        canvasEditorCtx.value.putImageData(brushID.value, x - brushing.value.size / 2, y - brushing.value.size / 2);
      }
    };

    const brush = (event: MapMouseEvent) => {
      const _pos = getCursorPosition(event);
      if (_pos) {
        applyBrush(_pos.x, _pos.y);
        if (brushBeforePos.value && canvasEditorCtx.value && !activeGrid.value) {
          const bx = brushBeforePos.value.x;
          const by = brushBeforePos.value.y;

          const dx = _pos.x - bx;
          const dy = _pos.y - by;

          const s = Math.max(Math.abs(dx), Math.abs(dy));
          const ix = dx === 0 ? 0 : Math.min(Math.abs(dx) / Math.abs(dy), 1);
          const iy = dy === 0 ? 0 : Math.min(Math.abs(dy) / Math.abs(dx), 1);

          for (let d = 1; d < s; d++) {
            const sdx = dx > 0 ? ix : -1 * ix;
            const sdy = dy > 0 ? iy : -1 * iy;
            const mx = Math.round(bx + d * sdx);
            const my = Math.round(by + d * sdy);
            applyBrush(mx, my);
          }
        }
        brushBeforePos.value = { x: _pos.x, y: _pos.y };
      }
    };
    // endregion

    // region Fill tool
    const fill = (evt: MapMouseEvent) => {
      if (!busy.value) {
        busy.value = true;
        const cursorPos = getCursorPosition(evt);
        const cw = canvasEditor.value?.width || 0;
        const ch = canvasEditor.value?.height || 0;
        if (canvasEditorCtx.value && cursorPos) {
          const id = canvasEditorCtx.value.getImageData(0, 0, cw, ch);

          const initX = cursorPos.x;
          const initY = cursorPos.y;
          const pixel = getPixel(id.data, initX, initY, cw);
          const color = BaseMapColors[activeZone.value];
          if (pixel.join(',') === color.join(',')) {
            busy.value = false;
            return;
          }

          if (pixel.join(',') === BaseMapColors[0].join(',')) {
            busy.value = false;
            return;
          }

          const r = pixel[0];
          const g = pixel[1];
          const b = pixel[2];
          const stack = new Set();

          const getNearMatches = (x: number, y: number) => {
            [[x - 1, y], [x, y - 1], [x + 1, y], [x, y + 1]].forEach((coords) => {
              const _x = coords[0];
              const _y = coords[1];
              const hash = `${_x}:${_y}`;
              if (!stack.has(hash)) {
                if (_x >= 0 && _x <= cw && _y >= 0 && _y <= ch && canvasEditorCtx.value) {
                  const _pixel = getPixel(id.data, _x, _y, cw);
                  if (_pixel[0] === r && _pixel[1] === g && _pixel[2] === b) {
                    stack.add(hash);
                  }
                }
              }
            });
            stack.delete(`${x}:${y}`);

            const pos = ((y * cw) + x) * 4;
            id.data[pos] = color[0];
            id.data[pos + 1] = color[1];
            id.data[pos + 2] = color[2];
          };

          getNearMatches(initX, initY);

          let limitSteps = 1000000;

          do {
            // eslint-disable-next-line no-restricted-syntax
            for (const key of stack.values()) {
              // @ts-ignore
              const [_x, _y] = key.split(':');
              getNearMatches(Number(_x), Number(_y));
              limitSteps--;
            }
          } while (stack.size > 0 && limitSteps > 0);

          canvasEditorCtx.value?.putImageData(id, 0, 0);
        }
        if (canvasEditor.value && canvasEditorCtx.value) {
          addToHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
        }
        save();
        busy.value = false;
      }
    };
    // endregion

    // Init History
    const initImage = () => {
      activeZone.value = activeCandidate.value?.taskImage?.zones[0].indexColor || 0;
      if (mapModel.value && canvasEditorCtx.value && canvasEditor.value && activeCandidate.value?.taskImage?.imageDataZoned) {
        const buffer = (canvasEditorCtx.value.getImageData(0, 0, width.value || 0, height.value || 0)).data.buffer;

        const data = new Uint8ClampedArray(buffer);

        activeCandidate.value.taskImage.imageDataZoned.forEach((pxl: number, i: number) => {
          if (pxl > 0) {
            data[i * 4] = BaseMapColors[pxl][0];
            data[i * 4 + 1] = BaseMapColors[pxl][1];
            data[i * 4 + 2] = BaseMapColors[pxl][2];
            data[i * 4 + 3] = BaseMapColors[pxl][3];
          } else {
            data[i * 4] = BaseMapColors[pxl][0];
            data[i * 4 + 1] = BaseMapColors[pxl][1];
            data[i * 4 + 2] = BaseMapColors[pxl][2];
            data[i * 4 + 3] = BaseMapColors[pxl][3];
          }
        });

        const imageData = new ImageData(data, width.value, height.value);

        canvasEditorCtx.value.putImageData(imageData, 0, 0);

        if (activeCandidate.value?.taskImage && mapModel.value?.map) {
          const cBbox = activeCandidate.value.taskImage.bbox;
          const source = (mapModel.value?.map.getSource(editorLayer.value?.sourceId || '') as CanvasSource);
          if (source) source.setCoordinates([[cBbox[0], cBbox[3]], [cBbox[2], cBbox[3]], [cBbox[2], cBbox[1]], [cBbox[0], cBbox[1]]]);
        }
        initHistory(imageData);
      }
    };
    // endregion

    const doUndo = () => {
      if (canvasEditorCtx.value && canvasEditor.value) {
        undo(canvasEditorCtx.value, canvasEditor.value);
        save();
      }
    };

    const doRedo = () => {
      if (canvasEditorCtx.value) {
        redo(canvasEditorCtx.value);
        save();
      }
    };

    const doReset = () => {
      if (canvasEditorCtx.value) {
        reset(canvasEditorCtx.value);
        save();
      }
    };

    let previousSquareCoords: { x: number, y: number } = { x: 0, y: 0 };

    const dragSquare = (event: MapMouseEvent) => {
      const cursorPos = getCursorPosition(event);
      if (canvasCursorSquareCtx.value && canvasCursorSquare.value && cursorPos === undefined) {
        canvasCursorSquareCtx.value.clearRect(0, 0, canvasCursorSquare.value.width, canvasCursorSquare.value.height);
        return;
      }
      if (canvasCursorSquareCtx.value && canvasCursorSquare.value && cursorPos) {
        if (previousSquareCoords) {
          const { x, y } = previousSquareCoords;
          canvasCursorSquareCtx.value.clearRect(x - 1, y - 1, BASE_MAP_IMAGE_SCALE_SIZE + 2, BASE_MAP_IMAGE_SCALE_SIZE + 2);
        }

        const squareX = cursorPos.x - BASE_MAP_IMAGE_SCALE_SIZE / 2;
        const squareY = cursorPos.y - BASE_MAP_IMAGE_SCALE_SIZE / 2;

        canvasCursorSquareCtx.value.strokeStyle = 'black';
        canvasCursorSquareCtx.value.strokeRect(squareX, squareY, BASE_MAP_IMAGE_SCALE_SIZE, BASE_MAP_IMAGE_SCALE_SIZE);

        previousSquareCoords = { x: squareX, y: squareY };
      }
    };

    const handlerMouseDown = (evt: MapMouseEvent) => {
      if (activeTool.value === 'brush' && !busy.value) {
        brushing.value.active = true;
        canvasTypes[0].refs.layer.value.play();
        brush(evt);
        evt.preventDefault();
      }
    };
    const handlerMouseMove = (evt: MapMouseEvent) => {
      // EventBus.$emit(EventsEnum.MapMouseMove, evt);
      if (activeTool.value === 'brush') {
        dragSquare(evt);
        if (brushing.value.active && !busy.value) {
          brush(evt);
        }
      }
    };

    const handelClick = (evt: MapMouseEvent) => {
      if (activeTool.value === 'fill' && !busy.value) {
        fill(evt);
        save();
      }
    };

    const handlerMouseUp = (evt: MapMouseEvent) => {
      if (brushing.value.active) {
        brushing.value.active = false;
        brushBeforePos.value = undefined;
        canvasTypes[0].refs.layer.value.pause();
        save();
        if (canvasEditor.value && canvasEditorCtx.value) {
          addToHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
        }
      }
    };

    onMounted(() => {
      activeTool.value = 'pointer';

      if (mapModel.value?.map) {
        mapModel.value?.map.on('mousedown', handlerMouseDown);
        mapModel.value?.map.on('mousemove', handlerMouseMove);
        mapModel.value?.map.on('click', handelClick);
        mapModel.value?.map.on('mouseup', handlerMouseUp);
      }
    });

    const handlerGeneralization = () => {
      addToHistory(canvasEditorCtx.value.getImageData(0, 0, canvasEditor.value.width, canvasEditor.value.height));
    };

    EventBus.$on('generalization', handlerGeneralization);

    const handlerOnKeyPressed = (evt: KeyboardEvent) => {
      if (evt.code.startsWith('Digit') && activeCandidate.value?.taskImage?.zones) {
        const key = Number(evt.key) - 1;
        if (activeCandidate.value.taskImage.zones.length >= key + 1) {
          activeZone.value = activeCandidate.value.taskImage.zones[key].indexColor;
        }
      }
      if (evt.code === 'KeyF') {
        activeTool.value = 'fill';
      }
      if (evt.code === 'KeyC') {
        activeTool.value = 'pointer';
      }
      if (evt.code === 'KeyB') {
        activeTool.value = 'brush';
      }
      if (evt.code === 'KeyZ' && evt.ctrlKey && !evt.shiftKey) {
        doUndo();
      }
      if (evt.code === 'KeyZ' && evt.ctrlKey && evt.shiftKey) {
        doRedo();
      }
    };

    EventBus.$on(EventsEnum.OnKeyPressed, handlerOnKeyPressed);

    const createCanvas = () => {
      canvasTypes.forEach(({
        id, refs, map,
      }, idx) => {
        const canvas = document.createElement('canvas');
        canvas.id = id;
        canvas.style.display = 'none';
        document.body.querySelector('.MapContainer-main-map')?.appendChild(canvas);

        const elementCanvas = new MapCanvasModel(id, [
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
          [Math.random(), Math.random()],
        ]);

        const layer = map.value?.render(elementCanvas) as MapLayerCanvasModel;
        refs.canvas.value = elementCanvas.canvas;
        refs.ctx.value = elementCanvas.ctx;
        refs.layer.value = layer;

        if (idx === 0) {
          layer.setOpacity(opacity.value);
        }
      });
    };

    const setBrushingSize = (size: number) => {
      brushing.value.size = size;
      snapLayer.value.updateImage();
    };

    onBeforeUnmount(removeLayers);

    onMounted(async () => {
      fitField(activeField.value);
      createCanvas();
      initImageData();
      initImage();
      mapModel.value?.map?.setPaintProperty(
        snapLayer.value?.layerId || '',
        'raster-opacity',
        1,
      );
    });

    watch(() => activeCandidate.value.taskImage.imageDataZoned, updateImage);

    watch(activeTool, (a) => {
      if (a === 'brush') {
        mapModel.value.map.getCanvas().style.cursor = 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAcxJREFUSEvV1EuoTXEUx/HPjaIYuAwUpSS6ZCaGBpgoBvKo61LkMSAhBgYeeQwNCANlwIBcj8ktSVFGBsy8klBEGShiYCCPVq2j3b53t7ezuwP/OnXOae/fb/1/67tWj1E+PaOs778wGIN+rMMbHMK3TjJtbhDC63EQcwpRn8WutgZzcRGLUuhZVr8St7CijcFqXMIEfMRu3MRjzMMGXO7WYAcigoj2KuL35+zBFbzAfPzsxmAbzqf4UcTnN6YgIpqKAYTR39O0yctwG2OxF6dSoXOTIOgBFherj2eaGEzGE0zDaewpFHg8KfqKBXhVHtwmBnHl4DziiEpvpMg+nMQvrMLQSFuhziCICcEviO+B5oHEM+gJ0+24ULVyqgxmYgs2YXoh96W4m2I/EI0PZCvPSAZbcQbjC29FtudwBJPwHhtxv25Zlg2W5yTG/4M5QCdKqyB6sjNjq9MfRtFzxBo4ltWGwBpcx4ckqNPkWvEyprPxEp8wA99T4RrWIpA83Ei1YtCW4B4eJSXjkvHYlmHWh3dtDBbiYWYbgxU7pTdR3FxHSxNMY6dEPMXzFPtx518r7zxfpihMZmEi3uJ1t8JVBm31hr1ftypaG/4BfnZXGfkpANcAAAAASUVORK5CYII=) 2 20, auto';
      } else if (a === 'fill') {
        mapModel.value.map.getCanvas().style.cursor = 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAASZJREFUSEvV1T0uRUEYxvHflShFRWcHVCISHQo2ILEFdkDPBoQdKNUSiU4htAo7IEq9kFfOSU5O5pyZuXILU00yM89/3ueZj4kZt8mM9ZUAjnCDj2k2kwMc4xIv2J0GkgMs4R5reMUO3moqyQFC60+QWkAAqyrJAZYbi1abHALQ9osyGQP0rdnGV20mQ4CU+HsTblUmKcCYeHuAiiF9wCIeCn3u55PMpA84xVnFxcpC+oATnNdcpM7c5G0fs6iG04ofYA4X7eJUyN2ySyCt+Aoe+X1At/AUi4eOaSmka8st9psd3WFvDBBjOUjf808sNIDox4nM/gdDkFSgYclGA4j+ZgkgVcnQ37COa8zjEM+lgJgX5cbn840rhAVFLfeaFomMTfr/gB/avFEZes7meQAAAABJRU5ErkJggg==) 16 16, auto';
      } else {
        mapModel.value.map.getCanvas().style.cursor = '';
      }
    });

    useUnload(() => {
      mapModel.value.map.getCanvas().style.cursor = '';
      mapModel.value?.map.off('mousedown', handlerMouseDown);
      mapModel.value?.map.off('mousemove', handlerMouseMove);
      mapModel.value?.map.off('click', handelClick);
      mapModel.value?.map.off('mouseup', handlerMouseUp);
      activeTool.value = 'pointer';

      EventBus.$off(EventsEnum.OnKeyPressed, handlerOnKeyPressed);
      EventBus.$off('generalization', handlerGeneralization);
    });

    watch(opacity, (v) => {
      if (editorLayer.value !== undefined) {
        editorLayer.value.setOpacity(v);
      }
    });

    return {
      activeCandidate,
      activeTool,
      width,
      height,
      busy,
      activeZone,
      undoAvailable,
      redoAvailable,
      resetAvailable,
      activeGrid,
      brushing,
      doUndo,
      doRedo,
      doReset,
      BaseMapColors,
      setBrushingSize,
    };
  },
});
</script>
