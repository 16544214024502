import {
  Feature, LineString, MultiPolygon, Polygon, Position,
} from 'geojson';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import { sliceLineStringByCoords } from '@/lib/map/sliceLineStringByCoords';
import { sortCoordinatesByLine } from '@/lib/map/sortCoordinatesByLine';
import { removeDuplicatesCordsByPolygon } from '@/lib/map/removeDuplicatesCordsByPolygon';
import {
  featureCollection,
  lineString,
  multiPolygon,
  polygon,
  booleanClockwise,
  difference,
} from '@turf/turf';

export const concatPolygonAndLine = (_polygon: Feature<Polygon>, line: Feature<LineString>, interser?: [Position, Position]): Feature<Polygon> => {
  const polygonCoords = booleanClockwise(_polygon.geometry.coordinates[0]) ? _polygon.geometry.coordinates[0] : [..._polygon.geometry.coordinates[0]].reverse();
  const lineCoords = booleanClockwise(line) ? line.geometry.coordinates : [...line.geometry.coordinates].reverse();

  const intersectLines = interser || findCurveIntersections(lineString(polygonCoords), lineString(lineCoords));

  const firstPoly = [[]];

  const sortCordsByPolygon = sortCoordinatesByLine(intersectLines, lineString(polygonCoords));
  console.log(polygonCoords);
  console.log(lineCoords);
  console.log(intersectLines);

  const _f1 = sliceLineStringByCoords(polygonCoords[0], sortCordsByPolygon[0], lineString(polygonCoords)).geometry.coordinates;

  const _f2 = sliceLineStringByCoords(intersectLines[0], intersectLines[1], lineString(lineCoords)).geometry.coordinates;

  const _f3 = sliceLineStringByCoords(polygonCoords[0], sortCordsByPolygon[1], lineString([...polygonCoords].reverse())).geometry.coordinates.reverse();

  console.log(_f1, _f2, _f3);

  if (_f1.at(-1)?.toString() !== _f2[0]?.toString()) {
    _f2.reverse();
  }

  if (_f2.at(-1)?.toString() !== _f3[0]?.toString()) {
    _f3.reverse();
  }

  firstPoly[0].push(..._f1);
  firstPoly[0].push(..._f2);
  firstPoly[0].push(..._f3);

  const holes = _polygon.geometry.coordinates.filter((a, index) => index !== 0);
  holes.forEach((hole) => {
    const holePoly = polygon([hole]);
    const _firstPoly = polygon([firstPoly[0]]);
    const feature1 = featureCollection([_firstPoly, holePoly]);
    const firstDiff = difference(feature1);
    firstPoly.splice(0, firstPoly.length, ...firstDiff.geometry.coordinates);
  });

  firstPoly[0] = removeDuplicatesCordsByPolygon(firstPoly[0]);
  const result = polygon(firstPoly);
  return result;
};
