import { computed } from 'vue';
import { Position } from 'geojson';

export const useFormatter = () => {
  const formatValue = computed(() => (value: unknown) => {
    if (typeof value === 'boolean') {
      return value ? 'да' : 'нет';
    }
    if (value === 'true' || value === 'false') {
      return value === 'true' ? 'да' : 'нет';
    }
    if (typeof value === 'number') {
      return value.toString();
    }
    if (typeof value === 'undefined') {
      return '';
    }
    if (value === null) {
      return '';
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value;
  });

  const ucFirst = computed(() => (value: string) => value.charAt(0).toUpperCase() + value.slice(1));

  const formatEwkt = (coordinates: Position[][][]): string => {
    const polygons: string[] = [];

    coordinates.forEach((polygon) => {
      const rings: string[] = [];

      polygon.forEach((ring) => {
        const points = ring.map((point) => point.join(' '));

        // Если полигон не замкнут — замыкаем
        if (ring[0][0] !== ring[ring.length - 1][0] || ring[0][1] !== ring[ring.length - 1][1]) {
          points.push(ring[0].join(' '));
        }

        rings.push(`(${points.join(', ')})`);
      });

      polygons.push(`(${rings.join(', ')})`);
    });

    return `SRID=4326;MULTIPOLYGON (${polygons.join(', ')})`;
  };
  const formatPointEwkt = (coordinates: []) => `SRID=4326;POINT (${coordinates.join(' ')})`;

  return {
    formatValue,
    formatEwkt,
    formatPointEwkt,
    ucFirst,
  };
};
