/**
 * Округление аналогично округлению в питоне
 * @param value - число для округления
 * @param decimal - количество знаков после запятой
 */
export const pythonRound = (value: number | string, decimal = 0) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.').replaceAll(' ', '');
    if (value !== Number(value).toString() || value.split('.').length > 2) {
      return NaN;
    }
    value = Number(value);
  }
  if (value === 0) {
    return 0;
  }
  if (!value || Number.isNaN(value)) {
    return NaN;
  }

  if (decimal === 0) {
    if (Number(value) % 1 > 0.5) {
      return Math.ceil(Number(value));
    }
    return Math.floor(Number(value));
  }

  value = value.toString();
  if (value.indexOf('.') === -1) {
    value += '.0';
  }

  let p = Number(value.slice(0, value.indexOf('.') + decimal + 1));
  const o = value.slice(value.indexOf('.') + decimal + 1);
  const ob = o.slice(0, 1);
  if (Number(ob) >= 5) {
    p += Math.sign(Number(value)) * (1 / 10 ** decimal);
  }
  return Number(p.toString().slice(0, value.indexOf('.') + decimal + 1));
};
