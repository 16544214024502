import { Model } from '@/models/Model';
import { FeatureCollection } from 'geojson';

export class MapLineModel extends Model {
  public FeatureCollection: FeatureCollection

  constructor() {
    super();
    this.FeatureCollection = {
      type: 'FeatureCollection',
      features: [
      ],
    };
  }

  clearFeatures() {
    this.FeatureCollection.features = [];
  }
}
