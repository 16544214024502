import type { MapModel } from '@/models/map/MapModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { FieldModel } from '@/models/field/FieldModel';
import { bbox } from '@turf/turf';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { CanvasSource, SourceSpecification } from 'mapbox-gl';
import { Feature, MultiPolygon } from 'geojson';

export class MapLayerFieldLoadingModel extends MapLayerModel implements IMapLayerModel {
  readonly data: FieldModel;

  private _active = true;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, field: MapInputType) {
    super(mapModel, type, 'Loading', field.uuid);
    this.data = field as FieldModel;
    this.create();
    this.layerIds.push(this.layerId);
    this.sourceIds.push(this.sourceId);
  }

  get fieldId(): number {
    return this.data.id;
  }

  create = () => {
    const cBbox = bbox(this.data.dto as Feature<MultiPolygon>);
    const k = 100000;
    const bw = (cBbox[2] - cBbox[0]) * k;
    const bh = (cBbox[3] - cBbox[1]) * k;

    const canvasId = `${this.sourceId}-canvas`;
    const canvas = document.createElement('canvas');
    document.getElementsByClassName('MapLayout-mapbox')[0]?.appendChild(canvas);
    canvas.id = canvasId;
    canvas.width = bw;
    canvas.height = bh;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, bw, bh);
      ctx.beginPath();
      this.data.dto.geometry.coordinates[0][0].forEach((v, i) => {
        if (i === 0) {
          ctx.moveTo((v[0] - cBbox[0]) * k, (v[1] - cBbox[1]) * k);
        } else {
          ctx.lineTo((v[0] - cBbox[0]) * k, (v[1] - cBbox[1]) * k);
        }
      });
      ctx.closePath();
      ctx.clip();

      ctx.fillStyle = 'rgba(0, 0, 0, 0.1 )';
      ctx.fillRect(0, 0, bw, bh);

      ctx.fillStyle = 'rgba(0, 0, 0, 0.25 )';
      const sk = 130;
      let offset = 0;

      const animate = () => {
        if (this._active) {
          requestAnimationFrame(animate);
        }
        ctx.clearRect(0, 0, bw, bh);

        for (let i = 0; i < bw; i += sk) {
          ctx.beginPath();
          ctx.moveTo(i + offset, 0);
          ctx.lineTo(i + offset + sk / 2, bh);
          ctx.lineTo(i + offset + sk, bh);
          ctx.lineTo(i + offset + sk / 2, 0);
          ctx.closePath();
          ctx.fill();
        }

        ctx.beginPath();
        ctx.moveTo(bw + offset + sk, 0);
        ctx.lineTo(bw + offset + 1.5 * sk, bh);
        ctx.lineTo(bw + offset + 2 * sk, bh);
        ctx.lineTo(bw + offset + 1.5 * sk, 0);
        ctx.closePath();
        ctx.fill();

        if (offset < -sk) {
          offset = 0;
        } else {
          offset -= 2;
        }
      };

      if (!this._mapModel?.map?.getSource(this.sourceId)) {
        this._mapModel?.map?.addSource(this.sourceId, {
          type: 'canvas',
          canvas,
          coordinates: [[cBbox[0], cBbox[1]], [cBbox[2], cBbox[1]], [cBbox[2], cBbox[3]], [cBbox[0], cBbox[3]]],
          animate: true,
        } as SourceSpecification & CanvasSource);
        this._mapModel?.map?.addLayer({
          id: this.layerId,
          source: this.sourceId,
          type: 'raster',
        });
      }
      animate();
    }
  }

  remove = (): void => {
    this._active = false;
    document.getElementById(`${this.layerId}-canvas`)?.remove();
    this._mapModel?.fieldsLoading.splice(this._mapModel?.fieldsLoading.indexOf(this), 1);
  }
}
