import { RegisterUiComponents } from '@/components/ui/components';
import RegisteredComponents from '@/modules/core/RegisteredComponents';
import { tolgee } from '@/plugin/tolgee';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { VueTolgee } from '@tolgee/vue';
import ElementPlus from 'element-plus';
import ru from 'element-plus/dist/locale/ru.mjs';
import { createApp } from 'vue';
import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import Vue3ColorPicker from 'vue3-colorpicker';
import Markdown from 'vue3-markdown-it';
import App from './modules/core/ui/App.vue';
import router from './router';
import '@/styles/app.scss';
import 'element-plus/dist/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'vue3-colorpicker/style.css';

const app = createApp(App)
  .use(router)
  .use(ElementPlus, {
    locale: ru,
  })
  .use(Markdown)
  .use(VueTolgee, { tolgee })
  // .use(VueResizeObserver)
  .use(Vue3ColorPicker);

app.use(VueSvgInlinePlugin, {
  attributes: {
    clone: ['viewbox'],
    merge: ['class', 'style'],
    add: [],
    data: [],
    remove: ['alt', 'src', 'data-src', 'width', 'height'],
  },
  cache: {
    persistent: process.env.NODE_ENV !== 'development',
  },
});

// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

RegisteredComponents.registerComponents(app);

RegisterUiComponents(app);

app.mount('#app');
