export const RuleFieldName = [
  {
    required: true,
    message: 'Введите краткое наименование поля',
    trigger: 'blur',
  },
  {
    min: 2,
    max: 255,
    message: 'Длина краткого наименования должна быть от 2-ух до 255-ти символов',
    trigger: 'blur',
  },

];
