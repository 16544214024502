import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-field"]
const _hoisted_2 = ["viewBox", "width", "height"]
const _hoisted_3 = ["transform-origin"]
const _hoisted_4 = ["points"]
const _hoisted_5 = {
  key: 1,
  class: "error-text",
  x: "50%",
  y: "50%",
  "dominant-baseline": "middle",
  "text-anchor": "middle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "FieldView",
    "data-field": _ctx.field?.id
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["FieldView_polygon", _ctx.classList])
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(["field-preview", _ctx.classList]),
        viewBox: `0 0 ${_ctx.width} ${_ctx.height}`,
        width: _ctx.width,
        height: _ctx.height,
        xmlns: "http://www.w3.org/2000/svg",
        preserveAspectRatio: "xMidYMid meet"
      }, [
        (_ctx.fields.length)
          ? (_openBlock(), _createElementBlock("g", {
              key: 0,
              "transform-origin": `${_ctx.width/2} ${_ctx.height/2}`
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
                return (_openBlock(), _createElementBlock("g", { key: index }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field, (polygon, pIndex) => {
                    return (_openBlock(), _createElementBlock("polygon", {
                      class: "field-polygon",
                      key: pIndex,
                      points: polygon
                    }, null, 8, _hoisted_4))
                  }), 128))
                ]))
              }), 128))
            ], 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("text", _hoisted_5, "No data"))
      ], 10, _hoisted_2))
    ], 2)
  ], 8, _hoisted_1))
}