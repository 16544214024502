<template lang="pug" src="./CropManage.pug"/>
<style lang="scss" src="./CropManage.scss"/>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref, watch,
} from 'vue';
import { useUser } from '@/composables/useUser';
import PublicCropList from '@/modules/crop/PublicCropList';
import WindowBox from '@/components/shared/WindowBox/WindowBox.vue';
import CropList from '@/modules/crop/CropList';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { useMapLayout } from '@/composables/useMapLayout';
import { Delete, Edit } from '@element-plus/icons-vue';
import ApiService from '@/services/api/ApiService';
import { CropModel } from '@/models/crop/CropModel';
import hashCode from '@/lib/tools/hashCode';
import StructEvents from '@/modules/struct/StructEvents';
import CropEditForm from '@/modules/dictionary/ui/crop/CropEditForm/CropEditForm.vue';
import { ElMessage } from 'element-plus';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';

export default defineComponent({
  name: 'CropManage',
  components: {
    WindowBox,
    CropEditForm,
  },
  setup() {
    const { user } = useUser();
    const { showCropManage } = useMapLayout();
    const variety = ref('');
    const showAddForm = ref(false);
    const show = ref(false);
    const {
      mapModel,
      fields,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const fieldsLayer = ref<MapLayerFieldsModel>();
    const search = ref('');

    const editCrop = ref<CropModel | undefined>();

    const form = ref<{ entity: {
        id: number,
        name: string,
        color: string,
      },
      hybrids: string[],
      isGreenManure: boolean,
      isPerennial: boolean
    }>({
      entity: {
        id: 0,
        name: '',
        color: '',
      },
      hybrids: [],
      isGreenManure: false,
      isPerennial: false,
    });

    // mdiFruitPear  плодовые
    onMounted(() => {
      PublicCropList.fetchPublicCrop();

      StructEvents.onChangeStructUnit(() => {
        CropList.fetchCrops();
      });
    });

    watch(form, (v) => {
      const publicCrop = PublicCropList.publicCrops.find((pc) => pc.name === v.entity.name);
      if (publicCrop) {
        v.entity.name = publicCrop.name;
        v.entity.color = !v.entity.color ? publicCrop.color : v.entity.color;
      } else {
        v.entity.color = '';
      }
    }, { deep: true });

    const clearHybrids = () => {
      form.value.hybrids.splice(0, form.value.hybrids.length);
      form.value.entity.color = '';
    };

    const save = async () => {
      const invalidHybrids = form.value.hybrids.some((h) => h.trim() === '');

      if (invalidHybrids) {
        ElMessage.error('Это поле не может быть пустым.');
        return;
      }

      showAddForm.value = false;
      LoadingStatus.loading(LoadingNamesEnum.CROP_LIST);

      await ApiService.crop.createCrop({
        name: form.value.entity.name,
        color: form.value.entity.color || '#D0D0D0',
        order: CropList.crops.value.length + 1,
        is_green_manure: form.value.isGreenManure,
        is_perennial: form.value.isPerennial,
        hybrids: form.value.hybrids.filter((h) => h.length).map((name) => ({ name })),
      });

      await CropList.fetchCrops(true);
    };

    const doDelete = async (id: number) => {
      LoadingStatus.loading(LoadingNamesEnum.CROP_LIST);
      await ApiService.crop.deleteCrop(id);
      await CropList.fetchCrops(true);
      if (fieldsLayer.value) {
        fields.value.forEach((field) => {
          if (field.feature.properties) field.feature.properties.__cropRateColor = '#ffffff';
        });

        fieldsLayer.value.selectedPaint = 'crop-rotation';
        mapModel.value?.events.emitFieldUpdated();
        mapModel.value?.events.emitFieldPaint();
      }
    };

    // region drag item
    const isDraggable = ref(false);
    const draggingCrop = ref<CropModel>();

    const orderData = () => CropList.crops.value.map((v) => ({ id: v.id, order: v.order }));

    let orderHash = 0;

    const dragStart = (event: DragEvent, crop: CropModel) => {
      orderHash = hashCode(JSON.stringify(orderData()));
      if (event && isDraggable.value) {
        draggingCrop.value = crop;

        const ghost = document.getElementById('ghostImage');
        if (ghost) {
          event.dataTransfer?.setDragImage(ghost, 0, 0);
        }
      }
    };

    const dragOver = (evt: DragEvent) => {
      const cropId = Number((evt.target as HTMLElement).dataset.cropId);
      if (Number.isNaN(cropId)) return;

      const resort = () => {
        let i = 1;
        CropList.crops.value
          .sort((a, b) => a.order - b.order)
          .forEach((v) => { v.order = i++; });
      };
      resort();
      const overCrop = CropList.crops.value.find((v) => v.id === cropId);
      if (overCrop && draggingCrop.value) {
        const fromIdx = draggingCrop.value.order;
        const toIdx = overCrop.order;
        overCrop.order += toIdx > fromIdx ? -0.5 : 0.5;
        draggingCrop.value.order = toIdx;
        resort();
        evt.preventDefault();
        evt.stopImmediatePropagation();
      }
    };

    const dragEnd = () => {
      draggingCrop.value = undefined;
      if (orderHash !== hashCode(JSON.stringify(orderData()))) {
        ApiService.crop.reorderCrops(orderData());
      }
    };
    // endregion

    const saveEdit = async () => {
      showAddForm.value = false;
      LoadingStatus.loading(LoadingNamesEnum.CROP_LIST);
      await ApiService.crop.createCrop({
        name: form.value.entity.name,
        color: form.value.entity.color || '#D0D0D0',
        order: CropList.crops.value.length + 1,
        is_green_manure: form.value.isGreenManure,
        is_perennial: form.value.isPerennial,
        hybrids: form.value.hybrids.filter((h) => h.length).map((name) => ({ name })),
      });
      await CropList.fetchCrops(true);
    };

    const filterMethod = (v: string) => {
      search.value = v;
    };

    watch(search, (v: string) => {
      if (v.length > 0) {
        form.value.entity.name = v;
      }
    });

    const options = computed<{ label: string, value: string, disabled: boolean}[]>(() => PublicCropList.publicCrops
      .map((v) => ({
        label: v.name,
        value: v.name,
        disabled: CropList.hasCropEntityId(v.id),
      }))
      .filter((r) => r.label.split(' ').some((w) => w.toLowerCase().startsWith(search.value.toLowerCase()))));

    return {
      Delete,
      Edit,
      user,
      PublicCropList,
      CropList,
      LoadingStatus,
      LoadingNamesEnum,
      showCropManage,
      variety,
      show,
      showAddForm,
      form,
      save,
      doDelete,
      isDraggable,
      draggingCrop,
      dragStart,
      dragOver,
      dragEnd,
      editCrop,
      saveEdit,
      clearHybrids,
      search,
      options,
      filterMethod,
    };
  },
});
</script>
