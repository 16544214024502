import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapCanvasModel } from '@/models/map/data/MapCanvasModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import mapboxgl, { CanvasSource, SourceSpecification } from 'mapbox-gl';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';

export class MapLayerCanvasModel extends MapLayerModel implements IMapLayerModel {
  public data: MapCanvasModel;

  private _source: CanvasSource;

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, (input as MapCanvasModel).canvasId, input.uuid);

    this.data = input as MapCanvasModel;
    this.createLayer();
    this._source = this._mapModel.map.getSource(this.sourceId) as CanvasSource;
    this._mapModel.map.moveLayer(`${this.layerId}`, MapAnchorEnum.CANVAS);
  }

  play(): void {
    this._source.play();
  }

  pause(): void {
    this._source.pause();
  }

  updateImage(): void {
    this._source.play();
    setTimeout(() => {
      this._source.pause();
    });
  }

  createLayer(): void {
    if (this._mapModel?.map) {
      (this._mapModel.map as mapboxgl.Map).addSource(this.sourceId, {
        animate: false, // !!!!!!!! анимация СТРОГО запрещена !!!!!!!!
        type: 'canvas',
        canvas: this.data.canvasId,
        coordinates: this.data.bbox,
      } as SourceSpecification & CanvasSource);

      (this._mapModel.map as mapboxgl.Map).addLayer({
        id: this.layerId,
        type: 'raster',
        source: this.sourceId,
      });
      this.layerIds.push(this.layerId);
      this.sourceIds.push(this.sourceId);
    }
  }
}
