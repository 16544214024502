import { Feature, LineString, Position } from 'geojson';
import getLinesIntersection from '@/lib/map/getLinesIntersection';

export const getSelfIntersections = (line: Feature<LineString>): Position[] => {
  const coords = line.geometry.coordinates;
  const intersections = [];
  for (let i = 0; i < coords.length - 1; i++) {
    for (let j = i + 2; j < coords.length - 1; j++) {
      if (i === j) continue;
      const intersectPosition = getLinesIntersection(coords[i], coords[i + 1], coords[j], coords[j + 1]);
      if (intersectPosition) {
        intersections.push(intersectPosition);
      }
    }
  }
  return intersections;
};
