import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import type { MapModel } from '@/models/map/MapModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { GeoJSONSource } from 'mapbox-gl';
import { featureCollection, point } from '@turf/turf';

export class MapLayerPositionModel extends MapLayerModel {
  private _position: {lng: number, lat: number}

  constructor(mapModel: MapModel) {
    super(mapModel, MapLayerTypeEnum.POSITION, 'position', 'position');
    navigator.geolocation.getCurrentPosition((position) => {
      this._position = {
        lng: position.coords.longitude,
        lat: position.coords.latitude,
      };
      this.initLayer();
    });
    setInterval(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        this._position = {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        };
        this.updateLayer();
      });
    }, 5000);
  }

  updateLayer(): void {
    (this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource)?.setData(featureCollection([point([this._position.lng, this._position.lat])]));
  }

  initLayer(): void {
    if (this._mapModel?.map) {
      this._mapModel.map.addSource(this.sourceId, {
        type: 'geojson',
        data: featureCollection([point([this._position.lng, this._position.lat])]),
      });

      this._mapModel.map.addLayer({
        id: this.layerId,
        type: 'circle',
        source: this.sourceId,

        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            7, 1,
            11, 2,
            12, 4,
            15, 5,
          ],
          'circle-color': '#ffe200',
          'circle-stroke-width': [
            'interpolate', ['linear'], ['zoom'],
            7, 1,
            11, 1,
            12, 2,
            15, 2,
          ],
          'circle-stroke-color': '#ff0000',
        },
      });

      this._mapModel.map.moveLayer(this.layerId, MapAnchorEnum.POSITION);
    }
  }
}
