import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { fetcher } from '@/lib/tools/fetcher';
import { CropRateModel } from '@/models/cropRate/CropRateModel';
import { FieldAverageIndexModel } from '@/models/field/FieldAverageIndexModel';
import { FieldIndexHistoryModel } from '@/models/field/FieldIndexHistoryModel';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldLoaderModel } from '@/models/field/FieldLoaderModel';
import { FieldModelEvents } from '@/models/field/FieldModelEvents';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapFactModel } from '@/models/field/FieldTaskMapFactModel';
import { FieldTaskMapHarvestModel } from '@/models/field/FieldTaskMapHarvestModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import StructList from '@/modules/struct/StructList';
import ApiService from '@/services/api/ApiService';
import type { FieldFeatureDto } from '@/services/api/dto/gis/FieldFeatureDto';
import { RasterCropDto } from '@/services/api/dto/gis/RasterCropDto';
import { ChartDataDto } from '@/services/api/dto/plot/ChartDataDto';
import { SatelliteCounterDto } from '@/services/api/dto/satellites/SatelliteCounterDto';
import { TaskMapBaseDto } from '@/services/api/dto/taskMap/TaskMapBaseDto';
import { TaskMapFactDto } from '@/services/api/dto/taskMap/TaskMapFactDto';
import { TaskMapHarvestDto } from '@/services/api/dto/taskMap/TaskMapHarvestDto';
import { TaskMapWorkDto } from '@/services/api/dto/taskMap/TaskMapWorkDto';
import LoggerService from '@/services/logger/LoggerService';
import { bbox, polygon } from '@turf/turf';
import type { Feature, MultiPolygon } from 'geojson';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import { MultiPolygonModel } from '@/models/geojson/MultiPolygonModel';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';
import { Model } from '@/models/Model';
import { FieldRelationsDto } from '@/services/api/dto/struct/FieldRelationsDto';

export class FieldModel extends Model {
  private readonly _id: number;

  // ключь это id растра
  private _rasterCrop: Record<number, RasterCropDto> = {};

  private readonly _preview: FieldPreviewModel;

  constructor(data: FieldFeatureDto) {
    super();
    this._id = Number(data.properties.pk);
    this._name = data.properties.name;
    this._nameRus = data.properties.name_rus;
    this._sq = Number(data.properties.sq);
    this._sqAcc = Number(data.properties.sq_acc);

    this._bounds = this.calculateBounds(data.geometry.coordinates);
    this._feature = {
      id: this._id,
      type: data.type,
      geometry: data.geometry,
      properties: {
        id: this._id,
        rating: data.properties.rating || undefined,
        avg_ndvi: data.properties.avg_ndvi || undefined,
      },
    };

    this._geozone = new MultiPolygonModel(data.geometry.coordinates as [number, number][][][]);

    this._preview = new FieldPreviewModel(data.geometry.coordinates as [number, number][][][]);

    this._dto = data as FieldFeatureDto;
    this._loader = new FieldLoaderModel(this._id);
    FieldModelEvents(this);
  }

  private _relations: {
    field: {
      id: number,
      name: string,
      seasonId: number,
    },
    percent: number;
    area: number;
  }[] = [];

  get relations(): {
    field: { id: number; name: string; seasonId: number };
    percent: number;
    area: number
  }[] {
    return this._relations;
  }

  get preview(): FieldPreviewModel {
    return this._preview;
  }

  get id(): number {
    return this._id;
  }

  private _feature: Feature<MultiPolygon>;

  get feature(): Feature<MultiPolygon> {
    return this._feature;
  }

  private _dto: FieldFeatureDto;

  get dto(): FieldFeatureDto {
    return this._dto;
  }

  set dto(value: FieldFeatureDto) {
    this._dto = value;
  }

  private _name: string;

  get name(): string {
    return this._name;
  }

  private _nameRus: string;

  get nameRus(): string {
    return this._nameRus;
  }

  private _sq: number;

  get sq(): number {
    return this._sq;
  }

  private _sqAcc: number;

  get sqAcc(): number {
    return this._sqAcc;
  }

  private _isActive = false;

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  private _isEditMode = false;

  get isEditMode(): boolean {
    return this._isEditMode;
  }

  set isEditMode(value: boolean) {
    this._isEditMode = value;
  }

  private _bounds: LngLatBounds;

  get bounds(): LngLatBounds {
    return this._bounds;
  }

  private _nirFiles: FieldNirModel[] = [];

  get nirFiles(): FieldNirModel[] {
    return this._nirFiles;
  }

  private _historyIndexes: FieldIndexHistoryModel[] = [];

  get historyIndexes(): FieldIndexHistoryModel[] {
    return this._historyIndexes;
  }

  private _monitoringIndexes: FieldIndexMonitoringModel[] = [];

  get monitoringIndexes(): FieldIndexMonitoringModel[] {
    return this._monitoringIndexes;
  }

  private _averageIndexes: FieldAverageIndexModel[] = [];

  get averageIndexes(): FieldAverageIndexModel[] {
    return this._averageIndexes;
  }

  set averageIndexes(value: FieldAverageIndexModel[]) {
    this._averageIndexes = value;
  }

  private _satelliteRgbFiles: FieldNirModel[] = [];

  get satelliteRgbFiles(): FieldNirModel[] {
    return this._satelliteRgbFiles;
  }

  set satelliteRgbFiles(value: FieldNirModel[]) {
    this._satelliteRgbFiles = value;
  }

  private _satelliteActiveRgbFile: FieldNirModel | undefined;

  get satelliteActiveRgbFile(): FieldNirModel | undefined {
    return this._satelliteActiveRgbFile;
  }

  set satelliteActiveRgbFile(value: FieldNirModel | undefined) {
    this._satelliteActiveRgbFile = value;
  }

  private _loader: FieldLoaderModel;

  get loader(): FieldLoaderModel {
    return this._loader;
  }

  private _cropRates: CropRateModel[] = [];

  get cropRates(): CropRateModel[] {
    return this._cropRates;
  }

  private _baseTaskMaps: FieldTaskMapBaseModel[] = [];

  get baseTaskMaps(): FieldTaskMapBaseModel[] {
    return this._baseTaskMaps;
  }

  private _workTaskMaps: FieldTaskMapWorkModel[] = [];

  get workTaskMaps(): FieldTaskMapWorkModel[] {
    return this._workTaskMaps;
  }

  private _factTaskMaps: FieldTaskMapFactModel[] = [];

  get factTaskMaps(): FieldTaskMapFactModel[] {
    return this._factTaskMaps;
  }

  private _harvestTaskMaps: FieldTaskMapHarvestModel[] = [];

  get harvestTaskMaps(): FieldTaskMapHarvestModel[] {
    return this._harvestTaskMaps;
  }

  private _defaultBaseTaskMap: FieldTaskMapBaseModel | undefined;

  get defaultBaseTaskMap(): FieldTaskMapBaseModel | undefined {
    return this._defaultBaseTaskMap;
  }

  private _counter: SatelliteCounterDto | undefined;

  get counter(): SatelliteCounterDto | undefined {
    return this._counter;
  }

  set counter(value: SatelliteCounterDto | undefined) {
    this._counter = value;
  }

  private _animateLoading = false;

  get animateLoading(): boolean {
    return this._animateLoading;
  }

  set animateLoading(value: boolean) {
    this._animateLoading = value;
    FieldsEvents.emitSetAnimateEffect(value, this);
  }

  private _plot: ChartDataDto[] = [];

  get plot(): ChartDataDto[] {
    return this._plot;
  }

  private _geozone: MultiPolygonModel;

  get geozone(): MultiPolygonModel {
    return this._geozone;
  }

  setRelations(dto: FieldRelationsDto): void {
    dto.relations.forEach((relation) => {
      this._relations.push({
        area: relation.area,
        percent: relation.percent,
        field: {
          id: relation.field.id,
          name: relation.field.name,
          seasonId: relation.field.season,
        },
      });
    });
  }

  findNirFileBySceneId(sceneId: number) {
    return this._nirFiles.find((f) => f.scene.id === sceneId);
  }

  // eslint-disable-next-line camelcase
  async fetchNirFiles(datetime_after?: string, force = false): Promise<void> {
    await fetcher(LoadingNamesEnum.FIELD_NIR_FILES, this._id, force, async () => {
      this._nirFiles.splice(0, this._nirFiles.length);
      await ApiService.monitoring.getRgb(this._id, { datetime_after }).then((response) => {
        [...response.data]
          .sort((a, b) => (new Date(a.scene.datetime) < new Date(b.scene.datetime) ? 1 : -1))
          .forEach((file) => this._nirFiles.push(new FieldNirModel(file, this._id)));
      });
    });
  }

  // eslint-disable-next-line camelcase
  async fetchSatelliteFiles(force = false): Promise<void> {
    await fetcher(LoadingNamesEnum.FIELD_SATELLITE_RGB_FILES, this._id, force, async () => {
      await ApiService.satellites.getRgbFiles(this._id).then((response) => {
        [...response.data]
          .sort((a, b) => (new Date(a.scene.datetime) < new Date(b.scene.datetime) ? 1 : -1))
          .forEach((file) => this._satelliteRgbFiles.push(new FieldNirModel(file, this._id)));
      });
    });
  }

  // eslint-disable-next-line camelcase

  async fetchHistoryIndexes(force = false): Promise<void> {
    await fetcher(LoadingNamesEnum.FIELD_HISTORY_INDEXES, this._id, force, async () => {
      await ApiService.satellites.getCompositeIndexes(this._id).then((response) => {
        [...response.data].forEach((dto) => {
          this._historyIndexes.push(new FieldIndexHistoryModel(dto));
        });
      });
    });
  }

  async fetchTaskMaps(force = false): Promise<void> {
    const fillTaskMap = (_data: TaskMapFactDto[] | TaskMapBaseDto[] | TaskMapWorkDto[] | TaskMapHarvestDto[],
      taskMaps: FieldTaskMapBaseModel[] | FieldTaskMapWorkModel[] | FieldTaskMapFactModel[] | FieldTaskMapHarvestModel[],
      type: 'base' | 'work' | 'fact' | 'harvest') => {
      const selectedTaskMapId = taskMaps.find((v) => v.selected)?.id;
      taskMaps.splice(0, taskMaps.length);
      switch (type) {
      case 'base':
        (_data as TaskMapBaseDto[]).forEach((dto) => (taskMaps as FieldTaskMapBaseModel[]).push(new FieldTaskMapBaseModel(dto)));
        break;
      case 'work':
        (_data as TaskMapWorkDto[]).forEach((dto) => (taskMaps as FieldTaskMapWorkModel[]).push(new FieldTaskMapWorkModel(dto)));
        break;
      case 'fact':
        (_data as TaskMapFactDto[]).forEach((dto) => (taskMaps as FieldTaskMapFactModel[]).push(new FieldTaskMapFactModel(dto)));
        break;
      case 'harvest':
        (_data as TaskMapHarvestDto[]).forEach((dto) => (taskMaps as FieldTaskMapHarvestModel[]).push(new FieldTaskMapHarvestModel(dto)));
        break;
      default:
        LoggerService.error(`Try to add task map, but unknown type: ${type}`);
      }

      taskMaps.sort((a, b) => (a.createAt > b.createAt ? -1 : 1));

      if (selectedTaskMapId) {
        const foundTaskMap = taskMaps.find((v) => v.id === selectedTaskMapId);
        if (foundTaskMap) {
          foundTaskMap.setSelected(true);
        }
      }
    };

    await fetcher(LoadingNamesEnum.FIELD_TASK_MAPS, this._id, force, async () => {
      const { data } = await ApiService.taskMap.getFieldTaskMaps(this.id);
      fillTaskMap(data.base, this._baseTaskMaps, 'base');
      fillTaskMap(data.work, this._workTaskMaps, 'work');
      fillTaskMap(data.fact, this._factTaskMaps, 'fact');
      fillTaskMap(data.harvest, this._harvestTaskMaps, 'harvest');
    });
  }

  updateBaseTaskMap = (baseTasks: TaskMapBaseDto[]) => {
    this._baseTaskMaps.splice(0, this._baseTaskMaps.length);
    this._baseTaskMaps = baseTasks.map((a) => new FieldTaskMapBaseModel(a)).sort((a, b) => (a.createAt > b.createAt ? -1 : 1));
  }

  updateWorkTaskMap = (workTasks: TaskMapWorkDto[]) => {
    this._workTaskMaps.splice(0, this._workTaskMaps.length);
    this._workTaskMaps = workTasks.map((a) => new FieldTaskMapWorkModel(a)).sort((a, b) => (a.createAt > b.createAt ? -1 : 1));
  }

  async fetchMonitoringIndexes(force = false): Promise<void> {
    return fetcher(LoadingNamesEnum.FIELD_INDEXES, this._id, force, async () => {
      const { data } = await ApiService.monitoring.getIndexes(this._id);
      data.forEach((dto) => {
        this._monitoringIndexes.push(new FieldIndexMonitoringModel(dto));
      });
    });
  }

  // region Crop rate
  async setCropRate(cropName: string | undefined, value: number): Promise<void> {
    if (cropName) {
      const { data } = await ApiService.cropRate.createFieldCropRate(StructList.activeStruct.value?.id || 0, this._id, cropName, value);
      this._cropRates = [
        ...this._cropRates.filter((cr) => cr.cropItem !== data.crop_item),
        new CropRateModel(data),
      ];
    }
  }

  async fetchRaster(rasterId: number): Promise<void> {
    await fetcher(LoadingNamesEnum.FIELD_RASTER_CROP, this._id, false, async () => {
      const { data } = await ApiService.gis.getRasterCrop(rasterId, this._id);
      this._rasterCrop[rasterId] = data;
    });
  }
  // endregion

  async getCropRaster(rasterId: number): Promise<RasterCropDto> {
    if (!this._rasterCrop[rasterId]) {
      await this.fetchRaster(rasterId);
      return this._rasterCrop[rasterId] as RasterCropDto;
    }
    return this._rasterCrop[rasterId] as RasterCropDto;
  }

  async fetchPlot(force = false) {
    fetcher(LoadingNamesEnum.FIELD_PLOT, this._id, force, async () => {
      const { data } = await ApiService.plot.fetchData(this._id);
      this._plot = [...data].sort((a, b) => (a.plot_type < b.plot_type ? 1 : -1));
    });
  }

  update(data: FieldFeatureDto) {
    this._name = data.properties.name;
    this._nameRus = data.properties.name_rus;
    this._sq = Number(data.properties.sq);
    this._sqAcc = Number(data.properties.sq_acc);

    this._bounds = this.calculateBounds(data.geometry.coordinates);
    this._feature = {
      id: this._id,
      type: data.type,
      geometry: data.geometry,
      properties: {
        id: this._id,
        rating: data.properties.rating || undefined,
        avg_ndvi: data.properties.avg_ndvi || undefined,
      },
    };
    this._dto = data as FieldFeatureDto;
    this._loader = new FieldLoaderModel(this._id);
  }

  private calculateBounds(coordinates: number[][][][]) {
    const sw = new LngLat(coordinates[0][0][0][0], coordinates[0][0][0][1]);
    const ne = new LngLat(coordinates[0][0][0][0], coordinates[0][0][0][1]);

    if (coordinates) {
      coordinates.forEach((ccs) => {
        const [w, s, e, n] = bbox(polygon(ccs));
        if (s < sw.lat) sw.lat = s;
        if (w < sw.lng) sw.lng = w;
        if (n > ne.lat) ne.lat = n;
        if (e > ne.lng) ne.lng = e;
      });
    }
    return new LngLatBounds(sw, ne);
  }
}
