import { ApiAssets } from '@/services/api/handlers/assets';
import { ApiAuth } from '@/services/api/handlers/auth';
import { ApiBilling } from '@/services/api/handlers/billing';
import { ApiCommunications } from '@/services/api/handlers/communications';
import { ApiCrop } from '@/services/api/handlers/crop';
import { ApiCropRate } from '@/services/api/handlers/cropRate';
import { ApiCropRotation } from '@/services/api/handlers/cropRotation';
import { ApiDictionary } from '@/services/api/handlers/dictionary';
import { ApiEtc } from '@/services/api/handlers/etc';
import { ApiGis } from '@/services/api/handlers/gis';
import { ApiIntegrations } from '@/services/api/handlers/integrations';
import { ApiMonitoring } from '@/services/api/handlers/monitoring';
import { ApiPlot } from '@/services/api/handlers/plot';
import { ApiSatellites } from '@/services/api/handlers/satellites';
import { ApiScouting } from '@/services/api/handlers/scouting';
import { ApiStreetMap } from '@/services/api/handlers/streetMap';
import { ApiStruct } from '@/services/api/handlers/struct';
import { ApiTaskMap } from '@/services/api/handlers/taskMap';
import { ApiTech } from '@/services/api/handlers/tech';
import { ApiSeason } from '@/services/api/handlers/season';
import { ApiTruefieldsServices } from '@/services/api/handlers/services';
import { ApiUser } from './handlers/user';

class ApiService {
  public auth = new ApiAuth();

  public user = new ApiUser();

  public gis = new ApiGis();

  public streetMap = new ApiStreetMap();

  public struct = new ApiStruct();

  public scouting = new ApiScouting();

  public satellites = new ApiSatellites();

  public communications = new ApiCommunications();

  public etc = new ApiEtc();

  public monitoring = new ApiMonitoring();

  public billing = new ApiBilling();

  public taskMap = new ApiTaskMap();

  public services = new ApiTruefieldsServices();

  public plot = new ApiPlot();

  public cropRate = new ApiCropRate();

  public crop = new ApiCrop();

  public dictionary = new ApiDictionary();

  public cropRotation = new ApiCropRotation();

  public integrations = new ApiIntegrations();

  public tech = new ApiTech();

  public assets = new ApiAssets();

  public season = new ApiSeason();
}

export default new ApiService();
