import { Bus } from '@/lib/classes/Bus';
import { MapMouseEvent } from 'mapbox-gl';
import { TaskIndexDto } from '@/services/api/dto/taskMap/TaskIndexDto';
import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';

enum Events {
  ON_CLICK,
  ON_MOUSE_MOVE,
  ACTIVATE_CANVAS_BASE_MAP,
  CONTEXT_MENU,
  CONTEXT_MENU_CLOSE,
  RASTER_UPDATE,
  POIS_UPDATED,
  TOGGLE_ACTIVE_POI,
  REDRAW_POIS,
  STRUCT_VIEW_REDRAW,
  FIELD_PAINT,
  FIELD_UPDATE,
  LAYER_RENDER,
  CLEAR_AREA_LAYER,
  UPDATE_DRAWER_MODE,
  ANCHOR_CREATED,
}

export type ClickOptionsType = {
  layerType?: string
}

export class MapModelEvents extends Bus {
  onClick(callback: (event: MapMouseEvent, options?: ClickOptionsType) => void, once = false): void {
    this.registerListener(Events.ON_CLICK, callback, once);
  }

  offClick(callback: (event: MapMouseEvent) => void): void {
    this.unregisterListener(Events.ON_CLICK, callback);
  }

  emitClick(event: MapMouseEvent, options?: ClickOptionsType): void {
    this.emitListeners(Events.ON_CLICK, event, options);
  }

  onActivateCanvasBaseMap(callback: (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, data: TaskIndexDto) => void, once = false): void {
    this.registerListener(Events.ACTIVATE_CANVAS_BASE_MAP, callback, once);
  }

  emitActivateCanvasBaseMap(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, data: TaskIndexDto): void {
    this.emitListeners(Events.ACTIVATE_CANVAS_BASE_MAP, canvas, context, data);
  }

  onContextMenu(callback: (event: MapMouseEvent) => void, once = false): void {
    this.registerListener(Events.CONTEXT_MENU, callback, once);
  }

  emitContextMenu(event: MapMouseEvent): void {
    this.emitListeners(Events.CONTEXT_MENU, event);
  }

  onContextMenuClose(callback: () => void, once = false): void {
    this.registerListener(Events.CONTEXT_MENU_CLOSE, callback, once);
  }

  emitContextMenuClose(): void {
    this.emitListeners(Events.CONTEXT_MENU_CLOSE);
  }

  emitUpdateRaster(): void {
    this.emitListeners(Events.RASTER_UPDATE);
  }

  onUpdateRaster(callback: () => void, once = false): void {
    this.registerListener(Events.RASTER_UPDATE, callback, once);
  }

  onPoisUpdated(callback: () => void, once = false): void {
    this.registerListener(Events.POIS_UPDATED, callback, once);
  }

  onToggleActivePoi(callback: (id: number, active: boolean) => void, once = false): void {
    this.registerListener(Events.TOGGLE_ACTIVE_POI, callback, once);
  }

  onRedrawPois(callback: (id: number, active: boolean) => void, once = false): void {
    this.registerListener(Events.REDRAW_POIS, callback, once);
  }

  emitRedrawPois(): void {
    this.emitListeners(Events.RASTER_UPDATE);
  }

  emitToggleActivePoi(): void {
    this.emitListeners(Events.TOGGLE_ACTIVE_POI);
  }

  emitPoisUpdated(): void {
    this.emitListeners(Events.POIS_UPDATED);
  }

  onStructViewRedraw(callback: () => void, once = false): void {
    this.registerListener(Events.STRUCT_VIEW_REDRAW, callback, once);
  }

  offStructViewRedraw(callback: () => void): void {
    this.unregisterListener(Events.STRUCT_VIEW_REDRAW, callback);
  }

  emitStructViewRedraw(): void {
    this.emitListeners(Events.STRUCT_VIEW_REDRAW);
  }

  onFieldPaint(callback: () => void, once = false): void {
    this.registerListener(Events.FIELD_PAINT, callback, once);
  }

  emitFieldPaint(): void {
    this.emitListeners(Events.FIELD_PAINT);
  }

  onFieldUpdate(callback: () => void, once = false): void {
    this.registerListener(Events.FIELD_UPDATE, callback, once);
  }

  emitFieldUpdated(): void {
    this.emitListeners(Events.FIELD_UPDATE);
  }

  onRenderLayer(callback: (layer: MapLayerModel) => void, once = false): void {
    this.registerListener(Events.LAYER_RENDER, callback, once);
  }

  offRenderLayer(callback: (layer: MapLayerModel) => void): void {
    this.unregisterListener(Events.LAYER_RENDER, callback);
  }

  emitRenderLayer(layer: MapLayerModel): void {
    this.emitListeners(Events.LAYER_RENDER, layer);
  }

  onRemoveLayer(callback: (layer: MapLayerModel) => void, once = false): void {
    this.registerListener(Events.LAYER_RENDER, callback, once);
  }

  offRemoveLayer(callback: (layer: MapLayerModel) => void): void {
    this.unregisterListener(Events.LAYER_RENDER, callback);
  }

  emitRemoveLayer(layer: MapLayerModel): void {
    this.emitListeners(Events.LAYER_RENDER, layer);
  }

  onUpdateDrawerMode(callback: (mode: 'edit'| 'create'|'none') => void, once = false): void {
    this.registerListener(Events.UPDATE_DRAWER_MODE, callback, once);
  }

  offUpdateDrawerMode(callback: (mode: 'edit'| 'create'|'none') => void): void {
    this.unregisterListener(Events.UPDATE_DRAWER_MODE, callback);
  }

  emitUpdateDrawerMode(mode: 'edit'| 'create'|'none'): void {
    this.emitListeners(Events.UPDATE_DRAWER_MODE, mode);
  }

  onAnchorCreated(callback: () => void, once = false): void {
    this.registerListener(Events.ANCHOR_CREATED, callback, once);
  }

  offAnchorCreated(callback: () => void): void {
    this.unregisterListener(Events.ANCHOR_CREATED, callback);
  }

  emitAnchorCreated(): void {
    this.emitListeners(Events.ANCHOR_CREATED);
  }
}
