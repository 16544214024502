<template lang="pug" src="./FieldUploadDialog.pug"/>

<style  lang="scss" src="./FieldUploadDialog.scss"/>

<script  lang="ts">

import { computed, defineComponent, ref } from 'vue';
import {
  genFileId, UploadInstance, UploadProps, UploadRawFile, UploadUserFile,
} from 'element-plus';
import {
  Feature, FeatureCollection, MultiPolygon, Polygon,
} from 'geojson';
import { v4 as uuid } from 'uuid';
import { useApp } from '@/composables/useApp';
import ApiService from '@/services/api/ApiService';
import StructList from '@/modules/struct/StructList';
import SeasonList from '@/modules/season/SeasonList';
import FieldsList from '@/modules/fields/FieldsList';
import { booleanIntersects } from '@turf/turf';
import { useStruct } from '@/composables/useStruct';
import { useMapContainers } from '@/composables/useMapContainers';
import { Upload } from '@element-plus/icons-vue';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import { handleFileToBase64 } from '@/utils/handleFileToBase64';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';

export default defineComponent({
  name: 'FieldUploadDialog',
  components: {
    Upload,
    UiDialog,
    FieldView,
  },
  emit: ['close'],
  setup(props, { emit }) {
    const isOpenAddDialog = ref(true);
    const showDialogAttributes = ref(false);
    const { structId } = useStruct();
    const fileList = ref<UploadUserFile[]>([]);
    const { mapModel } = useMapContainers();
    let uploadIdentifier;
    const featureCollection = ref<FeatureCollection<Polygon | MultiPolygon>>();
    const blockUploadFile = computed(() => fileList.value?.length === 0);
    const upload = ref<UploadInstance>();

    const attributes = ref<
      {
        'name': string,
        'type': string,
      }[]>([]);

    const selectAttributesKey = ref({
      name: '',
      description: '',
      area_ha: '',
    });

    const computedIsIntersection = computed(() => (poly: Feature<Polygon | MultiPolygon>) => {
      const result1 = FieldsList.fields.some((model) => booleanIntersects(model.feature, poly));
      const result2 = FieldsList.fields.some((model) => model.name === poly.properties[selectAttributesKey.value.name]);
      return result1 || result2;
    });

    const uploadFile = async () => {
      const formData = new FormData();
      uploadIdentifier = uuid();

      // @ts-ignore
      formData.append('file', fileList.value[0].raw);
      // @ts-ignore
      formData.append('identifier', uploadIdentifier);
      await ApiService.struct.postUpload(formData);

      isOpenAddDialog.value = false;
      useApp().setLoadingScreen('upload-fields', 'Проверка файла');

      const response = await ApiService.struct.getUploadParams(uploadIdentifier);
      attributes.value = response.data.attributes;
      const { data } = await ApiService.struct.getUploadGeoJSON(uploadIdentifier);
      featureCollection.value = { ...data.geojson };
      showDialogAttributes.value = true;
      useApp().setLoadingScreen('upload-fields');
    };

    const checkUniqueName = (): number => {
      const setList = new Set();
      featureCollection.value.features.forEach((feature) => {
        setList.add(feature.properties[selectAttributesKey.value.name]);
      });
      return setList.size - featureCollection.value.features.length;
    };

    const checkValidFile = computed(() => {
      //
      const result1 = Object.values(selectAttributesKey.value).every((v: string) => v.length);
      const result2 = featureCollection.value.features.filter((p) => computedIsIntersection.value(p));

      const result3 = checkUniqueName();

      return [
        result1,
        !result2.length,
        !result3];
    });

    const deleteFeature = (index: number) => {
      featureCollection.value.features = featureCollection.value.features.filter((_, i) => index !== i);
    };

    const saveFile = async () => {
      const formData = new FormData();
      // @ts-ignore
      formData.append('farmunit', StructList.activeStruct.value?.id.toString());
      featureCollection.value.features = featureCollection.value.features.filter((p) => !computedIsIntersection.value(p));
      const nameFile = `${uuid()}.json`;
      const file = await handleFileToBase64(new File([JSON.stringify(featureCollection.value)], nameFile, {
        type: 'application/json',
      }));

      // @ts-ignore
      formData.append('file', file);
      const data = {
        file: `data:application/json;name,${nameFile};base64,${file}`,
        rules: {
          allow_edit: true,
        },
        config: {
          name_attr: selectAttributesKey.value.name,
          descr_attr: selectAttributesKey.value.description,
          sq_acc_attr: selectAttributesKey.value.area_ha,
          rating_attr: 'rating',
        },
        farmunit: structId.value,
        season_year: SeasonList.activeSeason.value.id,
      };

      formData.append('season_year', String(data.season_year));
      await ApiService.struct.postAddFields(data).catch((e) => {
        fileList.value = [];
      });
      await FieldsList.fetchFields(structId.value, true);
      mapModel.value?.events.emitFieldUpdated();

      fileList.value = [];
      showDialogAttributes.value = false;
      emit('close');
    };

    const handleExceed: UploadProps['onExceed'] = (files) => {
      upload.value!.clearFiles();
      const file = files[0] as UploadRawFile;
      file.uid = genFileId();
      upload.value!.handleStart(file);
    };

    return {
      isOpenAddDialog,
      showDialogAttributes,
      fileList,
      blockUploadFile,
      saveFile,
      deleteFeature,
      checkValidFile,
      uploadFile,
      selectAttributesKey,
      attributes,
      computedIsIntersection,
      featureCollection,
      handleExceed,
      Upload,
      FieldPreviewModel,
    };
  },
});
</script>
