/** Генерирует по заданым координатам поля модель Preview для последующего отображения Preview поля в формате SVG. */

import { Position } from 'geojson';
import { mapLLToWebUTM } from '@/utils/mapLLToWebUTM';

export class FieldPreviewModel {
  private readonly coordinates: string[][][];

  private readonly viewBox: string;

  private readonly width: number;

  private readonly height: number;

  constructor(coordinates: Position[][][]) {
    const { normalizedCoordinates, bounds } = this.processCoordinates(coordinates);
    const {
      polygons, viewBox, width, height,
    } = this.precomputeSize(normalizedCoordinates, bounds);

    this.coordinates = polygons;
    this.viewBox = viewBox;
    this.width = width;
    this.height = height;
  }

  getSvgData() {
    return {
      polygons: this.coordinates,
      viewBox: this.viewBox,
      width: this.width,
      height: this.height,
    };
  }

  private processCoordinates(coordinates: Position[][][]) {
    let minX = Infinity; let
      maxX = -Infinity;
    let minY = Infinity; let
      maxY = -Infinity;
    const hk = 1000000;

    const webMercatorCoords = coordinates.map((polygons) => polygons.map((polygon) => polygon.map(([lon, lat]) => {
      const { x, y } = mapLLToWebUTM(lat, lon);
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
      return [x, y];
    })));

    const normalizedCoordinates = webMercatorCoords.map((polygons) => polygons.map((polygon) => polygon.map(([x, y]) => [
      (x - minX) * hk,
      (maxY - y) * hk,
    ])));

    return {
      normalizedCoordinates,
      bounds: {
        width: (maxX - minX) * hk,
        height: (maxY - minY) * hk,
      },
    };
  }

  private precomputeSize(
    normalizedCoordinates: number[][][][],
    bounds: { width: number; height: number },
  ) {
    const baseSize = 100;
    const sizeCoefficient = baseSize / Math.max(bounds.width, bounds.height);

    return {
      polygons: normalizedCoordinates.map((polygons) => polygons.map((polygon) => {
        const optimizedPolygon: string[] = [];
        let prevPoint: string | null = null;

        polygon.forEach(([x, y]) => {
          const point = `${Math.round(x * sizeCoefficient)},${Math.round(y * sizeCoefficient)}`;
          if (point !== prevPoint) {
            optimizedPolygon.push(point);
            prevPoint = point;
          }
        });

        return optimizedPolygon;
      })),
      viewBox: `0 0 ${Math.round(bounds.width * sizeCoefficient)} ${Math.round(bounds.height * sizeCoefficient)}`,
      width: Math.round(bounds.width * sizeCoefficient),
      height: Math.round(bounds.height * sizeCoefficient),
    };
  }
}
