import { MapLayerModel } from '@/models/map/Layers/MapLayerModel';
import { IMapLayerModel } from '@/models/map/Interfaces/IMapLayerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import type { MapModel } from '@/models/map/MapModel';
import { MapInputType } from '@/constants/types/map/MapInputType';
import { MapAnchorEnum } from '@/constants/enums/MapAnchorEnum';
import { GeoJSONSource, MapMouseEvent } from 'mapbox-gl';
import {
  Feature, FeatureCollection, Point, Position,
} from 'geojson';
import { LineStringModel } from '@/models/geojson/LineStringModel';

export class MapLayerLineModel extends MapLayerModel implements IMapLayerModel {
  get data(): LineStringModel {
    return this._data;
  }

  private _data: LineStringModel

  private activeState: 'inActive' | 'active' |'drag' = 'active'

  constructor(type: MapLayerTypeEnum, mapModel: MapModel, input: MapInputType) {
    super(mapModel, type, 'ruler', input.uuid);
    this._data = input as LineStringModel;
    this.createSourceLayer();
    this.layerIds.push(...[this.layerId, `${this.layerId}-points`]);
    this.sourceIds.push(this.sourceId);
    this._mapModel.map.on('mouseenter', `${this.layerId}-points`, (e) => {
      this._mapModel.map.getCanvas().style.cursor = 'pointer';
    });
    this._mapModel.map.on('mouseleave', `${this.layerId}-points`, (e) => {
      this._mapModel.map.getCanvas().style.cursor = '';
    });

    this._mapModel?.map?.on('click', this.handlerClick);
  }

  refreshLayer(): void {
    const featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [
        this._data.toFeature(),
        ...this._data.data.map((a) => a.toFeature()),
      ],
    };
    const source: GeoJSONSource = this._mapModel?.map?.getSource(this.sourceId) as GeoJSONSource;
    source.setData(featureCollection);
  }

  createSourceLayer() {
    this._mapModel?.map?.addSource(this.sourceId, {
      type: 'geojson',
      data: this._data.toFeature(),
    }).addLayer(
      {
        id: `${this.layerId}-points`,
        type: 'circle',
        source: this.sourceId,
        paint: {
          'circle-radius': 5,
          'circle-color': '#6a4c93',
        },
        filter: ['in', '$type', 'Point'],
      },
    ).addLayer({
      id: this.layerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#6a4c93',
        'line-width': 3.5,
      },
      filter: ['in', '$type', 'LineString'],
    });

    this._mapModel?.map?.moveLayer(this.layerId, MapAnchorEnum.RULER);
    this._mapModel?.map?.moveLayer(`${this.layerId}-points`, MapAnchorEnum.RULER);
  }

  handlerClick = (e: MapMouseEvent) => {
    if (this.activeState === 'inActive') return;
    const features = this._mapModel?.map?.queryRenderedFeatures(e.point, {
      layers: [`${this.layerId}-points`],
    });
    if (features?.length) {
      this._data.deletePosition(features[0]?.id as number || 0);
    } else {
      this._data.addPosition([e.lngLat.lng, e.lngLat.lat]);
    }
    this.refreshLayer();
  }

  removeSubscribeEvents(): void {
    this._mapModel.map.off('click', this.handlerClick);
  }
}
