<template lang="pug" src="./SeasonForm.pug"/>
<style lang="scss" src="./SeasonForm.scss"/>

<script lang="ts">
import {
  computed, defineComponent, PropType, ref, watch,
} from 'vue';
import { SeasonModel } from '@/models/season/SeasonModel';
import ApiService from '@/services/api/ApiService';
import CropRotationList from '@/modules/cropRotation/CropRotationList';
import { formatQueryDate } from '@/utils/formatQueryDate';
import { ElNotification } from 'element-plus';
import { useApp } from '@/composables/useApp';
import SeasonList from '@/modules/season/SeasonList';
import FieldsList from '@/modules/fields/FieldsList';

export default defineComponent({
  name: 'SeasonForm',
  props: {
    season: {
      type: Object as PropType<SeasonModel | null>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const isCopyField = ref(false);
    const editingSeason = ref(
      {
        id: props.season?.id || 0,
        prevName: props.season?.name || '',
        name: props.season?.name || '',
        start: props.season?.startDate || new Date(),
        end: props.season?.endDate || new Date(),
        note: props.season?.note || '',
      },
    );

    const deleteSeason = async () => {
      useApp().setLoadingScreen('rotation-season-form-delete', 'Удаление данных о сезоне');
      await ApiService.cropRotation.deleteSeason(editingSeason.value.id);

      emit('close');
      setTimeout(async () => {
        await SeasonList.fetch();
      });
      useApp().setLoadingScreen('rotation-season-form-delete');
    };

    const copyFields = async (id: number) => {
      const data = {
        season: id,
        items: FieldsList.fields.map((a) => ({ id: a.id })),
      };

      await ApiService.struct.postCopyFields(data);
    };

    const saveSeason = async () => {
      if (editingSeason.value.id) {
        useApp().setLoadingScreen('rotation-season-form-save', 'Обновление данных о сезоне');

        await ApiService.cropRotation.updateSeason(editingSeason.value.id, {
          name: editingSeason.value.name,
          period_start: formatQueryDate(editingSeason.value.start as Date),
          period_stop: formatQueryDate(editingSeason.value.end as Date),
          note: editingSeason.value.note,
        });
        await SeasonList.fetch();
        emit('close');
        useApp().setLoadingScreen('rotation-season-form-save');
      } else if (editingSeason.value.start && editingSeason.value.end && editingSeason.value.name) {
        useApp().setLoadingScreen('rotation-season-form-save', 'Добавление нового сезона');
        try {
          const { data } = await ApiService.cropRotation.addSeason({
            name: editingSeason.value.name,
            start: editingSeason.value.start as Date,
            stop: editingSeason.value.end as Date,
            note: editingSeason.value.note,
          });
          await SeasonList.fetch();
          if (isCopyField.value) {
            setTimeout(() => {
              copyFields(data.id);
            }, 2000);
          }
          emit('close');
        } finally {
          useApp().setLoadingScreen('rotation-season-form-save');
        }
      } else {
        ElNotification(
          {
            message: 'Ошибка формата данных формы.',
            type: 'error',
            position: 'bottom-right',
          },
        );
      }
    };

    const disabledDate = ((date: Date) => {
      if (!editingSeason.value.start) {
        return true;
      }

      return false;
    });

    const nameError = computed(() => {
      if (SeasonList.seasons.value.find((s) => s.id !== editingSeason.value.id && s.name === editingSeason.value.name)) {
        return 'Сезон с таким наименованием уже существует.';
      }
      if (editingSeason.value.name === '') {
        return 'Поле не может быть пустым';
      }

      return '';
    });

    watch(editingSeason, (v) => {
      if (!v.name && v.start) {
        v.name = (v.start as Date).getFullYear().toString();
      }
      if (v.start > v.end) {
        v.end = v.start;
      }
    }, { deep: true });

    const title = computed(() => (!editingSeason.value.id ? 'Добавить новый сезон' : editingSeason.value?.prevName || ''));

    return {
      title,
      editingSeason,
      deleteSeason,
      saveSeason,
      disabledDate,
      nameError,
      isCopyField,
    };
  },
});
</script>
