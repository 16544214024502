import { Feature, LineString, Position } from 'geojson';
import getLinesIntersection from '@/lib/map/getLinesIntersection';
import { sortCoordinatesByLine } from '@/lib/map/sortCoordinatesByLine';

export const findCurveIntersections = (curve1: Feature<LineString>, curve2: Feature<LineString>): Position[] => {
  const intersections: Position[] = [];

  const _curve1: Position[] = curve1.geometry.coordinates;
  const _curve2: Position[] = curve2.geometry.coordinates;
  for (let i = 0; i < _curve1.length - 1; i++) {
    for (let j = 0; j < _curve2.length - 1; j++) {
      const intersection = getLinesIntersection(_curve1[i], _curve1[i + 1], _curve2[j], _curve2[j + 1]);
      if (intersection) {
        intersections.push(intersection);
      }
    }
  }
  return intersections;
};
