import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "FieldCombiningBlock flex-col flex-col-justify-between" }
const _hoisted_2 = { class: "flex-row flex-row-justify-between flex-row-align-center" }
const _hoisted_3 = { class: "flex-row flex-row-justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldView = _resolveComponent("FieldView")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.name
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FieldView, { field: field }, null, 8, ["field"]),
            _createElementVNode("div", null, _toDisplayString(field.name), 1)
          ])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('save')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}