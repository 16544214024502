import { Model } from '@/models/Model';
import { SeasonDto } from '@/services/api/dto/struct/SeasonDto';
import ApiService from '@/services/api/ApiService';
import { JsonType } from '@/constants/types/JsonType';

export class SeasonModel extends Model {
  private readonly _id: number;

  constructor(seasonDto: SeasonDto) {
    super();
    seasonDto.id && (this._id = seasonDto.id);
    seasonDto.name && (this._name = seasonDto.name);
    seasonDto.period_start && (this._startDate = new Date(seasonDto.period_start));
    seasonDto.period_stop && (this._endDate = new Date(seasonDto.period_stop));
    seasonDto.note && (this._note = seasonDto.note);
  }

  get id(): number {
    return this._id;
  }

  private _name: string;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  private _startDate: Date;

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(value: Date) {
    this._startDate = value;
  }

  private _endDate: Date;

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(value: Date) {
    this._endDate = value;
  }

  private _note: string;

  get note(): string {
    return this._note;
  }

  set note(value: string) {
    this._note = value;
  }

  async save(): Promise<void> {
    const { data } = await ApiService.season.updateSeason(this);
    this.note = data.note;
    this.startDate = new Date(data.period_start);
    this.endDate = new Date(data.period_stop);
    this.name = data.name;
  }

  async delete(): Promise<void> {
    await ApiService.season.deleteSeason(this);
  }

  toJSON(): JsonType {
    return {
      id: this._id,
      name: this._name,
      period_start: this._startDate.toISOString(),
      period_stop: this._endDate.toISOString(),
      note: this._note,
    };
  }
}
