import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "SkeletonLoader"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_ctx.active)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "SkeletonLoader-message",
          innerHTML: _ctx.message
        }, null, 8, _hoisted_2),
        _createVNode(_component_el_skeleton, {
          rows: 5,
          animated: ""
        })
      ]))
    : _createCommentVNode("", true)
}