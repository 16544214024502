import { Position } from 'geojson';

export const removeDuplicatesCordsByPolygon = (points: Position[]): Position[] => {
  if (points.length <= 2) return points;

  const uniquePoints: Position[] = [];
  const seen = new Set<string>();

  for (let i = 0; i < points.length - 1; i++) {
    const key = points[i].toString();
    if (!seen.has(key)) {
      seen.add(key);
      uniquePoints.push(points[i]);
    }
  }
  uniquePoints.push(points[0]);

  return uniquePoints;
};
