import {
  Feature, LineString, Position,
} from 'geojson';
import { nearestPointOnLine, point } from '@turf/turf';

export const sortCoordinatesByLine = (_coords: Position[], _line: Feature<LineString>): Position[] => _coords
  .map((pt) => {
    const snappedPoint = nearestPointOnLine(_line, point(pt)); // Ищем ближайшую точку на линии
    return { pt, dist: snappedPoint.properties.location }; // Запоминаем положение точки на линии
  })
  .sort((a, b) => a.dist - b.dist) // Сортируем по положению на линии
  .map((obj) => obj.pt);
