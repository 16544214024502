import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "mt-10 fw"
}
const _hoisted_5 = { class: "flex-row flex-align-center flex-row-justify-between fw" }
const _hoisted_6 = {
  key: 0,
  class: "POIEditBlock_Visited"
}
const _hoisted_7 = { class: "POIEditBlock" }
const _hoisted_8 = { class: "POIEditBlock_Body" }
const _hoisted_9 = { class: "flex-row flex-row-justify-start flex-align-center mt-16" }
const _hoisted_10 = {
  key: 0,
  class: "mb-15"
}
const _hoisted_11 = { class: "form-header" }
const _hoisted_12 = { class: "flex-row flex-align-center flex-row-justify-between fw" }
const _hoisted_13 = { class: "form-dl" }
const _hoisted_14 = { class: "form-header mt-15" }
const _hoisted_15 = { class: "form-dl" }
const _hoisted_16 = { class: "form-header mt-16" }
const _hoisted_17 = { class: "POIEditBlock_BodyIMGlist" }
const _hoisted_18 = { class: "POIEditBlock_BodyIMGlist-item" }
const _hoisted_19 = { class: "image-slot" }
const _hoisted_20 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_EditPen = _resolveComponent("EditPen")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_tooltip = _resolveComponent("el-tooltip")
  const _component_Place = _resolveComponent("Place")
  const _component_Delete = _resolveComponent("Delete")
  const _component_el_button_group = _resolveComponent("el-button-group")
  const _component_el_alert = _resolveComponent("el-alert")
  const _component_Picture = _resolveComponent("Picture")
  const _component_el_image = _resolveComponent("el-image")
  const _component_ui_icon = _resolveComponent("ui-icon")
  const _component_RightPanel = _resolveComponent("RightPanel")
  const _component_PoiEditDialog = _resolveComponent("PoiEditDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RightPanel, { closeable: false }, {
      title: _withCtx(() => [
        (!_ctx.active && !_ctx.poiMoveMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t(`${'edit-poi'}`, 'edit-poi', { ns: 'poi' })), 1))
          : _createCommentVNode("", true),
        (_ctx.poiMoveMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t(`${'move-poi'}`, 'move-poi', { ns: 'poi' })), 1))
          : _createCommentVNode("", true)
      ]),
      actions: _withCtx(() => [
        (!_ctx.active && !_ctx.poiMoveMode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_el_button_group, { round: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, null, {
                      content: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-to-edit-poi'}`, 'tooltip-to-edit-poi', { ns: 'poi' })), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          plain: "",
                          round: "",
                          onClick: _ctx.activeEdit
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_EditPen)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_tooltip, null, {
                      content: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-to-move-poi'}`, 'tooltip-to-move-poi', { ns: 'poi' })), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          plain: "",
                          round: "",
                          onClick: _ctx.dragPoint
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Place)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_tooltip, null, {
                      content: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${'tooltip-delete-poi'}`, 'tooltip-delete-poi', { ns: 'poi' })), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          onClick: _ctx.deletePOI,
                          round: "",
                          type: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Delete)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.poi?.visited)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t(`${'visited-poi'}`, 'visited-poi', { ns: 'poi' })), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.poiMoveMode)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_alert, {
                    type: "info",
                    closable: false,
                    "show-icon": ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`${'move-poi-manual'}`, 'move-poi-manual', { ns: 'poi' })), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _ctx.saveMove
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${'save'}`, 'save', )), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_el_button, { onClick: _ctx.cancelMove }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(`${'cancel'}`, 'cancel', )), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ], 64))
              : _createCommentVNode("", true),
            (!_ctx.poiMoveMode && !_ctx.active)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.poi?.description)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.poi?.description), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t(`${'params'}`, 'params', { ns: 'poi' })), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("small", null, "LAT: " + _toDisplayString(Math.round(_ctx.poi?.y * 10000000) / 10000000) + " LNG: " + _toDisplayString(Math.round(_ctx.poi?.x * 10000000) / 10000000), 1),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      link: "",
                      onClick: _ctx.copyToClipboard
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('copy', 'copy')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _createElementVNode("dl", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedParams, (item) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (item.category ==='limit-factor' && Number(item.value) !== 0)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (Number(item.value))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })) + ":", 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.$t(`${item.key}-${item.value}`, `${item.key}-${item.value}`, { ns: 'poi' })), 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 64))
                          : (item.key !== 'n_prob')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("dt", null, _toDisplayString(_ctx.$t(`${item.key}`, item.key, { ns: 'poi' })) + ":", 1),
                                _createElementVNode("dd", null, _toDisplayString(_ctx.formatValue(item.value)) + " " + _toDisplayString(item.unit), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                      ], 64))
                    }), 256))
                  ]),
                  (_ctx.computedAdditionalParams.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('additional-parameters', 'additional-parameters', { ns: 'poi' })), 1),
                        _createElementVNode("dl", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedAdditionalParams, (item) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (_ctx.formatValue(item.value))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                    _createElementVNode("dt", null, _toDisplayString(item.key) + ":", 1),
                                    _createElementVNode("dd", null, _toDisplayString(_ctx.formatValue(item.value)), 1)
                                  ], 64))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.imgList.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('photo', 'photo', { ns: 'poi' })), 1),
                        _createElementVNode("div", _hoisted_17, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imgList, (item, idx) => {
                            return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                              _createVNode(_component_el_image, {
                                src: item.src,
                                "preview-src-list": _ctx.imgStrings,
                                alt: item.name,
                                "initial-index": idx,
                                "hide-on-click-modal": "",
                                "close-on-press-escape": "",
                                "preview-teleported": ""
                              }, {
                                placeholder: _withCtx(() => []),
                                error: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_19, [
                                    _createVNode(_component_Picture)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["src", "preview-src-list", "alt", "initial-index"]),
                              _createElementVNode("div", {
                                class: "POIEditBlock_BodyIMGlist-item-delete",
                                onClick: $event => (_ctx.deletePhoto(item))
                              }, [
                                _createVNode(_component_ui_icon, {
                                  size: 17,
                                  name: "Delete"
                                })
                              ], 8, _hoisted_20)
                            ]))
                          }), 256))
                        ])
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (!_ctx.poiMoveMode && _ctx.active)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [], 64))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.active)
        ? (_openBlock(), _createBlock(_component_PoiEditDialog, {
            key: 0,
            onClose: _cache[0] || (_cache[0] = $event => (_ctx.active = false))
          }))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}