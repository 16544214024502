<template lang="pug" src="./FieldsPanel.pug"/>
<style lang="scss" src="./FieldsPanel.scss"/>

<script lang="ts">
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useStorage } from '@/composables/useStorage';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { StorageKeyEnum } from '@/constants/enums/StorageKeyEnum';
import { FieldModel } from '@/models/field/FieldModel';
import { MapLayerFieldsModel } from '@/models/map/Layers/MapLayerFieldsModel';
import FieldsEvents from '@/modules/fields/FieldsEvents';
import FieldDrawBlock from '@/modules/fields/ui/FieldDrawBlock/FieldDrawBlock.vue';
import PermissionsList from '@/modules/permissions/PermissionsList';
import ApiService from '@/services/api/ApiService';
import LoadingStatus from '@/services/loading/LoadingStatus';
import StorageService from '@/services/storage/StorageService';
import {
  Plus, Search, Select, SemiSelect, Tools, Upload,
} from '@element-plus/icons-vue';
import { ElMessageBox, UploadProps } from 'element-plus';
import {
  computed, defineComponent, onMounted, PropType, ref, Slots, useSlots, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTranslate } from '@tolgee/vue';
import { SatelliteCounterDto } from '@/services/api/dto/satellites/SatelliteCounterDto';
import { SatelliteCounterStatusEnums } from '@/constants/enums/SatelliteCounterStatusEnums';
import { formatSecondsToTime, timerLeft, timerPercentage } from '@/utils/timerFunctions';
import { useDrawer } from '@/composables/useDrawer';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { useStruct } from '@/composables/useStruct';
import FieldsList from '@/modules/fields/FieldsList';
import { FieldsDto } from '@/services/api/dto/gis/FieldsDto';
import SeasonList from '@/modules/season/SeasonList';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import FieldUploadDialog from '@/modules/fields/ui/FieldUploadDialog/FieldUploadDialog.vue';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import { booleanIntersects } from '@turf/turf';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';

type LabelStructKeysType = 'name' | 'name_rus' | 'square';

export default defineComponent({
  name: 'MapFieldsSelector',
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      default: MapContainerEnum.MAIN_MAP,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UiDialog,
    UiIcon,
    SidePanelContent,
    FieldView,
    Select,
    SemiSelect,
    Tools,
    Upload,
    FieldDrawBlock,
    Plus,
    NotFound,
    FieldUploadDialog,
  },
  emits: ['close', 'selected', 'select', 'notfound'],
  setup(props) {
    const slots = useSlots();

    const {
      fields,
      activeField,
      selectedFields,
      hasSelectedField,
      isFieldActive,
      isDisabledField,
      hoverField,
      selectState,
      setHoverField,
      fitField,
      informationMode,
      maxSelectFields,
      cleanSelectedFields,
      selectAllFields,
      mapModel,
      compareMap,
      mapContainerMode,
      setActiveField,
    } = useMapContainers(props.mapContainer);

    const { getMapText } = useStorage();
    const { t } = useTranslate();
    const { activeDrawer, drawerMode } = useDrawer();
    const { structId } = useStruct();
    const route = useRoute();
    const router = useRouter();
    const _fieldsT = useTranslate('fields');

    const top = ref(0);

    const searchRow = ref('');

    const fieldsListRef = ref();

    const topIndex = ref(0);

    const rowsCount = ref(50);
    const isSatellite = ref(false);
    const isOpenCopyField = ref(false);
    const isOpenUploadDialog = ref(false);
    const labelStruct = ref({
      name: true,
      name_rus: true,
      square: true,
    });

    const showAddGeometry = ref();

    watch(route, (a) => {
      showAddGeometry.value = route.name === 'struct';
      isSatellite.value = route.name === 'satellite';
    });

    const computedHeight = computed(() => `${selectState.value === 'multiple' ? '60' : '40'}px`);

    const computedSearchStyle = computed(() => ({ height: computedHeight.value }));

    const computedBodyStyle = computed(() => ({
      height: `calc(100% - 40px - 30px  - ${computedHeight.value} )`,
      'max-height': `calc(100% - 40px - 30px - ${computedHeight.value} )`,
    }));

    const hasSlot = (slot: string) => {
      if (!slots[slot]) return false;
      // @ts-ignore
      const elements: Slots[] = (slots[slot] && slots[slot]()) || [];
      return elements.filter((f) => f.type?.toString() !== 'Symbol(Comment)').length > 0;
    };

    const hasNotice = computed(() => hasSlot('notice'));

    const hasIcon = computed(() => hasSlot('icon'));

    const onMouseEnter = (fieldId: number) => {
      if (isDisabledField(fieldId)) return;
      setHoverField(fieldId);
    };

    const getLabelStruct = computed(() => (key: LabelStructKeysType) => labelStruct.value[key]);

    const onMouseLeave = () => { setHoverField(undefined); };

    const computedShortFields = computed<{id: number, name: string, sq: number, field: FieldModel, counter: SatelliteCounterDto | undefined}[]>(() => fields.value.map((f: FieldModel) => ({
      id: f.id, name: f.name, sq: f.sq, field: f, counter: f.counter,
    })));

    const computedFields = computed(() => (searchRow.value.length === 0 ? computedShortFields.value : computedShortFields.value.filter((f) => f.name.indexOf(searchRow.value) !== -1)));

    const slicedFields = computed(() => computedFields.value.slice(topIndex.value, topIndex.value + rowsCount.value + 1));

    const calculateTopIndex = () => {
      topIndex.value = Math.min(
        Math.floor((fieldsListRef.value as HTMLElement).scrollTop / 40),
        Math.abs((computedFields.value.length - rowsCount.value - 1)),
      );
    };
    window.addEventListener('resize', calculateTopIndex);

    const scrollTop = () => { (fieldsListRef.value as HTMLElement).scrollTop = 0; };

    const emitSelectField = (fieldId: number) => {
      FieldsEvents.emitClick(fieldId, props.mapContainer);
      if (selectState.value === 'single') {
        const field = fields.value.find((a) => a.id === fieldId);
        fitField(field, false, 'main');
      }
    };

    const calculateRowsCount = () => {
      if (fieldsListRef.value?.clientHeight > 0) {
        rowsCount.value = Math.ceil(fieldsListRef.value?.clientHeight / 40);
      } else {
        rowsCount.value = 40;
      }
    };

    window.addEventListener('resize', calculateRowsCount);

    const featureClassList = computed(() => (field: FieldModel) => ({
      selected: hasSelectedField(field?.id),
      active: isFieldActive(field?.id),
      selectActive: selectedFields.value.some((a) => a.id === field.id),
      hover: field.id === hoverField.value?.id,
    }));

    watch(fieldsListRef, calculateRowsCount);

    const openDrawBlock = () => {
      if (showAddGeometry.value) {
        activeDrawer.value = true;
        setActiveField(undefined);
        informationMode.value = 'addField';
        mapContainerMode.value = 'editField';
        return;
      }
      ElMessageBox.confirm(
        _fieldsT.t.value('warning-add-filed'),
        t.value('warning'),
        {
          confirmButtonText: t.value('accept'),
          cancelButtonText: t.value('cancel'),
          type: 'warning',
        },
      )
        .then(() => {
          mapContainerMode.value = 'editField';
          informationMode.value = 'addField';
          activeDrawer.value = true;
          setActiveField(undefined);
          router.push({
            name: 'struct',
          });
        });
    };
    const handleExceed: UploadProps['onExceed'] = (files) => {
      // upload.value!.clearFiles();
      // const file = files[0] as UploadRawFile;
      // file.uid = genFileId();
      // upload.value!.handleStart(file);
    };

    const save = async () => {
      if (typeof getMapText.value.name !== 'undefined' && !props.isCompare) {
        await StorageService.updateItem(StorageKeyEnum.MAPTEXT, labelStruct.value);
      } else {
        await StorageService.setItem(StorageKeyEnum.MAPTEXT, labelStruct.value);
      }
    };

    const toggleLabelStruct = async (key: LabelStructKeysType) => {
      labelStruct.value[key] = !labelStruct.value[key];
      await save();
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS);
      const layerCompare = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS);
      if (layer !== undefined) {
        (layer as MapLayerFieldsModel).labelsShow = labelStruct.value;
      }
      if (layerCompare !== undefined) {
        (layerCompare as MapLayerFieldsModel).labelsShow = labelStruct.value;
      }
      FieldsEvents.emitFeatureUpdated();
    };

    onMounted(() => {
      showAddGeometry.value = route.name === 'struct';
      isSatellite.value = route.name === 'satellite';
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, props.mapContainer).then(() => {
        LoadingStatus.awaitLoad(LoadingNamesEnum.STORAGE).then(() => {
          labelStruct.value = getMapText.value;
          const layer = mapModel.value?.getLayer(MapLayerTypeEnum.FIELDS);
          if (layer !== undefined) {
            (layer as MapLayerFieldsModel).labelsShow = labelStruct.value;
            FieldsEvents.emitFeatureUpdated();
          }
        });
      });
      LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, `${props.mapContainer}-compare`).then(() => {
        const layerCompare = compareMap.value?.getLayer(MapLayerTypeEnum.FIELDS);

        if (layerCompare !== undefined) {
          (layerCompare as MapLayerFieldsModel).labelsShow = labelStruct.value;
          FieldsEvents.emitFeatureUpdated();
        }
      });
    });

    const computedProgressTitle = computed(() => (counter: SatelliteCounterDto) => {
      if (counter?.status.progress === 1) {
        return 'Ожидание в очереди';
      } if (counter?.status.progress === 10) {
        if (counter?.status.search === 10 || counter?.status.search === 1) return 'Поиск снимков';
        if (counter?.status.download === 10 || counter?.status.download === 1) return 'Загрузка снимков';
        if (counter?.status.rgb === 10 || counter?.status.rgb === 1) return 'Обработка снимков';
        if (counter?.status.estimation === 10 || counter?.status.estimation === 1) return 'Анализ снимков';
      }

      return 'Обработка задачи';
    });

    watch(() => props.active, (a) => {
      if (a) {
        topIndex.value = 0;
      }
    });

    const openCopyDialog = () => {
      isOpenCopyField.value = true;
    };

    const counterClass = computed(() => (counter: SatelliteCounterDto | undefined) => ({
      [`--${(counter?.amount_color) || 'red'}`]: true,
    }));

    const selectedYear = ref<number>();
    const seasonsFields = ref<Record<number, FieldsDto>>({});

    watch(selectedYear, async (season) => {
      if (!(season in seasonsFields.value)) {
        const { data } = await ApiService.gis.getUnitFields({ unitId: structId.value, season });
        seasonsFields.value[season] = data;
      }
    });

    const searchCopyRow = ref('');
    const computedIsIntersection = computed(() => (poly: Feature<Polygon | MultiPolygon>) => {
      const result1 = FieldsList.fields.some((model) => booleanIntersects(model.feature, poly));
      const result2 = FieldsList.fields.some((model) => model.name === poly.properties.name);
      return result1 || result2;
    });
    const computedCopyFields = computed(() => seasonsFields.value[selectedYear.value?.toString()]?.features.filter((f) => f.properties.name.includes(searchCopyRow.value)) || []);

    const slectedFieldsIdToCopy = ref<number[]>([]);

    const hasSelectedFieldToCopy = computed(() => (id: number) => slectedFieldsIdToCopy.value.some((_id: number) => _id === id));

    const emitSelectFieldToCopy = (id: number) => {
      const findIndex = slectedFieldsIdToCopy.value.findIndex((e) => e === id);
      if (findIndex !== -1) {
        slectedFieldsIdToCopy.value.splice(findIndex, 1);
      } else {
        slectedFieldsIdToCopy.value.push(id);
      }
    };

    const saveCopyFields = async () => {
      const data = {
        season: SeasonList.activeSeason.value.id,
        items: slectedFieldsIdToCopy.value.map((a) => ({ id: a })),
      };

      await ApiService.struct.postCopyFields(data);
      await FieldsList.fetchFields(structId.value, true);
      mapModel.value?.events.emitFieldUpdated();
      isOpenCopyField.value = false;
    };

    return {
      fieldsListRef,
      searchRow,
      activeField,
      selectedFields,
      hasSelectedField,
      featureClassList,
      onMouseEnter,
      onMouseLeave,
      hasNotice,
      hasIcon,
      top,
      Search,
      hoverField,
      emitSelectField,
      selectState,
      isDisabledField,
      fields,
      calculateTopIndex,
      topIndex,
      rowsCount,
      computedFields,
      slicedFields,
      scrollTop,
      informationMode,
      maxSelectFields,
      selectAllFields,
      cleanSelectedFields,
      Select,
      SemiSelect,
      Tools,
      handleExceed,
      Upload,
      activeDrawer,
      openDrawBlock,
      PermissionsList,
      getLabelStruct,
      toggleLabelStruct,
      showAddGeometry,
      isSatellite,
      SatelliteCounterStatusEnums,
      timerPercentage,
      formatSecondsToTime,
      timerLeft,
      computedProgressTitle,
      counterClass,
      drawerMode,
      Plus,
      openCopyDialog,
      isOpenCopyField,
      SeasonList,
      selectedYear,
      saveCopyFields,
      FieldsList,
      emitSelectFieldToCopy,
      hasSelectedFieldToCopy,
      seasonsFields,
      slectedFieldsIdToCopy,
      searchCopyRow,
      computedCopyFields,
      isOpenUploadDialog,
      computedIsIntersection,
      FieldPreviewModel,
      computedSearchStyle,
      computedBodyStyle,
    };
  },
});
</script>
