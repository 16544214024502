<template lang="pug" src="./MonitoringPanel.pug"/>
<style lang="scss" src="./MonitoringPanel.scss"/>

<script lang="ts">
import Content from '@/components/shared/Content/Content.vue';
import IndexesInfo from '@/components/shared/IndexesInfo/IndexesInfo.vue';
import SidePanelContent from '@/components/shared/SidePanelContent/SidePanelContent.vue';
import TaskMapSelector from '@/components/shared/TaskMapSelector/TaskMapSelector.vue';
import NoActiveField from '@/components/ui/NoActiveField/NoActiveField.vue';
import NotFound from '@/components/ui/NotFound/NotFound.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { useMonitoring } from '@/composables/useMonitoring';
import { FileIndexKindEnums } from '@/constants/enums/FileIndexKindEnums';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MonitoringIndexKindType } from '@/constants/types/monitoring/MonitoringIndexKindType';
import { FieldIndexMonitoringModel } from '@/models/field/FieldIndexMonitoringModel';
import { FieldTaskMapBaseModel } from '@/models/field/FieldTaskMapBaseModel';
import { FieldTaskMapWorkModel } from '@/models/field/FieldTaskMapWorkModel';
import { MapLayerIndexModel } from '@/models/map/Layers/MapLayerIndexModel';
import { MapLayerNirModel } from '@/models/map/Layers/MapLayerNirModel';
import LoadingEvents from '@/services/loading/LoadingEvents';

import LoadingStatus from '@/services/loading/LoadingStatus';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import { formatRuDate } from '@/utils/formatRuDate';
import { ElNotification } from 'element-plus';
import {
  computed, defineComponent, onMounted, PropType, ref, watch, watchEffect,
} from 'vue';
import { FieldNirModel } from '@/models/field/FieldNirModel';
import { FieldTaskMapModel } from '@/models/field/FieldTaskMapModel';
import ApiService from '@/services/api/ApiService';
import { MapLayerTaskMapModel } from '@/models/map/Layers/MapLayerTaskMapModel';

export default defineComponent({
  name: 'MonitoringPanel',
  components: {
    SidePanelContent,
    TaskMapSelector,
    NoActiveField,
    Content,
    NotFound,
    IndexesInfo,
  },
  props: {
    mapContainer: {
      type: String as PropType<MapContainerEnum>,
      required: true,
    },
    isCompare: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      indexesKinds,
    } = useMonitoring();

    const mapModel = !props.isCompare ? useMapContainers(props.mapContainer).mapModel : useMapContainers(props.mapContainer).compareMap;
    const { activeField } = useMapContainers(props.mapContainer);
    const activeFile = ref<FieldNirModel>();
    const showCloudMask = ref(false);
    const showContrast = ref(false);
    const selectedMask = ref<MonitoringIndexKindType | undefined>();
    const selectedIndex = ref<MonitoringIndexKindType | undefined>();
    const showSelectFile = ref(false);
    const isShowTaskMap = ref(true);
    // const taskMapContourModel = ref<MonitoringTaskMapContourModel | undefined>();
    // const taskMapContourLayer = ref<MapLayerTaskMapContourModel>();
    const indexFile = computed<FieldIndexMonitoringModel | undefined>(() => (activeField.value?.monitoringIndexes.find((index) => index.name === selectedIndex.value?.name && index.sceneId === activeFile?.value?.scene.id)));
    const cloudsFilter = ref(false);
    const cloudMaskFile = computed<FieldIndexMonitoringModel | undefined>(() => activeField.value?.monitoringIndexes.find((f: FieldIndexMonitoringModel) => f.kind === FileIndexKindEnums.MASK && f.sceneId === activeFile.value?.scene.id));
    const loading = ref(false);
    const showNir = ref(false);
    const buffTaskModel = ref<FieldTaskMapModel | undefined>();

    const taskSelectorRef = ref();

    const computedTaskLayer = computed(() => mapModel.value?.allLayers.find((a) => ((a.data instanceof FieldTaskMapBaseModel) || (a.data instanceof FieldTaskMapWorkModel)) && (a.data as FieldTaskMapBaseModel | FieldTaskMapWorkModel)?.field === activeField.value?.id));
    const computedTaskModel = computed(() => computedTaskLayer.value?.data as (FieldTaskMapModel | undefined));

    const taskMapSettings = ref({
      fill: 1,
      opacity: 100,
      showLabels: false,
    });

    watch(computedTaskModel, (a) => {
      buffTaskModel.value = a;
    });

    const fetchOverlap = async () => {
      if (buffTaskModel.value && indexFile.value) {
        await (buffTaskModel.value as FieldTaskMapBaseModel | FieldTaskMapWorkModel).fetchData();
        const taskType = buffTaskModel.value instanceof FieldTaskMapBaseModel ? 'base' : 'work';
        const { data } = await ApiService.taskMap.getOverlap(buffTaskModel.value.id, taskType, indexFile.value.id);
        buffTaskModel.value.overlap = data;
      } else {
        buffTaskModel.value.overlap = undefined;
      }
    };

    const renderIndex = () => {
      if (mapModel.value) {
        if (indexFile.value) {
          indexFile.value.showContrast = showContrast.value;
          const layer = mapModel.value.getLayer(MapLayerTypeEnum.MONITORING_INDEX) as MapLayerIndexModel;
          if (layer) {
            layer.updateImage(indexFile.value);
          } else {
            mapModel.value.render(indexFile.value);
          }
        } else {
          mapModel.value.removeLayer(MapLayerTypeEnum.MONITORING_INDEX);
        }
      }
    };

    const renderNir = () => {
      if (mapModel.value) {
        if (!activeFile.value || !showNir.value) {
          mapModel.value.removeLayer(MapLayerTypeEnum.NIR_FILE);
        } else {
          const layer = mapModel.value.getLayer(MapLayerTypeEnum.NIR_FILE) as MapLayerNirModel;
          if (layer) {
            layer.updateImage(activeFile.value);
          } else {
            mapModel.value.render(activeFile.value);
          }
        }
      }
    };

    watch(computedTaskLayer, async () => {
      if (computedTaskModel.value) {
        (indexFile.value !== undefined)
        && await fetchOverlap();
        (computedTaskLayer.value as MapLayerTaskMapModel).update();
      }
    });

    watch(indexFile, async () => {
      if (buffTaskModel.value) {
        await fetchOverlap();
        (computedTaskLayer.value as MapLayerTaskMapModel).update();
      }
    });

    watch(indexFile, (a, b) => {
      if (!!a !== !!b) {
        renderIndex();
      }
    });
    // endregion

    watch(showContrast, renderIndex);

    watch(activeFile, () => {
      renderIndex();
      renderNir();
    });
    watch(showNir, renderNir);

    const indexMenuItemClicked = (kind: MonitoringIndexKindType) => {
      if (selectedIndex.value?.name === kind.name) {
        selectedIndex.value = undefined;
      } else if (!activeFile.value) {
        selectedIndex.value = undefined;
        ElNotification({
          title: 'Файл индекса не найден',
          message: 'Данный индекс в процессе формирования.',
          type: 'warning',
          duration: 2000,
          position: 'bottom-right',
        });
      } else {
        selectedIndex.value = kind;
        MyTrackerService.send('Select index (Indexes selector)', { name: selectedIndex.value?.name || '', label: selectedIndex.value?.label || '' });
      }
      renderIndex();
    };

    watch(activeField, async () => {
      const buffSceneId = activeFile.value?.scene.id;
      isShowTaskMap.value = false;
      loading.value = false;
      await activeField.value?.fetchMonitoringIndexes();
      await activeField.value?.fetchNirFiles();
      if ((activeField.value?.nirFiles || []).length > 0) {
        const f = activeField.value?.nirFiles.filter((file) => (cloudsFilter.value ? file.cloudiness <= 30 : true));
        activeFile.value = f.find((a) => a.scene.id === buffSceneId) || f[0];
      } else {
        activeFile.value = undefined;
      }
      LoadingStatus.awaitLoad(LoadingNamesEnum.FIELD_NIR_FILES, activeField.value?.id).then(() => {
        loading.value = true;
      });
    });

    onMounted(async () => {
      await activeField.value?.fetchMonitoringIndexes();
      await activeField.value?.fetchNirFiles();
      if (activeField.value && activeField.value.nirFiles.length > 0) {
        activeFile.value = activeField.value?.nirFiles[0];
      } else {
        activeFile.value = undefined;
      }
      loading.value = true;

      LoadingEvents.onLoadingEnds((name) => {
        if (name === LoadingNamesEnum.FIELD_NIR_FILES) {
          if (activeField.value && activeField.value.nirFiles.length > 0) {
            activeFile.value = activeField.value?.nirFiles[0];
          } else {
            activeFile.value = undefined;
          }
        }
      });
    });

    const taskMaps = computed(() => [...(activeField.value?.workTaskMaps || []), ...(activeField.value?.baseTaskMaps || [])]);

    watch(activeField, (a) => {
      a?.fetchTaskMaps();
    });

    watchEffect(() => {
      if (activeField.value !== undefined && isShowTaskMap.value) {
        activeField.value.fetchTaskMaps();
      }
    });

    const changeOpacity = (evt: MouseEvent) => {
      if (evt.target) {
        const rect = (evt.target as HTMLElement)?.getBoundingClientRect();
        taskMapSettings.value.opacity = ((evt.clientX - rect.left) * 100) / rect.width;
      }
    };

    // region Mask
    const renderMask = () => {
      if (showCloudMask.value) {
        if (cloudMaskFile.value) {
          const layer = mapModel.value?.getLayer(MapLayerTypeEnum.MONITORING_MASK) as MapLayerIndexModel;
          if (layer) {
            layer.updateImage(cloudMaskFile.value);
          } else {
            mapModel.value?.render(cloudMaskFile.value);
          }
        }
      } else {
        mapModel.value?.removeLayer(MapLayerTypeEnum.MONITORING_MASK);
      }
    };
    watch(showCloudMask, renderMask);
    watch(activeFile, renderMask);
    // endregion

    return {
      loading,
      indexMenuItemClicked,
      activeFile,
      selectedIndex,
      indexesKinds,
      showContrast,
      showNir,
      isShowTaskMap,
      showSelectFile,
      formatRuDate,
      changeOpacity,
      activeField,
      mapModel,
      taskMaps,
      taskMapSettings,
      MapContainerEnum,
      selectedMask,
      cloudsFilter,
      showCloudMask,
      buffTaskModel,
      taskSelectorRef,
    };
  },
});
</script>
