<template lang="pug" src="./FieldDrawBlock.pug"/>
<style lang="scss" src="./FieldDrawBlock.scss"/>

<script lang="ts">
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import { useFormatter } from '@/composables/useFormatter';
import { useMap } from '@/composables/useMap';
import { useMapContainers } from '@/composables/useMapContainers';
import { FIELD_MAX_AREA_SIZE } from '@/constants/constants/fields';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import { RuleFieldName } from '@/constants/rules/RuleFieldName';
import ApiService from '@/services/api/ApiService';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import { ElNotification, FormInstance, FormRules } from 'element-plus';
import {
  defineComponent, onMounted, onUnmounted, reactive, ref, watch,
} from 'vue';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { MapLayerDrawerModel } from '@/models/map/Layers/MapLayerDrawerModel';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { useStruct } from '@/composables/useStruct';
import { useRoute } from 'vue-router';
import LoadingStatus from '@/services/loading/LoadingStatus';
import { LoadingNamesEnum } from '@/constants/enums/LoadingNamesEnum';
import { MapLayerRulerModel } from '@/models/map/Layers/MapLayerRulerModel';
import { fieldDrawerLogType } from '@/constants/types/fieldDrawer/fieldDrawerLogType';
import FieldView from '@/components/shared/FieldView/FieldView.vue';
import { formatTime } from '@/utils/formatTime';
import { MultiPolygonModel } from '@/models/geojson/MultiPolygonModel';
import { getSelfIntersections } from '@/lib/map/getSelfIntersections';
import {
  area, featureCollection, intersect, lineString, polygon,
} from '@turf/turf';
import { Feature, Polygon, Position } from 'geojson';
import { getByIntersectFeatures } from '@/lib/map/getByIntersectFeatures';
import UiDialog from '@/components/ui/Dialog/UiDialog.vue';
import { LineStringModel } from '@/models/geojson/LineStringModel';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import { useDrawer } from '@/composables/useDrawer';
import UiIcon from '@/components/ui/Icon/UiIcon.vue';
import { formatArea } from '@/utils/formatArea';
import FieldsList from '@/modules/fields/FieldsList';
import Hotkey from '@/components/shared/Hotkey/Hotkey.vue';
import WikiContent from '@/components/shared/WikiContent/WikiContent.vue';
import SeasonList from '@/modules/season/SeasonList';
import { checkHoleIntersections } from '@/lib/map/checkHoleIntersections';
import { FieldPreviewModel } from '@/models/field/FieldPreviewModel';

export default defineComponent({
  name: 'FieldDrawBlock',
  components: {
    WikiContent,
    UiIcon,
    UiDialog,
    UiDrawer,
    RightPanel,
    FieldView,
    Hotkey,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },

  },
  emits: ['close'],
  setup(props, { emit }) {
    const { rulerActive, blockRulerTool } = useMap();
    const { formatEwkt } = useFormatter();
    const {
      fields, mapModel, mapContainerMode, activeField, informationMode,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    const {
      union,
      cutDifferenceByFields,
      drawerModel,
      drawerLayer,
      deletePolygon,
      activeHistoryIndex,
      historyCoords,
      drawerMode,
      isHavePolygon, activeDrawer,
    } = useDrawer();
    const route = useRoute();
    const { structId } = useStruct();
    const openDrawer = ref(false);
    const invalidPositions = ref<Position[]>([]);
    const openDialog = ref(false);

    const activeAction = ref<''|'deleteHole'|'addRing'|'addPolygon'|'deletePolygon'>('');

    const form = ref({
      name: '',
      farmunit: 0,
      name_rus: '',
      descr: '',
      sq_acc: 0,
      season: SeasonList.activeSeason.value.id,
    });

    const fieldDrawRef = ref<FormInstance>();
    const checkSq = ref<number>(0);
    const disabled = ref(true);

    const isScissors = ref(false);

    const rulerLayer = ref<MapLayerRulerModel>();
    const _rulerModel = reactive(new LineStringModel([]));

    const drawerLog = ref<fieldDrawerLogType[]>([]);

    const activeLogId = ref(-1);
    const lengthPolygon = ref(0);

    const handlerUpdateActiveLog = (id: number) => {
      drawerLayer.value.refreshPolygin();
      activeLogId.value = id;
    };
    const showWikiDialog = ref(false);

    const polygonsList = ref<
      {
        data: Feature<Polygon>;
        isIntersect: boolean;
        isSelfIntersect: boolean;
        area: number;
      }[]
    >([]);

    watch(activeHistoryIndex, (idx) => {
      drawerModel.value.updatePolygons(historyCoords.value[idx]);
      polygonsList.value = drawerModel.value.data.map((a, i, arr) => {
        const coordinates = a.toFeature().geometry.coordinates;
        const selfIntersectFeatures = arr.filter(
          (v, i2) => (
            i === i2 ? false
              : (findCurveIntersections(
                lineString(coordinates[0]),
                lineString(arr[i2].toFeature().geometry.coordinates[0]),
              ).length > 0

              )

          ),
        );

        const intersectFields = getByIntersectFeatures(mapModel.value.map, a.toFeature(), ['field']);
        const _fields = fields.value.filter(
          (b) => b.id !== activeField.value?.id
            && intersectFields.some((c) => b.id === c.id),
        )
          .map((f) => f.feature);

        const intersectFeature = _fields.map((item) => intersect(featureCollection([a.toFeature(), item]))).filter(Boolean);

        return {
          data: a.toFeature(),
          isIntersect: arr.length > 1
            ? selfIntersectFeatures.length > 0
            || intersectFeature.length > 0
            : intersectFeature.length > 0 || checkHoleIntersections(polygon(coordinates)),
          area: area(a.toFeature()),
          isSelfIntersect: getSelfIntersections(lineString(a.toFeature().geometry.coordinates[0])).length > 0,
        };
      });
      isHavePolygon.value = polygonsList.value.length > 0;
      drawerLayer.value.refreshPolygin();
    });

    const validateName = (rule: any, value: any, callback: any) => {
      console.log(form.value.name.trim().length < 2);
      if (fields.value.some((f) => f.name === form.value.name) && !activeField.value) {
        callback(new Error('Данное имя не уникально'));
      } else if (form.value.name.trim().length < 2) {
        callback(new Error('Длина краткого наименования должна быть от 2-ух до 255-ти символов'));
      } else {
        callback();
      }
    };

    const rules = reactive<FormRules>({
      name: [
        ...RuleFieldName,
        {
          validator: validateName,
          trigger: 'blur',
        },
      ],
      name_rus: [
        {
          min: 0,
          max: 256,
          message: 'Длина полного наименования поля не должна превышать 256 символов',
          trigger: ['blur', 'change'],
        },
      ],

    });

    const closeWindow = () => {
      blockRulerTool.value = false;

      activeDrawer.value = false;
      mapContainerMode.value = 'default';
      drawerMode.value = 'create';
      emit('close');
    };

    const saveNewField = () => {
      const formData = new FormData();
      disabled.value = true;
      Object.keys(form.value).forEach((key) => {
        // @ts-ignore
        formData.append(key, form.value[key]);
      });
      formData.append('geom', formatEwkt(drawerModel.value.toFeature().geometry.coordinates));
      ApiService.gis.postFieldAdd(formData)
        .then(async (resp) => {
          closeWindow();
          EventBus.$emit(EventsEnum.MapFeaturesUpdated);
          openDialog.value = false;
          disabled.value = false;
          openDrawer.value = false;
          await FieldsList.fetchField(resp.data.id);
          mapModel.value?.events.emitFieldUpdated();
        })

        .catch((response) => {
          LoggerService.error(response.message);
          disabled.value = false;
        });
    };

    const saveEditPropertis = async () => {
      const data = {
        name: form.value.name,
        name_rus: form.value.name_rus,
        descr: form.value.descr,
        sq_acc: form.value.sq_acc,
      };

      const fieldId = Number(activeField.value?.id);
      if (fieldId) {
        await ApiService.struct.patchField(fieldId, data);
        await FieldsList.fetchField(fieldId);
        mapModel.value?.events.emitFieldUpdated();
      }
    };

    const saveEditField = () => {
      const data = {
        geom: drawerModel.value.toFeature().geometry,
      };
      ApiService.struct.patchFieldGeom(Number(activeField.value.id), data)
        .then(async (a) => {
          await FieldsList.getFieldModel(Number(activeField.value.id))?.update(a.data.geojson);
          mapModel.value?.events.emitFieldUpdated();
          closeWindow();

          openDrawer.value = false;
        });
      saveEditPropertis();
    };

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          if (form.value.sq_acc <= FIELD_MAX_AREA_SIZE && checkSq.value <= FIELD_MAX_AREA_SIZE) {
            drawerMode.value === 'create' ? saveNewField() : saveEditField();
          } else {
            ElNotification({
              title: 'Детали',
              message: `Поле должно быть меньше ${FIELD_MAX_AREA_SIZE} га`,
              type: 'warning',
              position: 'bottom-right',
            });
          }
        }
      });
    };

    const isNumber = (evt: KeyboardEvent): void => {
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      }
    };

    onMounted(() => {
      form.value.farmunit = useStruct().structId.value;
      rulerActive.value = false;
      blockRulerTool.value = true;
      form.value.sq_acc = 0;
      const coords = props.action === 'create' ? [[[]]] : activeField.value.feature.geometry.coordinates;
      props.action === 'edit' && (form.value.name = activeField.value.name);
      drawerModel.value = new MultiPolygonModel(coords);
      drawerLayer.value = mapModel.value.render(drawerModel.value as MultiPolygonModel) as MapLayerDrawerModel;
      props.action === 'create' ? drawerLayer.value.setActiveMode('create') : drawerLayer.value.setActiveMode('edit');
      if (props.action === 'edit') {
        EventBus.$emit('drawerLogType', 'startEditField');
        form.value.sq_acc = activeField.value.dto.properties.sq_acc;
        form.value.name = activeField.value.name;
        form.value.descr = activeField.value.dto.properties.descr;
      }
    });

    onUnmounted(() => {
      blockRulerTool.value = false;
      drawerMode.value = 'create';
    });

    watch(isScissors, (a) => {
      if (a) {
        rulerLayer.value = mapModel.value.render(_rulerModel as LineStringModel) as MapLayerRulerModel;
      } else {
        mapModel.value.removeLayer(rulerLayer.value.uuid);
      }
    });

    onMounted(async () => {
      await LoadingStatus.awaitLoad(LoadingNamesEnum.MAP_CONTAINER, MapContainerEnum.MAIN_MAP);
      drawerLog.value = [];
    });

    let idToLog = 0;
    const checkIsNewGeometry = (): boolean => JSON.stringify(drawerModel.value.data.map((a) => a.data.map((b) => b.data.map((c) => c.data)))) !== JSON.stringify(drawerLog.value[idToLog - 1]?.data);

    EventBus.$on('drawerLogType', (t) => {
      if (t !== 'addPoint' && checkIsNewGeometry()) {
        historyCoords.value.splice(activeHistoryIndex.value + 1, (historyCoords.value.length - 1) - activeHistoryIndex.value);
        const time = new Date();
        drawerLog.value.push(
          {
            id: idToLog,
            type: t,
            time: formatTime(time.toString()),
            data: drawerModel.value.data.map((a) => a.data.map((b) => b.data.map((c) => c.data))),
            selfIntersect: [],
          },
        );
        historyCoords.value.push(drawerModel.value.data.map((a) => a.data.map((b) => b.data.map((c) => c.data))));
        activeHistoryIndex.value = historyCoords.value.length - 1;
        idToLog++;
      }
    });

    const fixIntersect = () => {
      cutDifferenceByFields(fields.value, mapModel.value.map);
      union();
    };

    const returnToEditBlock = () => {
      blockRulerTool.value = false;
      activeDrawer.value = false;
      mapContainerMode.value = 'default';
      drawerMode.value = 'create';
      setTimeout(() => {
        informationMode.value = 'field';
      }, 300);
    };
    onUnmounted(() => {
      blockRulerTool.value = false;
      mapContainerMode.value = 'default';
      mapModel.value.removeLayer(MapLayerTypeEnum.AREA);
      drawerLayer.value.setActiveMode('none');
      drawerLayer.value.offEventListener();
    });

    onUnmounted(() => {
      closeWindow();
    });
    return {
      FIELD_MAX_AREA_SIZE,
      form,
      rules,
      fieldDrawRef,
      closeWindow,
      onSubmit,
      isNumber,
      disabled,
      openDrawer,
      lengthPolygon,
      activeAction,
      isScissors,
      activeLogId,
      drawerLog,
      handlerUpdateActiveLog,
      openDialog,
      invalidPositions,
      polygonsList,
      fixIntersect,
      deletePolygon,
      formatArea,
      drawerMode,
      showWikiDialog,
      FieldPreviewModel,
      returnToEditBlock,
    };
  },
});
</script>
