<template lang="pug" src="./POIEditBlock.pug"/>
<style lang="scss" src="./POIEditBlock.scss" />
<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  reactive,
  ref,
  shallowRef,
  toRefs,
  watch,
} from 'vue';
import {
  Delete, EditPen, MapLocation, Picture, Place,
} from '@element-plus/icons-vue';
import {
  ElMessage, ElMessageBox, FormInstance, FormRules,
} from 'element-plus';
import { POIParams } from '@/assets/data/POIParams';
import { useAuth } from '@/composables/useAuth';
import { usePoi } from '@/composables/usePoi';
import { useFormatter } from '@/composables/useFormatter';
import EventBus from '@/services/eventBus/EventBus';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import UiLoading from '@/components/ui/Loading/UiLoading.vue';
import { POICategoryEnum } from '@/constants/enums/POICategoryEnum';
import RightPanel from '@/components/shared/RightPanel/RightPanel.vue';
import UiSelector from '@/components/ui/Selector/UiSelector.vue';
import PoisList from '@/modules/poi/PoisList';
import { PoiModel } from '@/models/poi/PoiModel';
import PoiEvents from '@/modules/poi/PoiEvents';
import ApiService from '@/services/api/ApiService';
import { OrderedPoiKeys } from '@/assets/data/OrderedPoiKeys';
import { SimpleType } from '@/constants/types/BasedTypes';
import { cleanObject } from '@/utils/cleanObject';
import { PoiParamsType } from '@/constants/types/PoiParamsType';
import { MapLayerTypeEnum } from '@/constants/enums/MapLayerTypeEnum';
import { MapLayerPoiModel } from '@/models/map/Layers/MapLayerPoiModel';
import { pythonRound } from '@/utils/pythonRound';
import UiDrawer from '@/components/ui/Drawer/UiDrawer.vue';
import { useMapContainers } from '@/composables/useMapContainers';
import { MapContainerEnum } from '@/constants/enums/MapContainerEnum';
import PoiEditDialog from '@/modules/poi/ui/POIEditBlock/POIEditDialog.vue';

export default defineComponent({
  name: 'POIEditBlock',
  props: {
    poi: {
      type: Object as PropType<PoiModel>,
      required: true,
    },
  },
  components: {
    RightPanel,
    MapLocation,
    EditPen,
    Place,
    Delete,
    UiLoading,
    Picture,
    UiSelector,
    UiDrawer,
    PoiEditDialog,
  },
  emits: ['close'],
  setup(props, { emit }) {
    // region Composables
    const { formatValue, ucFirst } = useFormatter();
    const {
      poiMoveMode, poiMoveId,
    } = usePoi();
    const { accessToken } = useAuth();
    const {
      mapModel,
      informationMode,
    } = useMapContainers(MapContainerEnum.MAIN_MAP);
    // endregion

    // region Refs
    const { poi } = toRefs(props);
    const active = ref(false);
    const editPOI = ref();
    const activeTabs = ref('Cut');
    const activeTabsEdit = ref('Info');
    const visited = ref(false);
    const descr = ref('');
    const poiFormInfo = ref <{
      name: string,
      descr: string,
      visited: boolean,
      params: Record<string, any>,
      }>({
        name: '',
        descr: '',
        visited: false,
        params: {},
      });
    const excludeKeys = ['x', 'y', 'n_prob', 'comments', 'is_visited'];
    const paramsKeys = Object.keys(POIParams).map((key) => key.toLowerCase());
    const POIFormRef = shallowRef<FormInstance>();
    // endregion

    // region MapboxSubscribers register
    EventBus.$on(EventsEnum.BlockClosed, () => {
      editPOI.value = undefined;
    });
    // endregion

    const uniqueNamePOI = (rule: any, value: any, callback: any) => {
      if (PoisList.pois.value.some((f) => f.group === usePoi().formPoi.value.group && f.name === poiFormInfo.value.name && f.id !== poi.value.id)) {
        callback(new Error('Данное имя не уникально'));
      } else {
        callback();
      }
    };

    const rules = reactive<FormRules>({
      name: [
        {
          required: true,
          message: 'Введите название точки',
          trigger: 'blur',
        },
        {
          validator: uniqueNamePOI,
          trigger: 'blur',
        },
        {
          min: 1,
          max: 80,
          message: 'Длина поля не должна превышать  80 символов',
          trigger: ['blur', 'change'],
        },
      ],
    });

    const computedParams = computed(() => {
      const params: {
        key: string,
        value: SimpleType,
        category: POICategoryEnum,
        type: PoiParamsType,
        precision?: number,
        max?: number,
        min?: number
        unit?: string
      }[] = [];

      const pushParam = (key: string) => {
        const ignore = ['x', 'y'];
        const p = POIParams[key];
        const v = ['int', 'number'].includes(p.type) ? Number(poi.value?.params[key]) : poi.value?.params[key];

        if (['int', 'number'].includes(p.type) && !ignore.includes(key)) {
          if (Number(v) !== -1 && !Number.isNaN(v)) {
            params.push({
              key,
              value: Number(v),
              category: POIParams[key].category,
              type: POIParams[key].type,
              precision: POIParams[key]?.precision || 2,
              max: POIParams[key]?.max || 99999,
              min: POIParams[key]?.min || -1,
              unit: POIParams[key]?.unit || '',
            });
          }
        } else if (v && !ignore.includes(key)) {
          params.push({
            key,
            value: v,
            category: POIParams[key].category,
            type: POIParams[key].type,
          });
        }
      };

      OrderedPoiKeys.forEach((key) => pushParam(key));

      Object.keys(POIParams)
        .filter((key) => !OrderedPoiKeys.includes(key))
        .forEach((key) => pushParam(key));
      return params;
    });

    const computedAdditionalParams = computed(
      () => Object.keys(poi.value.params || {})
        .filter((key) => ![...excludeKeys, ...paramsKeys].includes(key.toLowerCase()))
        .map((key) => ({
          key,
          value: poi.value.params[key],
        })),
    );

    const calculateLabelWidth = (category: string) => {
      if (category === POICategoryEnum.LIMIT_FACTOR) {
        return 450;
      }
      if (category === POICategoryEnum.CUT) {
        return 360;
      }
      return 200;
    };

    const initForm = () => {
      if (poi.value) {
        poiFormInfo.value.descr = poi.value.description;
        poiFormInfo.value.name = poi.value.name;
        poiFormInfo.value.visited = poi.value.visited || false;

        poiFormInfo.value.params = Object.keys(POIParams).map((key) => ({
          key,
          ...POIParams[key],
          value: PoiModel.formatPoiValue(poi.value.params[key], POIParams[key].type),
          labelWidth: calculateLabelWidth(POIParams[key].category),
        }));

        Object.keys(poi.value.params).forEach((key) => {
          if (!(poiFormInfo.value.params as Array<Record<any, any>>).some((a) => a.key === key) && !excludeKeys.some((a) => a === key)) {
            (poiFormInfo.value.params as Array<Record<any, any>>).push({
              key,
              type: 'string',
              maxLength: 127,
              category: 'no-standard',
              value: poi.value.params[key],
              labelWidth: 400,
            });
          }
        });
        (poiFormInfo.value.params as Array<Record<any, any>>).sort((a, b) => (OrderedPoiKeys.indexOf(a.key) > OrderedPoiKeys.indexOf(b.key) ? 1 : -1));
      }
    };

    const imgList = computed(() => poi.value.photos.map((item) => ({
      id: item.id,
      src: `${process.env.VUE_APP_API_URL}/scouting/photo/${item.id}/file/?access_token=${accessToken.value}&size=70`,
      alt: item.name,
    })));
    const imgStrings = computed(() => poi.value.photos.map((item) => `${process.env.VUE_APP_API_URL}/scouting/photo/${item.id}/file/?access_token=${accessToken.value}`));

    onMounted(() => {
      initForm();
    });

    const activeEdit = () => {
      active.value = true;
    };

    const deletePOI = () => {
      ElMessageBox.confirm(
        'Удалить выбранную точку интереса?',
        'Удалить точку интереса',
        {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      )
        .then(() => {
          ElMessage({
            type: 'success',
            message: 'Точка интереса успешно удалена',
          });
          const poiIndexInCollection = PoisList.collection.collection.findIndex((p) => p.id === poi.value.id);
          if (poiIndexInCollection >= 0) {
            PoisList.collection.collection.splice(poiIndexInCollection, 1);
          }
          PoisList.deletePoi(poi.value.id).then(() => {
            const layer = mapModel.value?.getLayer(MapLayerTypeEnum.POI) as MapLayerPoiModel;
            const collection = layer.data.collection.filter((f) => f.id !== poi.value.id);
            if (layer) {
              layer.redraw(collection);
            }
            informationMode.value = 'none';
          });
        });
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(`${poi.value.y} ${poi.value.x}`);
    };

    // region Move POI
    let startMovePosition: any = [];
    const dragPoint = () => {
      if (!poiMoveMode.value) {
        startMovePosition = [poi.value.x, poi.value.y];
      }
      poiMoveMode.value = !poiMoveMode.value;
      poi.value.moveMode = true;
      poiMoveId.value = poi.value?.id;
    };

    const cancelMove = () => {
      poi.value.move(startMovePosition);
      poiMoveMode.value = false;
      poi.value.moveMode = false;
      const layer = mapModel.value?.getLayer(MapLayerTypeEnum.POI) as MapLayerPoiModel;
      const collection = layer.data.collection;
      layer.redraw([...collection]);
    };

    const saveMove = () => {
      poi.value.moveMode = false;
      poi.value.saveMove();
      usePoi().poiMoveMode.value = false;
      EventBus.$emit(EventsEnum.MapRedrawPois);
    };

    // endregion

    watch(poi, (a, b) => {
      if (a !== b && poiMoveMode.value) {
        b?.move(startMovePosition);
        EventBus.$emit(EventsEnum.MapPoisUpdated);
        poiMoveMode.value = false;
      }
    });

    const deletePhoto = (photo: any) => {
      ElMessageBox.confirm(
        'Вы уверены, что хотите удалить фотографию?',
        'Удалить фотографию',
        {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      ).then(() => {
        ApiService.scouting.deletePhoto(photo.id).then(() => {
          ElMessage({
            type: 'success',
            message: 'Изменения были внесены',
          });
          poi.value.deletePhoto(photo.id);
        });
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: 'Изменения не были внесены',
        });
      });
    };

    onUnmounted(() => {
      if (poiMoveMode.value) {
        poiMoveMode.value = false;
        poi.value.move(startMovePosition);
        EventBus.$emit(EventsEnum.MapPoisUpdated);
      }
    });

    const closeEdit = () => {
      emit('close');
    };

    return {
      PoisList,
      formatValue,
      descr,
      visited,
      activeTabsEdit,
      activeTabs,
      imgStrings,
      imgList,
      poiMoveMode,
      computedParams,
      computedAdditionalParams,
      activeEdit,
      active,
      editPOI,
      dragPoint,
      deletePOI,
      poiFormInfo,
      copyToClipboard,
      cancelMove,
      saveMove,
      ucFirst,
      rules,
      POIFormRef,
      deletePhoto,
      POIParams,
      closeEdit,
    };
  },
});
</script>
