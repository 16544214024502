import { useAuth } from '@/composables/useAuth';
import { useMapLayout } from '@/composables/useMapLayout';
import { EventsEnum } from '@/constants/enums/EventsEnum';
import { LoggerGroupsEnum } from '@/constants/enums/LoggerGroupsEnum';
import { ApiErrorBodyType } from '@/constants/types/ApiErrorBodyType';
import EventBus from '@/services/eventBus/EventBus';
import LoggerService from '@/services/logger/LoggerService';
import MyTrackerService from '@/services/myTracker/MyTrackerService';
import { Utils } from '@/utils';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useStruct } from '@/composables/useStruct';
import { JsonType } from '@/constants/types/JsonType';
import { ApiError } from './ApiError';
import { ApiResponse } from './ApiResponse';

export type RequestParamsType = {
  auth?: boolean;
  serviceToken?: boolean;
  freeze?: boolean,
  pagination?: number,
  passError?: boolean,
  ignoreLog?: boolean,
  ignoreSeason?: boolean,
  season?: number | undefined ;
};

export class ApiRequest {
  private params: RequestParamsType = {
    auth: false,
    serviceToken: false,
    freeze: true,
    pagination: 0,
    passError: false,
    ignoreLog: false,
    ignoreSeason: false,
    season: Number(window.localStorage.getItem('seasonId')) || undefined,
  };

  private isAuthRequired: boolean;

  private client;

  constructor(params?: RequestParamsType) {
    this.params = {
      ...this.params,
      ...params,
    };

    this.isAuthRequired = Boolean(this.params?.auth);
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_BASE_URL}`,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    this.client.interceptors.request.use(async (request) => await this.beforeRequest(request));
    this.client.interceptors.response.use(
      (response) => this.onRequestSuccess(response),
      (error) => this.onRequestFailure(error),
    );
  }

  public get<T>(url: string,
    data: Record<string, string | number | boolean | undefined> = {},
    page = 0): Promise<ApiResponse<T>> {
    const params: string[] = [];
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          params.push(`${key}=${String(data[key])}`);
        }
      });
    }
    if (this.params.pagination) {
      params.push(`offset=${page * this.params.pagination}`);
      params.push(`limit=${this.params.pagination}`);
    }
    return this.client.get(`${url}${params.length ? `?${params.join('&')}` : ''}`);
  }

  public post<T>(
    url: string,
    data: Record<string, unknown> | Record<string, unknown>[] | JsonType | FormData = {},
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse<T>> {
    MyTrackerService.send('POST request', { url });
    if (data instanceof FormData) {
      config = {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
    }
    return this.client.post(url, data, config);
  }

  public patch<T>(
    url: string,
    data: Record<string, unknown> | Record<string, unknown>[] | JsonType | FormData = {},
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse<T>> {
    MyTrackerService.send('PATCH request', { url });
    if (data instanceof FormData) {
      config = {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
    }
    return this.client.patch(url, data, config);
  }

  public put<T>(
    url: string,
    data: Record<string, unknown> | JsonType | FormData = {},
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse<T>> {
    MyTrackerService.send('PUT request', { url });
    if (data instanceof FormData) {
      config = {
        ...config,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
    }
    return this.client.put(url, data, config);
  }

  public delete<T>(
    url: string,
    config: AxiosRequestConfig = {},
  ): Promise<ApiResponse<T>> {
    MyTrackerService.send('DELETE request', { url });
    return this.client.delete(url, config);
  }

  private async beforeRequest(request: InternalAxiosRequestConfig) {
    if (!this.params.ignoreLog) {
      LoggerService
        .from(LoggerGroupsEnum.API)
        .group(`-> : ${request?.method?.toUpperCase() || 'GET'} ${request.url}`, request.data || null);
      useMapLayout().showProgress.value = true;
    }
    if (this.isAuthRequired) {
      if (useAuth().isAuth()) {
        this.setAccessToken(request);
      } else if (useAuth().hasRefreshToken() && useAuth().isAccessTokenExpired()) {
        EventBus.$emit(EventsEnum.TokenRenewRequire);
        await new Promise((resolve) => {
          EventBus.$on(EventsEnum.TokenRenewComplete, () => {
            resolve(1);
          });
        });
        this.setAccessToken(request);
      } else if (!this.params.ignoreLog) {
        LoggerService
          .from(LoggerGroupsEnum.API)
          .group(`ERROR:  ${request?.method?.toUpperCase() || 'GET'} ${request.url}: auth required but user is not authenticated: : `);
      }
    }
    if (!this.params.ignoreSeason) {
      this.setSeasonHeader(request);
    }
    return request;
  }

  private async onRequestSuccess<T>(response: AxiosResponse) {
    if (!this.params.ignoreLog) {
      LoggerService
        .from(LoggerGroupsEnum.API)
        .group(`<- : ${response?.config?.method?.toUpperCase()} ${response.config.url}`, response.data);
      useMapLayout().showProgress.value = false;
    }

    if (this.params.freeze) {
      return Utils.freezeObject(new ApiResponse<T>(response));
    }

    return new ApiResponse<T>(response);
  }

  private async onRequestFailure(requestError: AxiosError) {
    if (!this.params.ignoreLog) {
      useMapLayout().showProgress.value = false;
    }
    if (requestError?.response?.status === 401) {
      EventBus.$emit(EventsEnum.UserDoLogout);
    }
    if (!this.params.passError && requestError.response?.data && !this.params.ignoreLog) {
      const error = new ApiError(<ApiErrorBodyType>requestError.response.data);
    }
    return Promise.reject(requestError.response);
  }

  // eslint-disable-next-line class-methods-use-this
  private setAccessToken(request: AxiosRequestConfig) {
    if (request?.headers) {
      let token = useAuth().getBearer();
      if (this.params.serviceToken) {
        token = `Bearer ${useAuth().serviceToken.value}`;
      }
      request.headers.Authorization = token;
    }
  }

  private setFarmunitHeader(request: AxiosRequestConfig) {
    const unit = useStruct().structId.value;
    if (request?.headers) {
      request.headers['x-farmunit'] = unit;
    }
  }

  private setSeasonHeader(request: AxiosRequestConfig) {
    if (request?.headers) {
      request.headers['x-season-id'] = this.params.season;
    }
  }
}
