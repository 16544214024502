import { PositionModel } from '@/models/geojson/PositionModel';
import { Feature, Polygon, Position } from 'geojson';
import { Model } from '@/models/Model';

export class LinearRingModel extends Model {
  get data(): PositionModel[] {
    return this._data;
  }

  public get id(): number {
    return this._id;
  }

  private _data: PositionModel[] ;

  static counter = 1000000;

  private readonly _id: number;

  constructor(coordinates: Position[]) {
    super();
    this._data = coordinates.map((p) => new PositionModel(p));
    this._id = LinearRingModel.counter;
  }

  addPosition(position: Position) {
    this._data.push(new PositionModel(position));
  }

  toFeature(): Feature<Polygon> {
    return {
      type: 'Feature',
      properties: {
        id: this._id,
      },
      id: this._id,
      geometry: {
        type: 'Polygon',
        coordinates: [this._data.map((v) => v.data)],
      },
    };
  }
}
