import { Feature, MultiPolygon, Polygon } from 'geojson';
import { findCurveIntersections } from '@/lib/map/findCurveIntersections';
import getLinesIntersection from '@/lib/map/getLinesIntersection';

export function doesPolygonHaveHoleIntersection(polygon: Polygon): boolean {
  // Если в полигоне нет дырок – пересечений быть не может
  if (polygon.coordinates.length < 2) return false;

  const outerRing = polygon.coordinates[0];
  const holes = polygon.coordinates.slice(1);

  for (const hole of holes) {
    for (let i = 0; i < outerRing.length - 1; i++) {
      const p1 = outerRing[i];
      const p2 = outerRing[i + 1];

      for (let j = 0; j < hole.length - 1; j++) {
        const q1 = hole[j];
        const q2 = hole[j + 1];

        if (getLinesIntersection(p1, p2, q1, q2)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function doesMultiPolygonHaveHoleIntersection(multiPolygon: MultiPolygon): boolean {
  for (const polygon of multiPolygon.coordinates) {
    if (doesPolygonHaveHoleIntersection({ type: 'Polygon', coordinates: polygon })) {
      return true;
    }
  }
  return false;
}

export const checkHoleIntersections = (shape: Feature<Polygon | MultiPolygon>): boolean => {
  if (shape.geometry.type === 'Polygon') {
    return doesPolygonHaveHoleIntersection(shape.geometry);
  } if (shape.geometry.type === 'MultiPolygon') {
    return doesMultiPolygonHaveHoleIntersection(shape.geometry);
  }
  throw new Error('Не удалось определить тип фигуры');
};
