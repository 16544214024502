import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2b461d80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SidePanel-navigation" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["SidePanel", { [`--reverse`]: _ctx.isCompare }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPanels, (panel) => {
        return (_openBlock(), _createBlock(_component_el_tooltip, {
          content: _ctx.$t(panel.tooltip, { ns: 'side-panel' }),
          placement: "left",
          "show-after": 200
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["SidePanel-navigation-button bg-button-white", { active: _ctx.isPanelOpen && _ctx.activePanel === panel.name }]),
              onClick: ($event: any) => (_ctx.togglePanel(panel.name))
            }, [
              _createVNode(_component_ui_icon, {
                name: panel.icon,
                color: "currentColor",
                size: 32
              }, null, 8, ["name"])
            ], 10, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["content"]))
      }), 256))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["SidePanel-content", { active: _ctx.isPanelOpen }]),
      style: _normalizeStyle({ width: `${(_ctx.isPanelOpen ? _ctx.width : -10 )}px`})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredPanels, (panel) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["SidePanel-content-panel", { active: _ctx.activePanel === panel.name }]),
          style: _normalizeStyle({ width: `${_ctx.width}px`,  })
        }, [
          (!_ctx.isCompare || panel.compareAble)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(panel.component), {
                key: 0,
                "map-container": _ctx.mapContainer,
                "is-compare": _ctx.isCompare,
                options: panel.options,
                active: _ctx.activePanel === panel.name,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPanelOpen = false))
              }, null, 40, ["map-container", "is-compare", "options", "active"]))
            : _createCommentVNode("", true)
        ], 6))
      }), 256))
    ], 6)
  ], 2))
}