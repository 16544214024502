import { booleanPointInPolygon, featureCollection, intersect } from '@turf/turf';
import { getByBboxFeatures } from '@/lib/map/getByBboxFeatures';
import {
  Feature, MultiPolygon, Point, Polygon,
} from 'geojson';

export const getByIntersectFeatures = (map: mapboxgl.Map, poly: Feature<Polygon | MultiPolygon>, FilterTypeList: string[] = []) => getByBboxFeatures(map, poly, FilterTypeList)?.filter((a: Feature<Polygon | MultiPolygon | Point>) => {
  if (a.geometry.type === 'Point') {
    return booleanPointInPolygon(a as Feature<Point>, poly as Feature<Polygon>);
  }
  return intersect(featureCollection([poly, a as Feature<Polygon | MultiPolygon>])) !== null;
});
